module.exports.ROLE_STRINGS = {
  0: "user",
  1: "curator",
  2: "god"
}

module.exports.USER_ROLES = {
  NORMAL: 0,
  CURATOR: 1,
  GOD: 2
}

module.exports.UPDATE_IMPORTANCE = {
  NORMAL: 0,
  KEY: 1
}

module.exports.FEED_STATUS = {
  UNPUBLISHED: 0,
  ACTIVE: 1,
  FEATURED: 2,
  BREAKING: 3,
  ARCHIVED: 4
}

module.exports.UPDATE_STATUS = {
  UNPUBLISHED: 0,
  PUBLISHED: 1,
  IN_REVIEW: 2,
  REJECTED: 3
}

module.exports.DRAFT_STATUS = {
  QUEUED: 0,
  DRAFTING: 1,
  POSTED: 2
}

module.exports.AUTH_FLOWS = {
  FACEBOOK: 0,
  GOOGLE: 1,
  PHONE: 2,
  CURATOR: 3,
  APPLE: 4
}

module.exports.COVERAGE_TYPES = {
  LIVE: 0,
  DAILY: 1,
  TWICE_DAILY: 2
}

module.exports.COVERAGE_STATUS = {
  ACTIVE: 0,
  PAUSED: 1
}

module.exports.DANIEL_FEEDS = [
  "5f1cb71a9c6f89001efe41c2",
  "5f1cb659989344001e33e01d",
  "5ffe03351437f5001ebd06b0",
  "5ffe035ae909cf001ed57a93",
  "606cd4d80ab89b001e2938bb",
  "606cd5f30ab89b001e2938bc",
  "606cd748ae5e8d001e5c44ad",
  "609f0770c9a4ff001e11e643",
  "60d4cb27d2805b001e6c06fa",
  "5c064304e841240004a5bdb4", //china
  "5cda1bd3370ed300177cbb24", //iran
  "5c4020b001f7450004b7e6c5", //north korea
  "5d49c40bb710d2001e888ad1", //gun policy
  "618420e000c53f001ff27c30",
  "61816931e76b86001fb2d3c4", //microsoft
  "5cef0a310e3f8f0017366ffd", //abortion
  "5e18d66ee4be5f001ee60530", //health
  "5c3ebfd2d0816000049079c4", //immigration
  "61183432066bd7001e4f0e40" //afghanistan
]

module.exports.UPDATE_TYPES = {
  "TextUpdate": 0,
  "ImageUpdate": 1,
  "LinkUpdate": 2,
  "QuoteUpdate": 3,
  "TweetUpdate": 4,
  "VideoUpdate": 5,
  "QuestionUpdate": 6,
  "FeedUpdate": 7,
  "ArticleUpdate": 8
}

module.exports.DEFAULT_COLORS = [
  "#018BFF",
  "#EC789C",
  "#353B48",
  "#333333",
  "#5F27CD",
  "#FFCD00",
  "#4DD137",
  "#8AB1DC",
  "#2641A5",
  "#D8280F",
  "#E67E21",
  "#027F3C",
  "#FF6348"
]

module.exports.DANIEL_ID = "5df6adee6132ab001e0f7025";

module.exports.BASE_API_URL = process.env.REACT_APP_BASE_API_URL ? process.env.REACT_APP_BASE_API_URL : "https://api.winno.app";
// module.exports.BASE_API_URL = "https://sandbox.api.winno.app";
// module.exports.BASE_API_URL = "http://localhost:3001";
