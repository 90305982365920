import React, { Component } from 'react';
import logo from './images/logo.png';
import { Route, Link, NavLink, Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Lightbox from 'react-images';
import ReactCrop from 'react-image-crop';
import { Tweet } from 'react-twitter-widgets';
import { HashLoader, ClipLoader } from 'react-spinners';
import { Picker } from 'emoji-mart'
import renderHTML from 'react-render-html';
import io from 'socket.io-client';
import Moment from 'react-moment';
import TimeAgo from 'react-timeago';
import DatePicker from "react-datepicker";
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { SketchPicker } from 'react-color';
import Countdown from 'react-countdown-now';
import TagsInput from 'react-tagsinput'
import Select from 'react-select';
import $ from 'jquery';

import UpdateFooter from'./images/update_footer.png';
import notification from './notification.mp3';

import 'react-image-crop/dist/ReactCrop.css'
import "react-datepicker/dist/react-datepicker.css"
import 'react-tagsinput/react-tagsinput.css'
import 'emoji-mart/css/emoji-mart.css'

import './styles/App.css'
import './styles/Sidebar.css'
import './styles/Queue.css'
import './styles/Draft.css'
import './styles/Modal.css'
import './styles/Manage.css'
import './styles/Users.css'
import './styles/Login.css'
import './styles/Updates.css'
import './styles/Batch.css'
import './styles/Mobile.css'
import './styles/Data.css'

let constants = require('./helpers/constants.js')

class App extends Component {

	constructor(props) {

	  super(props);

	  this.state = {
      checking_session: true,
      logged_in: false,
      queue: null,
      feeds: null,
      users: null,
      admins: null,
      loading: true,
			show_sidebar: true,
	      socket: io(process.env.REACT_APP_BASE_API_URL ? process.env.REACT_APP_BASE_API_URL : "https://api.winno.app", {
				path: '/socket.io',
				transports: ['websocket'],
				secure: true
			}),
			has_subscribed_to_socket: false
    };

		this.state.socket.emit('subscribe_to_queue');

    this.fetchUsers = this.fetchUsers.bind(this);
    this.fetchQueueAndDrafts = this.fetchQueueAndDrafts.bind(this);
		this.fetchQuestions = this.fetchQuestions.bind(this);
    this.fetchDrafts = this.fetchDrafts.bind(this);
		this.onUpdatePost = this.onUpdatePost.bind(this);
    this.getFeedsList = this.getFeedsList.bind(this);
		this.getCoverageStatus = this.getCoverageStatus.bind(this);
		this.getUpdatesForSidebar = this.getUpdatesForSidebar.bind(this);
		this.getUnpublishedUpdates = this.getUnpublishedUpdates.bind(this);
		this.getUpdatesForReview = this.getUpdatesForReview.bind(this);
	}

  fetchQueueAndDrafts() {

    fetch(constants.BASE_API_URL + '/v1/drafts/queue/live', {
      credentials: 'include'
    }).then(res => res.json())
			.then(queued_drafts => {

        fetch(constants.BASE_API_URL + '/v1/feeds/queue', {
          credentials: 'include'
        }).then(res => res.json())
    			.then(updates_for_drafts => {

            this.setState({
              raw_updates_for_drafts: updates_for_drafts
            })

    				let queue = queued_drafts.live_drafts.map((draft) => {
							var updates = null;
							if (this.state.raw_updates_for_drafts[draft.feed._id]) {
								updates = this.state.raw_updates_for_drafts[draft.feed._id].map((update) => {
	    						return (
	    							<Update key={update._id} crosspost={update.crosspost} tooltips={update.tooltips} status={update.status} importance={update.importance} show_buttons={false} small_tweet={true} type={update.type} color={draft.feed.color} feed_id={draft.feed._id} content={update.content} num_reacts={update.num_reacts} id={update._id} draft_id={update.draft_id} source={update.source} timestamp={update.created_at} visible={update.visible} reacted={false} />
	    						)
	    					})
							}
    					return (
    						<QueuedUpdate feed_id={draft.feed._id} key={draft._id} feeds={this.state.feeds} id={draft._id} updates={updates} onDelete={this.onDeleteFromLiveQueue.bind(this)} onClaim={this.fetchDrafts.bind(this)} name={draft.feed.name} color={draft.feed.color} scout_name={draft.posted_by.login_id} source={draft.source} logged_in_user={this.state.logged_in_user} timestamp={draft.created_at} />
    					)
    				})

    				this.setState({
              live_queue: queue,
              live_queue_count: queued_drafts.live_queue_count,
            });

						if (window.location.href.includes("sandbox")) {
							document.title = "sandbox (" + this.state.live_queue_count + ")";
						} else {
							document.title = "curator (" + this.state.live_queue_count + ")";
						}

            if (this.state.live_queue_count <= 50 && !this.state.has_subscribed_to_socket) {
          		var t = this;
							this.setState({
								has_subscribed_to_socket: true
							})
          		this.state.socket.on('update_added', function(data) {
              	t.addUpdateToQueue(JSON.parse(data));
          			var audio = new Audio(notification);
          			audio.play();
            	});
            }

            fetch(constants.BASE_API_URL + '/v1/drafts/list/' + this.state.logged_in_user, {
              credentials: 'include'
            }).then(res => res.json())
        			.then(data => {
        				let drafts = data.drafts.map((draft) => {
        					return (
        						<SidebarDraft key={draft._id} id={draft._id} name={draft.feed.name} color={draft.feed.color} claimed_at={draft.claimed_at} />
        					)
        				})
        				this.setState({
                  drafts: drafts,
                  loading: false
                });
        			})
          })
			})
  }

	fetchQuestions() {

		fetch(constants.BASE_API_URL + '/v1/questions/posted', {
      credentials: 'include'
    }).then(res => res.json())
			.then(posted_questions => {

				this.setState({
					raw_questions_for_question_drafts: posted_questions.faq_posts
				})

				fetch(constants.BASE_API_URL + '/v1/questions/drafts', {
					credentials: 'include'
				}).then(res => res.json())
					.then(draft_questions => {
						let questions = draft_questions.questions.map((question) => {
							var temp_questions = null;
							if (this.state.raw_questions_for_question_drafts[question.feed._id]) {
								temp_questions = this.state.raw_questions_for_question_drafts[question.feed._id].map((update) => {
									return (
										<Update key={update._id} crosspost={update.crosspost} tooltips={update.tooltips} status={update.status} importance={update.importance} show_buttons={false} small_tweet={true} type={update.type} color={question.feed.color} feed_id={question.feed._id} content={update.content} num_reacts={update.num_reacts} id={update._id} draft_id={update.draft_id} source={update.source} timestamp={update.created_at} visible={update.visible} reacted={false} />
									)
								})
							}
							return (
								<QueuedUpdate feed_id={question.feed._id} status={question.status} key={question._id} feeds={[]} id={question._id} updates={temp_questions} onDelete={this.onDeleteFromQuestionsQueue.bind(this)} onClaim={this.fetchDrafts.bind(this)} name={question.feed.name} color={question.feed.color} scout_name={question.posted_by._id} source={question.source} notes={question.notes} is_question={true} logged_in_user={this.state.logged_in_user} timestamp={question.created_at} />
							)
						})
						this.setState({
							questions: questions,
							questions_count: draft_questions.questions_count
						});
					})

			})

  }

  addUpdateToQueue(draft) {
		var updates = [];
		if (this.state.raw_updates_for_drafts[draft.feed._id]) {
			updates = this.state.raw_updates_for_drafts[draft.feed._id].map((update) => {
	      return (
	        <Update key={update._id} crosspost={update.crosspost} tooltips={update.tooltips} status={update.status} importance={update.importance} show_buttons={false} small_tweet={true} type={update.type} color={draft.feed.color} content={update.content} num_reacts={update.num_reacts} id={update._id} draft_id={update.draft_id} source={update.source} timestamp={update.created_at} visible={update.visible} reacted={false} />
	      )
	    })
		}
    var queue = this.state.live_queue;
    queue.push((
      <QueuedUpdate feed_id={draft.feed._id} key={draft._id} feeds={this.state.feeds} id={draft._id} updates={updates} onDelete={this.onDeleteFromLiveQueue.bind(this)} onClaim={this.fetchDrafts.bind(this)} name={draft.feed.name} color={draft.feed.color} scout_name={draft.posted_by.login_id} source={draft.source} logged_in_user={this.state.logged_in_user} timestamp={draft.created_at} />
    ))
    this.setState({
      live_queue: queue,
      live_queue_count: this.state.live_queue_count + 1
    })
		if (window.location.href.includes("sandbox")) {
    	document.title = "sandbox (" + this.state.live_queue_count + ")";
		} else {
			document.title = "curator (" + this.state.live_queue_count + ")";
		}
  }

	onDeleteFromLiveQueue(id) {
		this.setState({
			live_queue: this.state.live_queue.filter(update => update.props.id !== id),
			live_queue_count: this.state.live_queue_count - 1
		})
		if (window.location.href.includes("sandbox")) {
    	document.title = "sandbox (" + this.state.live_queue_count + ")";
		} else {
			document.title = "curator (" + this.state.live_queue_count + ")";
		}
	}

	onDeleteFromQuestionsQueue(id) {
		this.setState({
			questions: this.state.questions.filter(update => update.props.id !== id),
			questions_count: this.state.questions_count - 1
		})
	}

  fetchDrafts() {
    fetch(constants.BASE_API_URL + '/v1/drafts/list/' + this.state.logged_in_user, {
      credentials: 'include'
    }).then(res => res.json())
			.then(data => {
				let drafts = data.drafts.map((draft) => {
					return (
						<SidebarDraft key={draft._id} id={draft._id} name={draft.feed.name} color={draft.feed.color} claimed_at={draft.claimed_at} />
					)
				})
				this.setState({
          drafts: drafts
        });
			})
  }

	getCoverageStatus() {
    fetch(constants.BASE_API_URL + '/v1/status', {
      credentials: 'include'
    }).then(res => res.json())
			.then(data => {
				this.setState({
          coverage_status: data.status
        })
			})
  }

	onUpdatePost() {
    this.fetchQueueAndDrafts();
		this.getUnpublishedUpdates();
  }

	getUpdatesForSidebar() {
    fetch(constants.BASE_API_URL + '/v1/updates/all?page=1', {
      credentials: 'include'
    }).then(res => res.json())
			.then(data => {
				if (data.updates) {
					var updates = [];
					for (var i = 0; i < data.updates.length; i++) {
						var update = data.updates[i];
						let feed_slug = (
							<Link to={"/feeds/" + update.feed_id._id}>
								<div className="feed-slug" key={update._id + update.feed_id._id} style={{color: update.feed_id.color}}>
									<i className="fas fa-hashtag"></i>
									{update.feed_id.name + " "}
									{update.is_faq &&
										<span>
											/ faq
										</span>
									}
								</div>
							</Link>
						)
						updates.push(feed_slug);
						let update_component = (
							<Update key={update._id} crosspost={update.crosspost} tooltips={update.tooltips} show_buttons={false} small_tweet={true} status={update.status} importance={update.importance} type={update.type} color={update.feed_id.color} feed_id={update.feed_id._id} content={update.content} num_reacts={update.num_reacts} id={update._id} draft_id={update.draft_id} source={update.source} timestamp={update.created_at} visible={update.visible} reacted={false} />
						)
						updates.push(update_component);
					}
					ReactTooltip.rebuild();
					this.setState({
						sidebar_updates: updates
					});
				}
			})
  }

  fetchUsers() {
    fetch(constants.BASE_API_URL + '/v1/users/list/1', {
      credentials: 'include'
    }).then(res => res.json())
			.then(data => {
				let admins = data.admins.map((user) => {
					return (
						<User key={user._id} profile={user.profile} username={user.login_id} role={user.role} login_flow={user.login_flow} id={user._id} onSubmit={this.fetchUsers} created_at={user.created_at} />
					)
				})
        let admin_options = data.admins.map((user) => {
          return ( // CAN CHECK FOR WRITERS ONLY HERE
            <option key={user._id} value={user._id}>{user.login_id}</option>
          )
				})
				let users = data.users.map((user) => {
					return (
						<User key={user._id} profile={user.profile} num_follows={user.num_follows} num_unreads={user.num_unreads} username={user.login_id} role={user.role} login_flow={user.login_flow} id={user._id} onSubmit={this.fetchUsers} created_at={user.created_at} />
					)
				})
				this.setState({
          admins: admins,
          admin_options: admin_options,
					users: users,
					total_users: data.total_docs
        });
			})
  }

  getFeedsList() {
    fetch(constants.BASE_API_URL + '/v1/feeds/all')
			.then(res => res.json())
			.then(data => {
				let active_feeds = data.feeds.filter(feed => feed.visible && (feed.status === constants.FEED_STATUS.ACTIVE || feed.status === constants.FEED_STATUS.BREAKING || feed.status === constants.FEED_STATUS.FEATURED || feed.status === constants.FEED_STATUS.UNPUBLISHED))
				let feeds = active_feeds.map((feed) => {
					return {
            label: `#${feed.name}`,
            value: feed._id,
						name: feed.name,
						color: feed.color
          }
				})
				this.setState({feeds: feeds});
			})
  }

	getUnpublishedUpdates() {
		fetch(constants.BASE_API_URL + '/v1/updates/unpublished', {
			credentials: 'include'
		}).then(res => res.json())
			.then(data => {
				var batched_updates = [];
				var overnight_updates = [];
				var batched_feeds = [];
				var overnight_feeds = [];
				let raw_batched_updates = data.updates.filter(update => update.feed_id.coverage_type === constants.COVERAGE_TYPES.DAILY || update.feed_id.coverage_type === constants.COVERAGE_TYPES.TWICE_DAILY);
				let raw_overnight_updates = data.updates.filter(update => update.feed_id.coverage_type === constants.COVERAGE_TYPES.LIVE);
				for (var i = 0; i < raw_batched_updates.length; i++) {
					var update = raw_batched_updates[i];
					if (!batched_feeds.includes(update.feed_id._id.toString())) {
						let feed_slug = (
							<Link to={"/feeds/" + update.feed_id._id}>
								<div key={update.feed_id._id} className="feed-slug" style={{backgroundColor: update.feed_id.color}}>
									<i className="fas fa-hashtag"></i>
									{update.feed_id.name}
								</div>
							</Link>
						)
						batched_updates.push(feed_slug);
						batched_feeds.push(update.feed_id._id.toString());
					}
					let update_component = (
						<Update user_role={this.state.user_role} key={update._id} crosspost={update.crosspost} tooltips={update.tooltips} show_buttons={true} refreshReviewing={this.getUpdatesForReview} logged_in_user={this.state.logged_in_user} author={update.author} small_tweet={true} status={update.status} importance={update.importance} type={update.type} color={update.feed_id.color} feed_id={update.feed_id._id} content={update.content} num_reacts={update.num_reacts} id={update._id} draft_id={update.draft_id} source={update.source} timestamp={update.created_at} visible={update.visible} reacted={false} refreshUpdates={this.getUnpublishedUpdates} />
					)
					batched_updates.push(update_component);
				}
				this.setState({
					batched_updates: batched_updates,
					batched_count: raw_batched_updates.length
				});
				for (var i = 0; i < raw_overnight_updates.length; i++) {
					var update = raw_overnight_updates[i];
					if (!overnight_feeds.includes(update.feed_id._id.toString())) {
						let feed_slug = (
							<Link to={"/feeds/" + update.feed_id._id}>
								<div key={update.feed_id._id} className="feed-slug" style={{backgroundColor: update.feed_id.color}}>
									<i className="fas fa-hashtag"></i>
									{update.feed_id.name}
								</div>
							</Link>
						)
						overnight_updates.push(feed_slug);
						overnight_feeds.push(update.feed_id._id.toString());
					}
					let update_component = (
						<Update user_role={this.state.user_role} key={update._id} crosspost={update.crosspost} tooltips={update.tooltips} show_buttons={true} refreshReviewing={this.getUpdatesForReview} logged_in_user={this.state.logged_in_user} author={update.author} small_tweet={true} status={update.status} importance={update.importance} type={update.type} color={update.feed_id.color} feed_id={update.feed_id._id} content={update.content} num_reacts={update.num_reacts} id={update._id} draft_id={update.draft_id} source={update.source} timestamp={update.created_at} visible={update.visible} reacted={false} refreshUpdates={this.getUnpublishedUpdates} />
					)
					overnight_updates.push(update_component);
				}
				this.setState({
					overnight_updates: overnight_updates,
					overnight_count: raw_overnight_updates.length
				});
			})
	}

	getUpdatesForReview() {
		fetch(constants.BASE_API_URL + '/v1/updates/reviewing', {
      credentials: 'include'
    }).then(res => res.json())
			.then(data => {
				if (data.updates) {
					var updates = [];
					for (var i = 0; i < data.updates.length; i++) {
						var update = data.updates[i];
						let feed_slug = (
							<Link to={"/feeds/" + update.feed_id._id}>
								<div className="feed-slug" key={update._id + update.feed_id._id} style={{color: update.feed_id.color}}>
									<i className="fas fa-hashtag"></i>
									{update.feed_id.name + " "}
									{update.is_faq &&
										<span>
											/ faq
										</span>
									}
									<span className="reviewing-update-author">
										/ {" " + update.author.login_id}
									</span>
								</div>
							</Link>
						)
						updates.push(feed_slug);
						let update_component = (
							<Update notes={update.notes} user_role={this.state.user_role} crosspost={update.crosspost} onApprove={this.getUpdatesForReview} key={update._id} tooltips={update.tooltips} show_buttons={true} refreshReviewing={this.getUpdatesForReview} logged_in_user={this.state.logged_in_user} author={update.author} status={update.status} importance={update.importance} type={update.type} color={update.feed_id.color} feed_id={update.feed_id._id} content={update.content} num_reacts={update.num_reacts} id={update._id} draft_id={update.draft_id} source={update.source} timestamp={update.created_at} visible={update.visible} reacted={false} />
						)
						updates.push(update_component);
					}
					this.setState({
						reviewing_updates: updates,
						num_reviewing_updates: data.updates.length
					});
				}
				if (data.rejected_updates) {
					var rejected_updates = [];
					for (var i = 0; i < data.rejected_updates.length; i++) {
						var update = data.rejected_updates[i];
						let feed_slug = (
							<Link to={"/feeds/" + update.feed_id._id}>
								<div className="feed-slug" key={update._id + update.feed_id._id} style={{color: update.feed_id.color}}>
									<i className="fas fa-hashtag"></i>
									{update.feed_id.name + " "}
									{update.is_faq &&
										<span>
											/ faq
										</span>
									}
									<span className="reviewing-update-author">
										/ {" " + update.author.login_id}
									</span>
								</div>
							</Link>
						)
						rejected_updates.push(feed_slug);
						let update_component = (
							<Update notes={update.notes} user_role={this.state.user_role} crosspost={update.crosspost} onApprove={this.getUpdatesForReview} key={update._id} tooltips={update.tooltips} show_buttons={true} refreshReviewing={this.getUpdatesForReview} logged_in_user={this.state.logged_in_user} author={update.author} status={update.status} importance={update.importance} type={update.type} color={update.feed_id.color} feed_id={update.feed_id._id} content={update.content} num_reacts={update.num_reacts} id={update._id} draft_id={update.draft_id} source={update.source} timestamp={update.created_at} visible={update.visible} reacted={false} />
						)
						rejected_updates.push(update_component);
					}
					this.setState({
						rejected_updates: rejected_updates,
						num_rejected_updates: data.rejected_updates.length
					});
				}
				ReactTooltip.rebuild();
			})
	}

  onLogin(id, role, username) {
    this.setState({
      logged_in: true,
      logged_in_user: id,
      user_role: role,
			raw_user_role: role,
      username: username
    })
		this.getFeedsList();
    this.fetchQueueAndDrafts();
		this.fetchQuestions();
		this.getCoverageStatus();
		this.getUpdatesForSidebar();
		this.getUnpublishedUpdates();
		this.getUpdatesForReview();
		if (role === constants.USER_ROLES.GOD) {
			this.fetchUsers();
		}
  }

  componentDidMount() {
		if (window.innerWidth < 1000) {
			this.setState({
				show_sidebar: false
			})
		}
		let t = this;
		fetch(constants.BASE_API_URL + '/v1/auth/user', {
			credentials: 'include'
		}).then(function(res) {
			if (res.status == 200) {
        res.json().then(data => {
          t.setState({
  					checking_session: false,
  				})
  				t.onLogin(data.user._id, data.user.role, data.user.login_id);
        })
			} else {
				t.setState({
					checking_session: false
				})
			}
		})
	}

	toggleSidebar() {
		this.setState({
			show_sidebar: !this.state.show_sidebar
		})
	}

  handleLogout() {
    this.setState({
      logged_in: false,
      user_role: null,
      username: null,
      logged_in_user: null
    });
  }

	render() {
		return (
			<div className="app-container">
        {this.state.logged_in && !this.state.loading &&
          <div className="main-container">
						<MobileToggle onToggle={this.toggleSidebar.bind(this)} />
						{this.state.show_sidebar &&
							<Sidebar coverage_status={this.state.coverage_status} drafts={this.state.drafts} queue={this.state.queue} queue_count={this.state.live_queue_count} questions_count={this.state.questions_count} reviewing_updates_count={this.state.num_reviewing_updates} batched_count={this.state.batched_count} overnight_count={this.state.overnight_count} handleLogout={this.handleLogout.bind(this)} user_role={this.state.user_role} username={this.state.username} />
						}
    				<Route exact path="/" render={() => (
              <Queue user_role={this.state.raw_user_role} coverage_status={this.state.coverage_status} onCoverageChange={this.getCoverageStatus} queue={this.state.live_queue} sidebar_updates={this.state.sidebar_updates} />
            )} />
						<Route exact path="/questions" render={() => (
              <QuestionsQueue coverage_status={this.state.coverage_status} onCoverageChange={this.getCoverageStatus} is_questions_queue={true} queue={this.state.questions} sidebar_updates={this.state.sidebar_updates} />
            )} />
						<Route exact path="/review" render={() => (
              <ReviewingUpdates updates={this.state.reviewing_updates} rejected_updates={this.state.rejected_updates} />
            )} />
						<Route exact path="/batched" render={() => (
              <BatchedQueue onClaim={this.fetchDrafts} logged_in_user={this.state.logged_in_user} sidebar_updates={this.state.sidebar_updates} />
            )} />
    				<Route path="/draft/:id" render={(props) => (
        			<Draft coverage_status={this.state.coverage_status} admins={this.state.admin_options} onUpdateCreate={this.fetchDrafts.bind(this)} id={props.match.params.id} logged_in_user={this.state.logged_in_user} onSubmit={this.onUpdatePost.bind(this)} refreshReviewing={this.getUpdatesForReview} />
    				)} />
            <Route path="/feed/new" render={(props) => (
							<CreateFeed sidebar_updates={this.state.sidebar_updates} />
						)} />
            <Route exact path="/feeds/:id" render={(props) => (
        			<UpdateList id={props.match.params.id} refreshReviewing={this.getUpdatesForReview} logged_in_user={this.state.logged_in_user} user_role={this.state.user_role} onUpdateCreate={this.fetchDrafts.bind(this)} sidebar_updates={this.state.sidebar_updates} />
    				)} />
						<Route exact path="/feeds/:id/tooltips" render={(props) => (
        			<TooltipList id={props.match.params.id} user_role={this.state.user_role} />
    				)} />
            <Route exact path="/feeds/:id/edit" render={(props) => (
        			<EditFeed id={props.match.params.id} />
    				)} />
            <Route path="/update/:id" render={(props) => (
        			<Draft id={props.match.params.id} logged_in_user={this.state.logged_in_user} onSubmit={this.fetchDrafts.bind(this)} refreshReviewing={this.getUpdatesForReview} />
    				)} />
            <Route path="/users" render={() => (
              <Users admins={this.state.admins} users={this.state.users} total_users={this.state.total_users} onSubmit={this.fetchUsers.bind(this)} />
            )} />
						<Route path="/data" render={() => (
              <Data />
            )} />
						<Route path="/batch" render={() => (
              <Batch updates={this.state.batched_updates} onPublish={this.getUnpublishedUpdates.bind(this)}/>
            )} />
						<Route path="/overnight" render={() => (
              <Overnight updates={this.state.overnight_updates} onPublish={this.getUnpublishedUpdates.bind(this)}/>
            )} />
            <Route exact path="/feeds" render={(props) => (
              <Feeds onUpdateCreate={this.fetchDrafts.bind(this)} user_role={this.state.user_role} logged_in_user={this.state.logged_in_user} />
            )} />
						{this.state.raw_user_role === constants.USER_ROLES.GOD &&
							<ConfigModal />
						}
    				<CreateDraftModal logged_in_user={this.state.logged_in_user} feeds={this.state.feeds} admins={this.state.admin_options} onSubmit={this.fetchQueueAndDrafts} />
    				<Darken />
          </div>
        }
        {this.state.checking_session &&
          <div className="app-loading-container">
						<HashLoader
							className="loading-spinner"
							sizeUnit={"px"}
							size={150}
							color={'#E3E3E3'}
							loading={this.state.checking_session}
						/>
					</div>
        }
        {this.state.logged_in && this.state.loading &&
          <div className="app-loading-container">
						<HashLoader
							className="loading-spinner"
							sizeUnit={"px"}
							size={150}
							color={'#E3E3E3'}
							loading={this.state.loading}
						/>
					</div>
        }
        {!this.state.checking_session && !this.state.logged_in &&
					<Login onLogin={this.onLogin.bind(this)} />
				}
			</div>
		);
	}
}

class Darken extends Component {

	hideDarken() {
		$('.modal-container').hide();
		$('.darken').hide();
	}

	render() {
		return (
			<div className="darken" onClick={() => this.hideDarken()}></div>
		)
	}
}

class MobileToggle extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	toggleSidebar() {
		this.props.onToggle();
	}

	render() {
		return (
			<div className="mobile-menu-button" onClick={this.toggleSidebar.bind(this)}>
				<i class="fas fa-bars"></i>
			</div>
		)
	}
}

class CreateDraftModal extends Component {

	constructor(props) {
	  super(props);
	  this.state = {
			selected_feeds: null,
      feeds: props.feeds
		};
	}

  componentWillReceiveProps(props) {
    this.setState({
      feeds: props.feeds,
      admins: props.admins
    });
  }

	handleFeedsChange = (selected_feeds) => {
    this.setState({ selected_feeds });
  }

  handleChange(e) {
    this.setState({
      [$(e.target).attr('data-content')]: e.target.value
    })
  }

	handleSubmit() {
    var t = this;
    var data = {
      source: this.state.source,
      notes: this.state.notes,
      assigned_to: this.state.assigned,
      feed: this.state.selected_feeds[0].value,
      posted_by: this.props.logged_in_user
    }
    fetch(constants.BASE_API_URL + '/v1/drafts/create', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        $('.submit-modal').hide();
    		$('.darken').hide(); // FIX THIS, NO JQUERY HERE
        t.props.onSubmit();
				t.setState({
					source: null,
					notes: null,
					assigned_to: null,
					selected_feeds: null
				})
				$(".modal-input").val("");
				$('.modal-select').prop('selectedIndex',0);
      })
      .catch(error => console.error('Error:', error));
	}

	render() {
		return (
			<div className="modal-container submit-modal">
				<div className="modal-top-container">
					<p className="modal-title">create draft</p>
				</div>
				<div className="submit-input-container">
					<input type="text" onChange={this.handleChange.bind(this)} className="modal-input" data-content="source" name="source" placeholder="source URL" />
          {this.state.feeds &&
            <Select
              value={this.state.selected_feeds}
              isMulti={true}
              name="update-feeds"
              placeholder="select feed"
              className="feeds-selector"
              onChange={this.handleFeedsChange}
              options={this.state.feeds}
            />
          }
					<textarea name="notes" className="modal-input" onChange={this.handleChange.bind(this)} data-content="notes" placeholder="notes"></textarea>
					<select className="modal-select" onChange={this.handleChange.bind(this)} defaultValue="assign" data-content="assigned">
						<option value="assign" disabled>assign</option>
					  <option value="5c1d6ca4ca64a600047447c0">queue</option>
					  {this.props.admins}
					</select>
					<div className="fas fa-sort-down"></div>
				</div>
				<div className="modal-submit-button" onClick={() => this.handleSubmit()}>
					<span className="fas fa-check"></span>
					SUBMIT
				</div>
			</div>
		)
	}
}

class ConfigModal extends Component {

	constructor(props) {
	  super(props);
	  this.state = {
			wake_time: ""
		};
	}

	componentDidMount() {
		this.fetchConfig();
	}

	fetchConfig() {
		fetch(constants.BASE_API_URL + '/v1/config', {
			credentials: 'include'
		}).then(res => res.json())
			.then(config => {
				this.setState({
					wake_time: config.config.wake_time
				})
			})
	}

  handleChange(e) {
    this.setState({
      [$(e.target).attr('data-content')]: e.target.value
    })
  }

	handleSubmit() {
    var t = this;
    var data = {
      time: this.state.wake_time
    }
    fetch(constants.BASE_API_URL + '/v1/config/time', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        $('.config-modal').hide();
    		$('.darken').hide(); // FIX THIS, NO JQUERY HERE
      })
      .catch(error => console.error('Error:', error));
	}

	render() {
		return (
			<div className="modal-container config-modal">
				<div className="modal-top-container">
					<p className="modal-title">edit config</p>
				</div>
				<div className="submit-input-container">
					<input type="text" onChange={this.handleChange.bind(this)} value={this.state.wake_time} data-content="wake_time" name="time" placeholder="wake time" />
				</div>
				<div className="modal-submit-button" onClick={() => this.handleSubmit()}>
					<span className="fas fa-check"></span>
					SUBMIT
				</div>
			</div>
		)
	}
}

class Sidebar extends Component {

	constructor(props) {
	  super(props);
	  this.state = {};
	}

  logout() {
		fetch(constants.BASE_API_URL + '/v1/auth/logout', {
			credentials: 'include',
			method: 'POST'
		}).then(res => res.json())
			.then(data => {
				if (data.success) {
					this.props.handleLogout();
				}
			})
	}

	render() {
		return (
			<div className="sidebar-container">
				<div className="sidebar-top-container">
					<Link to="/">
						<div className="sidebar-title">
              <img src={logo} className="sidebar-icon" />
							{window.location.href.includes("sandbox") &&
								<span>
									sandbox
								</span>
							}
							{!window.location.href.includes("sandbox") &&
								<span>
									curator
								</span>
							}
              <span className="version-label">beta</span>
						</div>
					</Link>
					<div className="current-user">
						{this.props.username} / {constants.ROLE_STRINGS[this.props.user_role]} /
            <i className="fas fa-sign-out" onClick={this.logout.bind(this)}></i>
					</div>
				</div>
				<div className="sidebar-buttons-container">
					{this.props.coverage_status === constants.COVERAGE_STATUS.PAUSED &&
						<Link to="/overnight">
							<div className="coverage-paused-button">
								<div className="sidebar-button-label">
									<i className="fas fa-moon"></i>
									coverage asleep
								</div>
							</div>
						</Link>
					}
					<NavLink exact to="/" activeClassName="active-sidebar-button">
						<div className="update-queue-button">
							<div className="sidebar-button-label">
								<i className="fas fa-bars"></i>
								live queue
								<div className="update-count-container">
									<div className="update-count">{this.props.queue_count}</div>
								</div>
							</div>
						</div>
					</NavLink>
					<NavLink exact to="/batched" activeClassName="active-sidebar-button">
						<div className="update-queue-button">
							<div className="sidebar-button-label">
								<i className="fas fa-bars"></i>
								batched queue
							</div>
						</div>
					</NavLink>
				</div>
				<div className="sidebar-separator"></div>
				<div className={this.props.coverage_status === constants.COVERAGE_STATUS.PAUSED ? "sidebar-drafts-container-paused" : "sidebar-drafts-container"}>
          {this.props.drafts}
				</div>
        <div className="sidebar-bottom-container">
					<div className="sidebar-separator-bottom"></div>
          <NavLink to="/feeds" activeClassName="active-sidebar-button">
            <div className="sidebar-bottom-button">
              <div className="sidebar-button-label">
                <i className="fas fa-bars"></i>
                manage feeds
                <i className="fas fa-chevron-right"></i>
              </div>
            </div>
          </NavLink>
					{this.props.coverage_status === constants.COVERAGE_STATUS.PAUSED &&
						<NavLink to="/overnight" activeClassName="active-sidebar-button">
							<div className="sidebar-bottom-button">
								<div className="sidebar-button-label">
									<i className="fas fa-moon"></i>
									overnight batch
									<div className="update-count-container">
										<div className="update-count">{this.props.overnight_count}</div>
									</div>
								</div>
							</div>
						</NavLink>
					}
					<NavLink to="/batch" activeClassName="active-sidebar-button">
						<div className="sidebar-bottom-button">
							<div className="sidebar-button-label">
								<i className="fas fa-clock"></i>
								manage batch
								<div className="update-count-container">
									<div className="update-count">{this.props.batched_count}</div>
								</div>
							</div>
						</div>
					</NavLink>
					{this.props.user_role === constants.USER_ROLES.GOD &&
						<NavLink to="/review" activeClassName="active-sidebar-button">
							<div className="sidebar-bottom-button">
								<div className="sidebar-button-label">
									<i className="fas fa-edit"></i>
									for review
									<div className="update-count-container">
										<div className="update-count">{this.props.reviewing_updates_count}</div>
									</div>
								</div>
							</div>
						</NavLink>
					}
					{this.props.user_role === constants.USER_ROLES.CURATOR &&
						<NavLink to="/review" activeClassName="active-sidebar-button">
							<div className="sidebar-bottom-button">
								<div className="sidebar-button-label">
									<i className="fas fa-edit"></i>
									in review
									<div className="update-count-container">
										<div className="update-count">{this.props.reviewing_updates_count}</div>
									</div>
								</div>
							</div>
						</NavLink>
					}
					<NavLink to="/data" activeClassName="active-sidebar-button">
            <div className="sidebar-bottom-button">
              <div className="sidebar-button-label">
                <i className="fas fa-chart-line"></i>
                data dashboard
                <i className="fas fa-chevron-right"></i>
              </div>
            </div>
          </NavLink>
					<NavLink to="/questions" activeClassName="active-sidebar-button">
						<div className="sidebar-bottom-button">
							<div className="sidebar-button-label">
								<i className="fas fa-question"></i>
								questions
								<div className="update-count-container">
									<div className="update-count">{this.props.questions_count}</div>
								</div>
							</div>
						</div>
					</NavLink>
					{this.props.user_role === constants.USER_ROLES.GOD &&
	          <NavLink to="/users" activeClassName="active-sidebar-button">
	            <div className="sidebar-bottom-button">
	              <div className="sidebar-button-label">
	                <i className="fas fa-users"></i>
	                manage users
	                <i className="fas fa-chevron-right"></i>
	              </div>
	            </div>
	          </NavLink>
					}
        </div>
			</div>
		)
	}

}

class SidebarDraft extends Component {

  constructor(props) {
    super(props);
    this.state = {
      timer: this.props.timer
    };
    setInterval(this.incrementTimer.bind(this), 1000);
  }

  incrementTimer() {
    this.setState({
      timer: Number(this.state.timer) + 1
    });
  }

	render() {
		let link = "/draft/" + this.props.id;
		return (
			<NavLink to={link} activeClassName="active-draft-container">
				<div className="update-draft-container" style={{backgroundColor: shadeColor(this.props.color, 11)}}>
					<div className="update-draft-top-container">
						<div className="feed-slug" style={{backgroundColor: this.props.color}}>
							<div className="fas fa-hashtag"></div>
							{this.props.name}
						</div>
						<div className="update-status-label active-status-label">
							DRAFT - <TimeAgo date={this.props.claimed_at} />
						</div>
						<div className="fas fa-chevron-right"></div>
					</div>
				</div>
			</NavLink>
		)
	}

}

class Queue extends Component {

	constructor(props) {
		super(props);
		this.state = {
			confirm_toggle: false,
			reminder_confirm: false,
			coverage_status: props.coverage_status
		};
	}

	showSubmit() {
		$('.darken').show(0, function() {
			$('.submit-modal').show();
		});
	}

	showConfig() {
		$('.darken').show(0, function() {
			$('.config-modal').show();
		});
	}

	confirmCoverage() {
		this.setState({
			confirm_toggle: true
		})
	}

	confirmReminder() {
		this.setState({
			reminder_confirm: true
		})
	}

	sendReminder() {
		let t = this;
		fetch(constants.BASE_API_URL + '/v1/config/send-reminder', {
			method: 'POST',
			credentials: 'include'
		})
		alert("Reminder notification sent");
		this.setState({
			reminder_confirm: false
		})
	}

	sleepCoverage() {
		let t = this;
		fetch(constants.BASE_API_URL + '/v1/config/sleep', {
			method: 'POST',
			credentials: 'include'
		}).then(res => res.json())
			.then(res => {
				if (res.error) {
					alert(res.error);
				} else {
					this.setState({
						coverage_status: constants.COVERAGE_STATUS.PAUSED,
						confirm_toggle: false
					})
					t.props.onCoverageChange();
				}
			})
	}

	wakeCoverage() {
		let t = this;
		fetch(constants.BASE_API_URL + '/v1/config/wake', {
			method: 'POST',
			credentials: 'include'
		}).then(res => res.json())
			.then(res => {
				if (res.error) {
					alert(res.error);
				} else {
					this.setState({
						coverage_status: constants.COVERAGE_STATUS.ACTIVE,
						confirm_toggle: false
					})
					t.props.onCoverageChange();
				}
			})
	}

	render() {
		return (
			<div className="queue-container">
				<div className="queue-top-container">
					<div className="manage-feeds-top-button add-feed-button" onClick={this.showSubmit}>
						<i className="fas fa-plus"></i>
						create draft
					</div>
					{this.props.user_role === constants.USER_ROLES.GOD &&
						<div className="manage-feeds-top-button coverage-toggle-button" onClick={this.showConfig.bind(this)}>
							<i className="fas fa-wrench"></i>
							config
						</div>
					}
					{this.state.coverage_status === constants.COVERAGE_STATUS.ACTIVE && !this.state.confirm_toggle &&
						<div className="manage-feeds-top-button coverage-toggle-button" onClick={this.confirmCoverage.bind(this)}>
							<i className="fas fa-moon"></i>
							sleep coverage
						</div>
					}
					{this.state.coverage_status === constants.COVERAGE_STATUS.ACTIVE && this.state.confirm_toggle &&
						<div className="manage-feeds-top-button coverage-toggle-button confirm-warning" onClick={this.sleepCoverage.bind(this)}>
							<i className="fas fa-exclamation-triangle"></i>
							are you sure?
						</div>
					}
					{this.props.user_role === constants.USER_ROLES.GOD && !this.state.reminder_confirm &&
						<div className="manage-feeds-top-button coverage-toggle-button" onClick={this.confirmReminder.bind(this)}>
							<i className="fas fa-bell"></i>
							send reminder
						</div>
					}
					{this.props.user_role === constants.USER_ROLES.GOD && this.state.reminder_confirm &&
						<div className="manage-feeds-top-button coverage-toggle-button confirm-warning" onClick={this.sendReminder.bind(this)}>
							<i className="fas fa-exclamation-triangle"></i>
							are you sure?
						</div>
					}
					{this.state.coverage_status === constants.COVERAGE_STATUS.PAUSED && !this.state.confirm_toggle &&
						<div className="manage-feeds-top-button coverage-toggle-button" onClick={this.confirmCoverage.bind(this)}>
							<i className="fas fa-sun"></i>
							wake coverage
						</div>
					}
					{this.state.coverage_status === constants.COVERAGE_STATUS.PAUSED && this.state.confirm_toggle &&
						<div className="manage-feeds-top-button coverage-toggle-button confirm-warning" onClick={this.wakeCoverage.bind(this)}>
							<i className="fas fa-exclamation-triangle"></i>
							are you sure?
						</div>
					}
				</div>
				<div className="queue-left-container">
          {this.props.queue}
				</div>
				<div className="queue-right-container">
					<div className="draft-right-item">
						<div className="sidebar-updates-container queue-updates">
							{this.props.sidebar_updates}
						</div>
					</div>
				</div>
			</div>
		)
	}

}

class QuestionsQueue extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="queue-container">
				<div className="queue-left-container">
          {this.props.queue.filter(draft => draft.props.status.toString() === constants.DRAFT_STATUS.QUEUED.toString())}
					<div className="questions-section-title">
						posted questions
					</div>
					{this.props.queue.filter(draft => draft.props.status.toString() === constants.DRAFT_STATUS.POSTED.toString())}
				</div>
				<div className="queue-right-container">
					<div className="draft-right-item">
						<div className="sidebar-updates-container queue-updates">
							{this.props.sidebar_updates}
						</div>
					</div>
				</div>
			</div>
		)
	}

}

class BatchedQueue extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: true
		};
	}

	componentDidMount() {
		this.fetchBatchQueue();
	}

	selectBatchFeed(id) {
		this.setState({
			selected_batched_feed: id,
			batch_queue: this.state.raw_batch_queue.filter(queue => queue.props.feed_id === id).slice(0, 70)
		})
	}

	deleteFromBatchedQueue(id) {
		let new_queue_counts = Object.assign({}, this.state.queue_counts);
		new_queue_counts[this.state.selected_batched_feed] = new_queue_counts[this.state.selected_batched_feed] - 1;
		this.setState({
			batch_queue: this.state.batch_queue.filter(update => update.props.id !== id),
			queue_counts: new_queue_counts
		})
	}

	fetchBatchQueue() {
    fetch(constants.BASE_API_URL + '/v1/drafts/queue/batched', {
      credentials: 'include'
    }).then(res => res.json())
			.then(queued_drafts => {

        fetch(constants.BASE_API_URL + '/v1/feeds/queue', {
          credentials: 'include'
        }).then(res => res.json())
    			.then(updates_for_drafts => {

            this.setState({
              raw_updates_for_drafts: updates_for_drafts
            })

						let batched_feeds = [];
						let queue_counts = {};

    				let queue = queued_drafts.all_drafts.map((draft) => {

							var updates = [];

							if (this.state.raw_updates_for_drafts[draft.feed._id]) {
								updates = this.state.raw_updates_for_drafts[draft.feed._id].map((update) => {
	    						return (
	    							<Update key={update._id} tooltips={update.tooltips} crosspost={update.crosspost} status={update.status} importance={update.importance} show_buttons={false} small_tweet={true} type={update.type} color={draft.feed.color} feed_id={draft.feed._id} content={update.content} num_reacts={update.num_reacts} id={update._id} draft_id={update.draft_id} source={update.source} timestamp={update.created_at} visible={update.visible} reacted={false} />
	    						)
	    					})
							}
							if (!batched_feeds.includes(JSON.stringify(draft.feed))) {
								batched_feeds.push(JSON.stringify(draft.feed));
								queue_counts[draft.feed._id] = queued_drafts.all_drafts.filter(queued_draft => queued_draft.feed._id === draft.feed._id).length
							}
    					return (
    						<QueuedUpdate feed_id={draft.feed._id} is_batched={true} key={draft._id} feeds={this.state.feeds} id={draft._id} updates={updates} feed_id={draft.feed._id} onDelete={this.deleteFromBatchedQueue.bind(this)} onClaim={this.props.onClaim} name={draft.feed.name} color={draft.feed.color} scout_name={draft.posted_by.login_id} source={draft.source} logged_in_user={this.props.logged_in_user} timestamp={draft.created_at} />
    					)
    				})

    				this.setState({
							raw_batch_queue: queue,
							batched_feeds: batched_feeds,
							queue_counts: queue_counts,
							loading: false
            });

						this.selectBatchFeed(JSON.parse(batched_feeds[0]).id);

          })
			})
  }

	showSubmit() {
		$('.darken').show(0, function() {
			$('.submit-modal').show();
		});
	}

	clearCurrent() {
		if (this.state.selected_batched_feed) {
			var data = {
	      feed: this.state.selected_batched_feed
	    }
			let t = this;
			fetch(constants.BASE_API_URL + '/v1/drafts/queue/clear', {
	      method: 'POST',
	      credentials: 'include',
	      body: JSON.stringify(data),
	      headers: {
	        'Content-Type': 'application/json'
	      }
	    }).then(res => res.json())
	      .then(response => {
					if (response.err) {
	          alert(JSON.stringify(response.err));
	        } else {
						alert(t.state.queue_counts[t.state.selected_batched_feed] + " drafts deleted.")
					}
	      })
	      .catch(error => console.error('Error:', error));
		}
	}

	clearOld() {
		if (this.state.selected_batched_feed) {
			var data = {
	      feed: this.state.selected_batched_feed
	    }
			let t = this;
			fetch(constants.BASE_API_URL + '/v1/drafts/queue/clear-old', {
	      method: 'POST',
	      credentials: 'include',
	      body: JSON.stringify(data),
	      headers: {
	        'Content-Type': 'application/json'
	      }
	    }).then(res => res.json())
	      .then(response => {
					if (response.err) {
	          alert(JSON.stringify(response.err));
	        } else {
						alert("Old drafts deleted.")
					}
	      })
	      .catch(error => console.error('Error:', error));
		}
	}

	render() {
		var twice_daily_feed_buttons = null;
		var daily_feed_buttons = null;
		var live_feed_buttons = null;
		if (this.state.batched_feeds) {
			twice_daily_feed_buttons = this.state.batched_feeds.map(feed => {
				feed = JSON.parse(feed);
				if (feed.coverage_type === constants.COVERAGE_TYPES.TWICE_DAILY) {
					let button_class = this.state.selected_batched_feed === feed._id ? "feed-slug feed-button selected-feed-button" : "feed-slug feed-button";
					return (
						<div className={button_class} style={{backgroundColor: feed.color}} onClick={() => this.selectBatchFeed(feed._id)}>
							<div className="fas fa-hashtag"></div>
							{feed.name}
							<span className="queue-count" style={{color: feed.color}}>
								{this.state.queue_counts[feed._id]}
							</span>
						</div>
					)
				}
			})
			daily_feed_buttons = this.state.batched_feeds.map(feed => {
				feed = JSON.parse(feed);
				if (feed.coverage_type === constants.COVERAGE_TYPES.DAILY) {
					let button_class = this.state.selected_batched_feed === feed._id ? "feed-slug feed-button selected-feed-button" : "feed-slug feed-button";
					return (
						<div className={button_class} style={{backgroundColor: feed.color}} onClick={() => this.selectBatchFeed(feed._id)}>
							<div className="fas fa-hashtag"></div>
							{feed.name}
							<span className="queue-count" style={{color: feed.color}}>
								{this.state.queue_counts[feed._id]}
							</span>
						</div>
					)
				}
			})
			live_feed_buttons = this.state.batched_feeds.map(feed => {
				feed = JSON.parse(feed);
				if (feed.coverage_type === constants.COVERAGE_TYPES.LIVE) {
					let button_class = this.state.selected_batched_feed === feed._id ? "feed-slug feed-button selected-feed-button" : "feed-slug feed-button";
					return (
						<div className={button_class} style={{backgroundColor: feed.color}} onClick={() => this.selectBatchFeed(feed._id)}>
							<div className="fas fa-hashtag"></div>
							{feed.name}
							<span className="queue-count" style={{color: feed.color}}>
								{this.state.queue_counts[feed._id]}
							</span>
						</div>
					)
				}
			})
		}
		return (
			<div className="queue-container">
				<div className="queue-top-container">
					<div className="manage-feeds-top-button add-feed-button" onClick={this.showSubmit}>
						<i className="fas fa-plus"></i>
						create draft
					</div>
					<div className="manage-feeds-top-button clear-current-button" onClick={this.clearCurrent.bind(this)}>
						<i className="fas fa-times"></i>
						clear all
					</div>
					<div className="manage-feeds-top-button clear-current-button" onClick={this.clearOld.bind(this)}>
						<i className="fas fa-times"></i>
						clear old
					</div>
				</div>
				{this.state.loading &&
					<div className="manage-feeds-loading-container">
						<HashLoader
							className="loading-spinner"
							sizeUnit={"px"}
							size={150}
							color={'#E3E3E3'}
							loading={this.state.loading}
						/>
					</div>
				}
				{!this.state.loading &&
					<div>
						{live_feed_buttons.length > 0 &&
							<div>
								<div className="queue-label">LIVE FEEDS</div>
								<div className="feed-buttons-container">
									{live_feed_buttons}
								</div>
							</div>
						}
						{daily_feed_buttons.filter(feed => feed).length > 0 &&
							<div>
								<div className="queue-label second-label">DAILY FEEDS</div>
								<div className="feed-buttons-container">
									{daily_feed_buttons}
								</div>
							</div>
						}
						{twice_daily_feed_buttons.filter(feed => feed).length > 0 &&
							<div>
								<div className="queue-label second-label">TWICE-DAILY FEEDS</div>
								<div className="feed-buttons-container">
									{twice_daily_feed_buttons}
								</div>
							</div>
						}
						<div className="queue-left-container batch-queue-container">
		          {this.state.batch_queue}
						</div>
						<div className="queue-right-container">
							<div className="draft-right-item">
								<div className="sidebar-updates-container queue-updates">
									{this.props.sidebar_updates}
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		)
	}

}

class QueuedUpdate extends Component {

	constructor(props) {
	  super(props);
	  this.state = {
			is_deleted: false,
			feed_name: props.name,
			feed_color: props.color,
			confirm_notify: false
		};
	}

	claim(e) {
		e.stopPropagation();
		this.props.onDelete(this.props.id);
    var t = this;
    var data = {
      user: this.props.logged_in_user
    }
    fetch(constants.BASE_API_URL + '/v1/drafts/' + this.props.id + '/assign', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
				if (response.error) {
          alert(JSON.stringify(response.error));
        } else {
					this.props.onClaim();
				}
      })
      .catch(error => console.error('Error:', error));
	}

	swap(e) {
		let t = this;
		e.stopPropagation();
		$('.darken').show(0, function() {
			$('.switch-draft-modal-' + t.props.id).show();
		});
	}

	onSwitch(feed_name, feed_color) {
		this.setState({
			feed_name: feed_name,
			feed_color: feed_color
		})
	}

	delete(e) {
    e.stopPropagation();
		this.setState({
      is_deleted: true
    })
    let t = this;
    fetch(constants.BASE_API_URL + '/v1/drafts/' + this.props.id + '/delete', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        if (response.error) {
          alert(JSON.stringify(response.error));
        } else {
					this.props.onDelete(this.props.id);
				}
      })
      .catch(error => console.error('Error:', error));
	}

	confirmNotify() {
		this.setState({
			confirm_notify: true
		})
	}

	notifyForQuestion() {
		this.setState({
			confirm_notify: false
		})
		var data = {
      user_id: this.props.scout_name,
			feed_id: this.props.feed_id
    }
    let t = this;
    fetch(constants.BASE_API_URL + '/v1/questions/notify', {
      method: 'POST',
      credentials: 'include',
			body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        if (response.err) {
          alert(JSON.stringify(response.err));
        } else {
					alert(this.props.scout_name + " notified.");
				}
      })
      .catch(error => console.error('Error:', error));
	}

	render() {
		return (
			<div className="queued-update" style={this.state.is_deleted ? {display: 'none'} : {}}>
				<div className="queued-update-left-container">
					<Link to={"/feeds/" + this.props.feed_id}>
						<div className="feed-slug" style={{backgroundColor: this.state.feed_color}}>
							<div className="fas fa-hashtag"></div>
							{this.state.feed_name}
						</div>
					</Link>
					{!this.props.is_question &&
						<span className="update-scout-label">
							/ <span className="bold-text">{this.props.scout_name}</span> <TimeAgo date={this.props.timestamp} />
						</span>
					}
					{this.props.is_question &&
						<span className="update-scout-label">
							<span className="user-id">{this.props.scout_name}</span> <TimeAgo date={this.props.timestamp} />
						</span>
					}
				</div>
				<div className="queued-update-right-container">
					<div className="queue-claim-button" onClick={(e) => this.claim(e)}>
						claim
					</div>
					<div className="queue-reject-button" onClick={(e) => this.delete(e)}>
						delete
					</div>
					{this.props.is_question && !this.state.confirm_notify &&
						<div className="queue-reject-button" onClick={(e) => this.confirmNotify(e)}>
							notify
						</div>
					}
					{this.props.is_question && this.state.confirm_notify &&
						<div className="queue-reject-button confirm-warning" onClick={(e) => this.notifyForQuestion(e)}>
							are you sure?
						</div>
					}
					{!this.props.is_batched &&
						<div className="queue-swap-button" onClick={(e) => this.swap(e)}>
							swap
						</div>
					}
				</div>
        <div className="queued-update-bottom-container">
          <div className="queued-update-tweet-container">
						{!this.props.is_question &&
							<Tweet tweetId={this.props.source} />
						}
            {this.props.is_question &&
							<div className="queued-question-container">
								{this.props.notes}
							</div>
						}
          </div>
          <div className="queued-update-updates-container">
            {this.props.updates}
          </div>
        </div>
				{!this.props.is_batched &&
					<SwitchDraftFeedModal feeds={this.props.feeds} id={this.props.id} onSwitch={this.onSwitch.bind(this)} />
				}
			</div>
		)
	}

}

class EditFeed extends Component {

  constructor(props) {
	  super(props);
    this.state = {
      id: this.props.id,
      redirect: null,
			show_emojis: false,
      new_search_keywords: [],
      new_twitter_keywords: [],
			coverage_type: constants.COVERAGE_TYPES.LIVE
    }
		window.scrollTo(0, 0);
	}

  componentDidMount() {
    fetch(constants.BASE_API_URL + '/v1/feeds/' + this.state.id)
			.then(res => res.json())
			.then(data => {
				this.setState({
          name: data.feed.name,
          status: data.feed.status,
          new_name: data.feed.name,
          color: data.feed.color,
          new_color: data.feed.color,
          description: data.feed.description,
          new_description: data.feed.description,
          image_url: data.feed.image_url,
          new_image: data.feed.image_url,
          category: data.feed.category,
          new_category: data.feed.category,
					published_at: data.feed.published_at,
          search_keywords: data.feed.search_keywords ? data.feed.search_keywords.split(",") : [],
          new_search_keywords: data.feed.search_keywords ? data.feed.search_keywords.split(",") : [],
          twitter_keywords: data.feed.twitter_keywords ? data.feed.twitter_keywords.split(",") : [],
          new_twitter_keywords: data.feed.twitter_keywords ? data.feed.twitter_keywords.split(",") : [],
          notification_text: data.feed.notification_text,
          new_notification_text: data.feed.notification_text,
					emoji: data.feed.emoji,
					new_emoji: data.feed.emoji,
          is_featured: data.feed.is_featured,
          archived: data.feed.archived,
          visible: data.feed.visible,
					coverage_type: data.feed.coverage_type,
					new_coverage_type: data.feed.coverage_type,
          crop: {
            x: 0,
            y: 0,
            aspect: 28/9,
            width: 1000
          }
        })
				this.fetchUpdates(data.feed._id);
			})
  }

	fetchUpdates(id) {
		fetch(constants.BASE_API_URL + '/v1/feeds/' + id + '/updates/all?page=1', {
			credentials: "include"
		}).then(res => res.json())
			.then(data => {
				if (data.updates) {
					var updates = [];
					var start_banner_added = false;
					var unpublished_banner_added = false;
					let feed_has_unpublished_updates = data.updates.filter(update => update.status === constants.UPDATE_STATUS.UNPUBLISHED).length > 0;
					if (data.feed.status === constants.FEED_STATUS.ARCHIVED) {
						let archived_banner = (
							<FeedArchivedBanner archived_at={data.feed.archived_at} />
						)
						updates.push(archived_banner)
					}
					for (var i = 0; i < data.updates.length; i++) {
						var update = data.updates[i];
						if (data.feed.status !== constants.FEED_STATUS.UNPUBLISHED && !start_banner_added && new Date(update.created_at) < new Date(data.feed.published_at)) {
							let published_banner = (
								<FeedPublishedBanner published_at={data.feed.published_at} />
							)
							updates.push(published_banner)
							start_banner_added = true
						}
						if (feed_has_unpublished_updates && update.status === constants.UPDATE_STATUS.PUBLISHED && !unpublished_banner_added) {
							let unpublished_banner = (
								<UnpublishedUpdatesBanner />
							)
							updates.push(unpublished_banner)
							unpublished_banner_added = true
						}
						let update_component = (
							<Update key={update._id} tooltips={update.tooltips} crosspost={update.crosspost} show_buttons={false} small_tweet={true} status={update.status} importance={update.importance} type={update.type} color={data.feed.color} feed_id={data.feed._id} content={update.content} num_reacts={update.num_reacts} id={update._id} draft_id={update.draft_id} source={update.source} timestamp={update.created_at} visible={update.visible} reacted={false} refreshUpdates={this.fetchUpdates.bind(this)} />
						)
						updates.push(update_component);
					}
					if (data.feed.status !== constants.FEED_STATUS.UNPUBLISHED && !start_banner_added) {
						let published_banner = (
							<FeedPublishedBanner published_at={data.feed.published_at} />
						)
						updates.push(published_banner)
					}
					this.setState({
						updates: updates
					});
				}
			})
	}

  handleChange(e) {
    this.setState({
      [$(e.target).attr('data-content')]: e.target.value
    })
  }

	toggleEmojiPicker() {
		this.setState({
			show_emojis: !this.state.show_emojis
		})
	}

	selectEmoji(emoji) {
		this.setState({
			new_emoji: emoji.native,
			show_emojis: false
		})
	}

	deleteEmoji(e) {
		var key = e.keyCode || e.charCode;
		if (key == 8 || key == 46) {
			this.setState({
				new_emoji: "",
				show_emojis: false
			})
		}
	}

  handleColorChange(color) {
    this.setState({
      new_color: color.hex
    })
  }

  toggleColorPicker() {
    this.setState({
      show_color_picker: !this.state.show_color_picker
    })
  }

	handleDateChange(date) {
    this.setState({
      published_at: date
    })
  }

  hideColorPicker() {
    this.setState({
      show_color_picker: false
    })
  }

	selectCoverage(type) {
		this.setState({
			new_coverage_type: type
		})
	}

  handleSearchKeywordsChange(tags) {
    this.setState({
      new_search_keywords: tags
    })
  }

  handleTwitterKeywordsChange(tags) {
    this.setState({
      new_twitter_keywords: tags
    })
  }

  changeCrop(crop) {
    this.setState({ crop });
  }

  save() {
    var data = {
      name: this.state.new_name,
      color: this.state.new_color,
			emoji: this.state.new_emoji,
			published_at: this.state.published_at,
      description: this.state.new_description,
      category: this.state.new_category,
      search_keywords: this.state.new_search_keywords.join(","),
      twitter_keywords: this.state.new_twitter_keywords.join(","),
      image_url: this.state.new_image,
			coverage_type: this.state.new_coverage_type
    }
    if (this.state.status === constants.FEED_STATUS.UNPUBLISHED) {
      data.notification_text = this.state.new_notification_text
    }
    fetch(constants.BASE_API_URL + '/v1/feeds/' + this.state.id + '/edit', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        this.setState({redirect: true});
      })
      .catch(error => console.error('Error:', error));
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/feeds' />
    }
  }

  render() {
		return (
			<div className="draft-container">
        {this.renderRedirect()}
				<div className="draft-left-container">
					<div className="draft-section-label">FEED NAME</div>
					<div className="feed-input-container">
            <input onChange={this.handleChange.bind(this)} data-content="new_name" type="text" value={this.state.new_name} className="feed-input feed-name-input feed-text-input post-data" name="name" placeholder="name" />
            <div className="feed-slug" style={{backgroundColor: this.state.new_color}}>
              <div className="fas fa-hashtag"></div>
              {this.state.new_name}
            </div>
					</div>
          <div className="draft-section-label">FEED COLOR</div>
					<div className="feed-input-container">
            <div className="feed-slug color-slug" style={{backgroundColor: this.state.new_color}} onClick={this.toggleColorPicker.bind(this)}>
              <div className="fas fa-hashtag"></div>
              {this.state.new_color && this.state.new_color.substring(1)}
            </div>
					</div>
          {this.state.show_color_picker &&
            <div onMouseLeave={this.hideColorPicker.bind(this)}>
              <SketchPicker color={ this.state.new_color } presetColors={constants.DEFAULT_COLORS} onChange={ this.handleColorChange.bind(this) } />
            </div>
          }
					<div className="draft-section-label">COVERAGE TYPE</div>
					<div className="feed-input-container coverage-buttons-container">
            <div onClick={() => this.selectCoverage(constants.COVERAGE_TYPES.LIVE)} className={this.state.new_coverage_type === constants.COVERAGE_TYPES.LIVE ? "coverage-button selected-coverage" : "coverage-button"}>
							<i className="far fa-exclamation-triangle"></i>
							live
						</div>
						<div onClick={() => this.selectCoverage(constants.COVERAGE_TYPES.TWICE_DAILY)} className={this.state.new_coverage_type === constants.COVERAGE_TYPES.TWICE_DAILY ? "coverage-button selected-coverage" : "coverage-button"}>
							<i className="far fa-clock"></i>
							2x daily
						</div>
						<div onClick={() => this.selectCoverage(constants.COVERAGE_TYPES.DAILY)} className={this.state.new_coverage_type === constants.COVERAGE_TYPES.DAILY ? "coverage-button selected-coverage" : "coverage-button"}>
							<i className="far fa-calendar-alt"></i>
							daily
						</div>
					</div>
          <div className="draft-section-label">FEED IMAGE</div>
					<div className="feed-input-container">
            <input onChange={this.handleChange.bind(this)} data-content="new_image" type="text" value={this.state.new_image} className="feed-input feed-image-input feed-text-input post-data" name="image" placeholder="image URL" />
            {this.state.new_image && this.state.new_image.includes(".gif") &&
              <div className="feed-gif-message">
                recognized as gif
              </div>
            }
            <div className="feed-image-container">
              <ReactCrop src={this.state.new_image} crop={this.state.crop} onChange={this.changeCrop.bind(this)} />
            </div>
					</div>
          <div className="draft-section-label">CATEGORY</div>
					<div className="feed-input-container">
            <input type="text" onChange={this.handleChange.bind(this)} data-content="new_category" value={this.state.new_category} className="feed-input feed-category-input feed-text-input post-data" name="category" placeholder="category" />
					</div>
          <div className="draft-section-label">SEARCH KEYWORDS</div>
					<div className="feed-input-container">
            <TagsInput inputProps={{placeholder: "enter keywords"}} value={this.state.new_search_keywords} addKeys={[9, 13, 188]} onChange={this.handleSearchKeywordsChange.bind(this)} />
					</div>
          <div className="draft-section-label">TWITTER KEYWORDS</div>
					<div className="feed-input-container">
            <TagsInput inputProps={{placeholder: "enter keywords"}} value={this.state.new_twitter_keywords} addKeys={[9, 13, 188]} onChange={this.handleTwitterKeywordsChange.bind(this)} />
					</div>
          <div className="draft-section-label">DESCRIPTION</div>
					<div className="feed-input-container">
						<textarea onChange={this.handleChange.bind(this)} data-content="new_description" value={this.state.new_description} className="feed-input feed-description-input post-data" name="description" placeholder="write a description..."></textarea>
					</div>
					<div className="draft-section-label">PREVIEW</div>
					<div className="feed-tile-previews">
						<div className="tile-preview-container left-preview">
							<div className="feed-tile-preview" style={{backgroundColor: this.state.new_color}}>
								<div className="tile-preview-image-container">
									<img className="feed-tile-preview-image" src={this.state.new_image} />
								</div>
								<div className="feed-slug" style={{backgroundColor: "rgb(255, 255, 255, 0.2)"}}>
									<div className="fas fa-hashtag"></div>
									{this.state.new_name}
								</div>
								<div className="tile-preview-description">
									{this.state.new_description}
								</div>
								<div className="feed-tile-metadata">
		              <i class="fas fa-user"></i>
		              123
		              <i class="fas fa-sync-alt"></i>
		              12 days
		            </div>
							</div>
						</div>
						<div className="tile-preview-container right-preview">
							<div className="feed-tile-preview" style={{backgroundColor: this.state.color}}>
								<div className="tile-preview-image-container">
									<img className="feed-tile-preview-image" src={this.state.image_url} />
								</div>
								<div className="feed-slug" style={{backgroundColor: "rgb(255, 255, 255, 0.2)"}}>
									<div className="fas fa-hashtag"></div>
									{this.state.name}
								</div>
								<div className="feed-tile-metadata">
		              <i class="fas fa-user"></i>
		              123
		              <i class="fas fa-sync-alt"></i>
		              12 days
		            </div>
							</div>
						</div>
					</div>
					<div>
						<div className="draft-section-label update-time-label emoji-section-label">TIME PUBLISHED</div>
						<div className="source-input-container">
							<DatePicker
								className="feed-input update-time-input feed-text-input"
								selected={this.state.published_at}
								onChange={this.handleDateChange.bind(this)}
								showTimeSelect
								timeFormat="h:mm aa"
								timeIntervals={10}
								dateFormat="MMMM d, yyyy h:mm aa"
								timeCaption="time"
							/>
						</div>
					</div>
					<div className="draft-section-label">EMOJI</div>
					<div className="feed-input-container">
						<input autocomplete="off" onClick={this.toggleEmojiPicker.bind(this)} onKeyUp={this.deleteEmoji.bind(this)} data-content="new_emoji" type="text" className="feed-input emoji-input feed-text-input post-data" value={this.state.new_emoji} name="emoji" />
						<Picker style={!this.state.show_emojis ? {display: "none"} : {position: "absolute", marginTop: 70, marginLeft: -50}} set="apple" onSelect={this.selectEmoji.bind(this)} color={this.state.color} />
					</div>
          {this.state.status === constants.FEED_STATUS.UNPUBLISHED &&
            <div className="notification-input-container">
              <div className="draft-section-label">NOTIFICATION TEXT</div>
              <div className="feed-input-container">
                <input onChange={this.handleChange.bind(this)} data-content="new_notification_text" value={this.state.new_notification_text} type="text" className="feed-input feed-image-input feed-text-input post-data" name="notification text" placeholder="will be sent to all users..." />
              </div>
            </div>
          }
          <div className="bottom-feed-button save-button" onClick={() => this.save()}>
            <span className="fas fa-check"></span>
            SAVE CHANGES
          </div>
        </div>
				<div className="draft-right-container">
				<div className="draft-right-item">
					<div className="draft-section-label latest-updates-label">
						LATEST UPDATES
					</div>
					<div className="sidebar-updates-container">
						{this.state.updates}
					</div>
				</div>
				</div>
			</div>
		)
	}

}

class CreateFeed extends Component {

  constructor(props) {
	  super(props);
    this.state = {
      name: "",
      color: "#000000",
      image_url: "",
      description: "",
      category: "",
      search_keywords: [],
      twitter_keywords: [],
      notification_text: "",
			emoji: "",
			show_emojis: false,
			coverage_type: 0,
      streaming: true,
      crop: {
        x: 0,
        y: 0,
        aspect: 28/9,
        width: 1000
      },
      redirect: false,
      show_confirm: false,
      show_color_picker: false
    }
		window.scrollTo(0, 0);
	}

  create() {
    var data = {
      name: this.state.name,
      color: this.state.color,
      description: this.state.description,
      category: this.state.category,
      search_keywords: this.state.search_keywords.join(","),
      twitter_keywords: this.state.twitter_keywords.join(","),
      image_url: this.state.image_url,
      tweet_stream: this.state.streaming,
      notification_text: this.state.notification_text,
			emoji: this.state.emoji,
			coverage_type: this.state.coverage_type
    }
    fetch(constants.BASE_API_URL + '/v1/feeds/create', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        if (response.error) {
          alert(response.error);
        } else {
          this.setState({redirect: true});
        }
      })
      .catch(error => console.error('Error:', error));
  }

  handleChange(e) {
    this.setState({
      [$(e.target).attr('data-content')]: e.target.value
    })
  }

  handleSearchKeywordsChange(tags) {
    this.setState({
      search_keywords: tags
    })
  }

  handleTwitterKeywordsChange(tags) {
    this.setState({
      twitter_keywords: tags
    })
  }

  handleColorChange(color) {
    this.setState({
      color: color.hex
    })
  }

  toggleColorPicker() {
    this.setState({
      show_color_picker: !this.state.show_color_picker
    })
  }

	toggleEmojiPicker() {
		this.setState({
			show_emojis: !this.state.show_emojis
		})
	}

	selectEmoji(emoji) {
		this.setState({
			emoji: emoji.native,
			show_emojis: false
		})
	}

	deleteEmoji(e) {
		var key = e.keyCode || e.charCode;
		if (key == 8 || key == 46) {
			this.setState({
				emoji: "",
				show_emojis: false
			})
		}
	}

  hideColorPicker() {
    this.setState({
      show_color_picker: false
    })
  }

  changeCrop(crop) {
    this.setState({ crop });
  }

  updateStreaming(e) {
    this.setState({
      streaming: Boolean($(e.target).data('streaming'))
    })
  }

	selectCoverage(type) {
		this.setState({
			coverage_type: type
		})
	}

  showConfirm() {
    this.setState({
      show_confirm: true
    })
	}

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/feeds' />
    }
  }

  render() {
		return (
			<div className="draft-container">
        {this.renderRedirect()}
				<div className="draft-left-container">
					<div className="draft-section-label">FEED NAME</div>
					<div className="feed-input-container">
            <input onChange={this.handleChange.bind(this)} data-content="name" type="text" className="feed-input feed-name-input feed-text-input post-data" name="name" placeholder="name" />
            <div className="feed-slug" style={{backgroundColor: this.state.color}}>
              <div className="fas fa-hashtag"></div>
              {this.state.name}
            </div>
					</div>
          <div className="draft-section-label">FEED COLOR</div>
					<div className="feed-input-container">
            <div className="feed-slug color-slug" style={{backgroundColor: this.state.color}} onClick={this.toggleColorPicker.bind(this)}>
              <div className="fas fa-hashtag"></div>
              {this.state.color.substring(1)}
            </div>
					</div>
          {this.state.show_color_picker &&
            <div onMouseLeave={this.hideColorPicker.bind(this)}>
              <SketchPicker color={ this.state.color } presetColors={constants.DEFAULT_COLORS} onChange={ this.handleColorChange.bind(this) } />
            </div>
          }
					<div className="draft-section-label">COVERAGE TYPE</div>
					<div className="feed-input-container coverage-buttons-container">
            <div onClick={() => this.selectCoverage(0)} className={this.state.coverage_type === 0 ? "coverage-button selected-coverage" : "coverage-button"}>
							<i className="far fa-exclamation-triangle"></i>
							live
						</div>
						<div onClick={() => this.selectCoverage(1)} className={this.state.coverage_type === 1 ? "coverage-button selected-coverage" : "coverage-button"}>
							<i className="far fa-clock"></i>
							2x daily
						</div>
						<div onClick={() => this.selectCoverage(2)} className={this.state.coverage_type === 2 ? "coverage-button selected-coverage" : "coverage-button"}>
							<i className="far fa-calendar-alt"></i>
							daily
						</div>
					</div>
          <div className="draft-section-label">FEED IMAGE</div>
					<div className="feed-input-container">
            <input onChange={this.handleChange.bind(this)} data-content="image_url" type="text" className="feed-input feed-image-input feed-text-input post-data" name="image" placeholder="image URL" />
            {this.state.image_url && this.state.image_url.includes(".gif") &&
              <div className="feed-gif-message">
                recognized as gif
              </div>
            }
            <div className="feed-image-container">
              <ReactCrop src={this.state.image_url} crop={this.state.crop} onChange={this.changeCrop.bind(this)} />
            </div>
					</div>
          <div className="draft-section-label">CATEGORY</div>
					<div className="feed-input-container">
            <input type="text" onChange={this.handleChange.bind(this)} data-content="category" className="feed-input feed-category-input feed-text-input post-data" name="category" placeholder="category" />
					</div>
          <div className="draft-section-label">SEARCH KEYWORDS</div>
					<div className="feed-input-container">
            <TagsInput inputProps={{placeholder: "enter keywords"}} value={this.state.search_keywords} addKeys={[9, 13, 188]} onChange={this.handleSearchKeywordsChange.bind(this)} />
					</div>
          <div className="draft-section-label">TWITTER KEYWORDS</div>
					<div className="feed-input-container">
            <TagsInput inputProps={{placeholder: "enter keywords"}} value={this.state.twitter_keywords} addKeys={[9, 13, 188]} onChange={this.handleTwitterKeywordsChange.bind(this)} />
					</div>
          <div className="draft-section-label">DESCRIPTION</div>
					<div className="feed-input-container">
						<textarea onChange={this.handleChange.bind(this)} data-content="description" className="feed-input feed-description-input post-data" name="description" placeholder="write a description...">
              {this.state.description}
            </textarea>
					</div>
					<div className="draft-section-label">PREVIEW</div>
					<div className="feed-tile-previews">
						<div className="tile-preview-container left-preview">
							<div className="feed-tile-preview" style={{backgroundColor: this.state.color}}>
								<div className="tile-preview-image-container">
									<img className="feed-tile-preview-image" src={this.state.image_url} />
								</div>
								<div className="feed-slug" style={{backgroundColor: "rgb(255, 255, 255, 0.2)"}}>
									<div className="fas fa-hashtag"></div>
									{this.state.name}
								</div>
								<div className="tile-preview-description">
									{this.state.description}
								</div>
								<div className="feed-tile-metadata">
		              <i class="fas fa-user"></i>
		              123
		              <i class="fas fa-sync-alt"></i>
		              12 days
		            </div>
							</div>
						</div>
						<div className="tile-preview-container right-preview">
							<div className="feed-tile-preview" style={{backgroundColor: this.state.color}}>
								<div className="tile-preview-image-container">
									<img className="feed-tile-preview-image" src={this.state.image_url} />
								</div>
								<div className="feed-slug" style={{backgroundColor: "rgb(255, 255, 255, 0.2)"}}>
									<div className="fas fa-hashtag"></div>
									{this.state.name}
								</div>
								<div className="feed-tile-metadata">
		              <i class="fas fa-user"></i>
		              123
		              <i class="fas fa-sync-alt"></i>
		              12 days
		            </div>
							</div>
						</div>
					</div>
					<div className="draft-section-label emoji-section-label">EMOJI</div>
					<div className="feed-input-container">
            <input autocomplete="off" onClick={this.toggleEmojiPicker.bind(this)} onKeyUp={this.deleteEmoji.bind(this)} data-content="emoji" type="text" className="feed-input emoji-input feed-text-input post-data" value={this.state.emoji} name="emoji" />
						<Picker style={!this.state.show_emojis ? {display: "none"} : {position: "absolute", marginTop: 70, marginLeft: -50}} set="apple" onSelect={this.selectEmoji.bind(this)} color={this.state.color} />
					</div>
          <div className="draft-section-label">NOTIFICATION TEXT</div>
					<div className="feed-input-container">
            <input onChange={this.handleChange.bind(this)} data-content="notification_text" type="text" className="feed-input feed-image-input feed-text-input post-data" name="notification text" placeholder="will be sent to all users..." />
					</div>
          <div className="radios-input-container">
            <div className="feed-radios-container">
              <div className="update-radio-container">
                <div className="update-radio-button" data-streaming="true" onClick={this.updateStreaming.bind(this)} style={this.state.streaming ? {backgroundColor: "#666666"} : null}></div>
                <div className="update-radio-label">
                  STREAMING ON
                </div>
              </div>
              <div className="update-radio-container">
                <div className="update-radio-button" data-streaming="false" onClick={this.updateStreaming.bind(this)} style={!this.state.streaming ? {backgroundColor: "#666666"} : null}></div>
                <div className="update-radio-label">
                  STREAMING OFF
                </div>
              </div>
            </div>
          </div>
          {!this.state.show_confirm &&
            <div className="bottom-feed-button create-feed-button" onClick={() => this.showConfirm()}>
              <span className="fas fa-check"></span>
              CREATE FEED
            </div>
          }
          {this.state.show_confirm &&
            <div className="bottom-feed-button save-button" onClick={() => this.create()}>
              <span className="fas fa-check"></span>
              CONFIRM
            </div>
          }
				</div>
				<div className="draft-right-container">
					<div className="draft-right-item">
						<div className="sidebar-updates-container queue-updates">
							{this.props.sidebar_updates}
						</div>
					</div>
				</div>
			</div>
		)
	}

}

class Draft extends Component {

	constructor(props) {
	  super(props);
    this.state = this.getInitialState();
    this.fetchDraft(this.props.id);
	}

  getInitialState() {
    return {
      post_type: null,
      redirect: false,
      show_confirm: false,
      importance: 0,
			copy: 0,
      text: null,
      color: null,
      image_url: null,
      tooltip_text: null,
      caption: null,
      video_id: null,
      button_text: null,
      quote: null,
      attr: null,
			source: null,
      question: null,
      answer: null,
      tweet_html: null,
      created_at: null,
			is_faq: null,
			no_notif: false,
			is_crosspost: null,
			crosspost: null,
			tooltips: null,
			article_img: null,
			article_favicon: null,
	    article_title: null,
	    article_site: null,
	    article_url: null,
			has_created_top_news_draft: false
    }
  }

	// renderTooltipPreviews() {
	// 	if (this.state.tooltips) {
	// 		let tooltips = this.state.tooltips;
	// 		for (var i = 0; i < tooltips.length; i++) {
	// 			let tooltip_text = tooltips[i].tooltip_text.replace("\"", "").replace("\'", "")
	// 			let open_tag = '<span data-tip="' + tooltip_text + '" data-for="' + this.props.id + this.state.timestamp + '" style="cursor: default; border-bottom: 2px solid ' + this.props.color + '">';
	// 			let close_tag = "</span>";
	// 			if (this.props.type === constants.UPDATE_TYPES["TextUpdate"] && (this.props.content.text.split("data-tip").length - 1) !== tooltips.length) {
	// 				this.props.content.text = this.props.content.text.substr(0, tooltips[i].start) + open_tag + this.props.content.text.substr(tooltips[i].start)
	// 				this.props.content.text = this.props.content.text.substr(0, tooltips[i].end + open_tag.length) + close_tag + this.props.content.text.substr(tooltips[i].end + open_tag.length)
	// 			} else if (this.props.type === constants.UPDATE_TYPES["ImageUpdate"] && (this.props.content.caption.split("data-tip").length - 1) !== tooltips.length) {
	// 				this.props.content.caption = this.props.content.caption.substr(0, tooltips[i].start) + open_tag + this.props.content.caption.substr(tooltips[i].start)
	// 				this.props.content.caption = this.props.content.caption.substr(0, tooltips[i].end + open_tag.length) + close_tag + this.props.content.caption.substr(tooltips[i].end + open_tag.length)
	// 			} else if (this.props.type === constants.UPDATE_TYPES["LinkUpdate"] && (this.props.content.text.split("data-tip").length - 1) !== tooltips.length) {
	// 				this.props.content.text = this.props.content.text.substr(0, tooltips[i].start) + open_tag + this.props.content.text.substr(tooltips[i].start)
	// 				this.props.content.text = this.props.content.text.substr(0, tooltips[i].end + open_tag.length) + close_tag + this.props.content.text.substr(tooltips[i].end + open_tag.length)
	// 			} else if (this.props.type === constants.UPDATE_TYPES["QuoteUpdate"] && (this.props.content.quote.split("data-tip").length - 1) !== tooltips.length) {
	// 				this.props.content.quote = this.props.content.quote.substr(0, tooltips[i].start) + open_tag + this.props.content.quote.substr(tooltips[i].start)
	// 				this.props.content.quote = this.props.content.quote.substr(0, tooltips[i].end + open_tag.length) + close_tag + this.props.content.quote.substr(tooltips[i].end + open_tag.length)
	// 			} else if (this.props.type === constants.UPDATE_TYPES["TweetUpdate"] && (this.props.content.caption.split("data-tip").length - 1) !== tooltips.length) {
	// 				this.props.content.caption = this.props.content.caption.substr(0, tooltips[i].start) + open_tag + this.props.content.caption.substr(tooltips[i].start)
	// 				this.props.content.caption = this.props.content.caption.substr(0, tooltips[i].end + open_tag.length) + close_tag + this.props.content.caption.substr(tooltips[i].end + open_tag.length)
	// 			} else if (this.props.type === constants.UPDATE_TYPES["VideoUpdate"] && (this.props.content.caption.split("data-tip").length - 1) !== tooltips.length) {
	// 				this.props.content.caption = this.props.content.caption.substr(0, tooltips[i].start) + open_tag + this.props.content.caption.substr(tooltips[i].start)
	// 				this.props.content.caption = this.props.content.caption.substr(0, tooltips[i].end + open_tag.length) + close_tag + this.props.content.caption.substr(tooltips[i].end + open_tag.length)
	// 			} else if (this.props.type === constants.UPDATE_TYPES["QuestionUpdate"] && (this.props.content.answer.split("data-tip").length - 1) !== tooltips.length) {
	// 				this.props.content.answer = this.props.content.answer.substr(0, tooltips[i].start) + open_tag + this.props.content.answer.substr(tooltips[i].start)
	// 				this.props.content.answer = this.props.content.answer.substr(0, tooltips[i].end + open_tag.length) + close_tag + this.props.content.answer.substr(tooltips[i].end + open_tag.length)
	// 			} else if (this.props.type === constants.UPDATE_TYPES["FeedUpdate"] && (this.props.content.text.split("data-tip").length - 1) !== tooltips.length) {
	// 				this.props.content.text = this.props.content.text.substr(0, tooltips[i].start) + open_tag + this.props.content.text.substr(tooltips[i].start)
	// 				this.props.content.text = this.props.content.text.substr(0, tooltips[i].end + open_tag.length) + close_tag + this.props.content.text.substr(tooltips[i].end + open_tag.length)
	// 			}
	// 		}
	// 	}
	// }

	fetchUpdates(id) {
		fetch(constants.BASE_API_URL + '/v1.1/feeds/' + id + '/updates?page=1', {
			credentials: "include"
		}).then(res => res.json())
			.then(data => {
				if (data.updates) {
					var updates = [];
					var start_banner_added = false;
					var unpublished_banner_added = false;
					let feed_has_unpublished_updates = data.updates.filter(update => update.status === constants.UPDATE_STATUS.UNPUBLISHED).length > 0;
					if (data.feed.status === constants.FEED_STATUS.ARCHIVED) {
						let archived_banner = (
							<FeedArchivedBanner archived_at={data.feed.archived_at} />
						)
						updates.push(archived_banner)
					}
					for (var i = 0; i < data.updates.length; i++) {
						var update = data.updates[i];
						if (data.feed.status !== constants.FEED_STATUS.UNPUBLISHED && !start_banner_added && new Date(update.created_at) < new Date(data.feed.published_at)) {
							let published_banner = (
								<FeedPublishedBanner published_at={data.feed.published_at} />
							)
							updates.push(published_banner)
							start_banner_added = true
						}
						if (feed_has_unpublished_updates && update.status === constants.UPDATE_STATUS.PUBLISHED && !unpublished_banner_added) {
							let unpublished_banner = (
								<UnpublishedUpdatesBanner />
							)
							updates.push(unpublished_banner)
							unpublished_banner_added = true
						}
						let update_component = (
							<Update key={update._id} tooltips={update.tooltips} crosspost={update.crosspost} show_buttons={false} small_tweet={true} status={update.status} importance={update.importance} type={update.type} color={data.feed.color} feed_id={data.feed._id} content={update.content} num_reacts={update.num_reacts} id={update._id} draft_id={update.draft_id} source={update.source} timestamp={update.created_at} visible={update.visible} reacted={false} refreshUpdates={this.fetchUpdates.bind(this)} />
						)
						updates.push(update_component);
					}
					if (data.feed.status !== constants.FEED_STATUS.UNPUBLISHED && !start_banner_added) {
						let published_banner = (
							<FeedPublishedBanner published_at={data.feed.published_at} />
						)
						updates.push(published_banner)
					}
					this.setState({
						updates: updates
					});
				}
			})
	}

  fetchDraft(id) {
    fetch(constants.BASE_API_URL + '/v1/drafts/' + id, {
      credentials: 'include'
    }).then(res => res.json())
			.then(data => {

				this.setState({
          name: data.draft.feed.name,
          color: data.draft.feed.color,
          scout_name: data.draft.posted_by.login_id,
          posted_time: data.draft.created_at,
          draft_source: data.draft.source,
          notes: data.draft.notes,
          feed_id: data.draft.feed._id,
          draft_id: data.draft._id,
          draft_status: data.draft.status,
          feed_status: data.draft.feed.status,
					coverage_type: data.draft.feed.coverage_type
        });

				// if (this.state.draft_status !== constants.DRAFT_STATUS.POSTED && this.state.feed_status !== constants.FEED_STATUS.UNPUBLISHED && this.state.coverage_type === constants.COVERAGE_TYPES.LIVE && this.props.coverage_status === constants.COVERAGE_STATUS.PAUSED) {
				// 	this.setState({
				// 		created_at: data.draft.created_at
				// 	})
				// }

				this.fetchUpdates(data.draft.feed._id);
				this.fetchTooltips(data.draft.feed._id);

        if (data.draft.status === constants.DRAFT_STATUS.POSTED) {
          this.setState({
            post_type: constants.UPDATE_TYPES[data.draft.update.__t],
            update_id: data.draft.update._id,
            source: data.draft.update.source,
						no_notif: data.draft.update.no_notif,
            importance: data.draft.update.importance,
            created_at: new Date(data.draft.update.created_at),
						is_crosspost: Boolean(data.draft.update.crosspost),
						crosspost: data.draft.update.crosspost
          })
          this.setState(data.draft.update.content);
        } else if (data.draft.feed.status === constants.FEED_STATUS.UNPUBLISHED || this.state.coverage_type !== constants.COVERAGE_TYPES.LIVE) {
          this.setState({
            created_at: new Date()
          })
        } else {
					this.setState({
						created_at: data.draft.created_at
					})
				}
			})
  }

	fetchTooltips(id) {
		fetch(constants.BASE_API_URL + '/v1/feeds/' + id + "/tooltips", {
      credentials: 'include'
    }).then(res => res.json())
			.then(response => {
				this.setState({
					tooltips: response.tooltips
				})
			})
	}

	componentWillReceiveProps(props) {
    if (props.id !== this.props.id) {
      this.setState(this.getInitialState());
      this.fetchDraft(props.id);
    }
	}

	chooseUpdateType(e) {
		this.setState({
			post_type: $(e.currentTarget).data('post-type'),
			update_text: null,
      image_url: null,
      image_caption: null,
      show_confirm: false
		});
	}

	showConfirm() {
    this.setState({
      show_confirm: true
    })
	}

	setTimePostedToNow() {
    this.setState({
      created_at: Date.now()
    })
	}

	publish() {
    var data = {}
    if (this.state.draft_status === constants.DRAFT_STATUS.POSTED) {
      data = {
        type: this.state.post_type,
        source: this.state.source,
        created_at: new Date(this.state.created_at),
				importance: this.state.importance,
				is_faq: this.state.is_faq,
				no_notif: this.state.no_notif,
				force_notif: this.state.force_notif,
				crosspost: this.state.crosspost,
        content: {}
      }
    } else {
      data = {
        type: this.state.post_type,
        feed_id: this.state.feed_id,
        draft_id: this.state.draft_id,
        source: this.state.source,
				created_at: new Date(this.state.created_at),
        author: this.props.logged_in_user,
        importance: this.state.importance,
				crosspost: this.state.crosspost,
				is_faq: this.state.is_faq,
				no_notif: this.state.no_notif,
				force_notif: this.state.force_notif,
        content: {}
  		}
      if (this.state.feed_status === constants.FEED_STATUS.UNPUBLISHED || this.state.coverage_type !== constants.COVERAGE_TYPES.LIVE || this.props.coverage_status === constants.COVERAGE_STATUS.PAUSED) {
        data.created_at = new Date(this.state.created_at)
      }
    }
		if (this.state.post_type === 1) {
			data.should_copy = Boolean(this.state.copy);
		}
		$('.post-data').each(function() {
      if ($(this).val()) {
        data.content[$(this).attr('data-content')] = $(this).val().trim();
      }
		});

		let identified_tooltips = "";
		this.state.tooltips.map(tooltip => {
			if (data.content.text && data.content.text.toLowerCase().includes(tooltip.search_string.toLowerCase())) {
				identified_tooltips = identified_tooltips + tooltip.search_string + ", "
			}
		})

    var t = this;
		if (!identified_tooltips || (identified_tooltips && window.confirm("Identified tooltips: " + identified_tooltips))) {
			let publish_url = constants.BASE_API_URL + '/v1/updates/submit';
	    let edit_url = constants.BASE_API_URL + '/v1/updates/' + this.state.update_id + '/edit';
	    fetch(this.state.draft_status === constants.DRAFT_STATUS.POSTED ? edit_url : publish_url, {
	      method: 'POST',
	      credentials: 'include',
	      body: JSON.stringify(data),
	      headers: {
	        'Content-Type': 'application/json'
	      }
	    }).then(res => res.json())
	      .then(response => {
	        if (response.error) {
	          alert(JSON.stringify(response.error));
	        } else {
	          t.props.onSubmit();
						t.props.refreshReviewing();
	          t.setState({
	            redirect: true
	          })
	        }
	      })
	      .catch(error => console.error('Error:', error));
		}
	}

	toggleFAQ() {
		this.setState({
			is_faq: !this.state.is_faq
		})
	}

	toggleNotif() {
		this.setState({
			no_notif: !this.state.no_notif
		})
	}

	toggleForceNotif() {
		this.setState({
			force_notif: !this.state.force_notif
		})
	}

	toggleCrosspost() {
		this.setState({
			is_crosspost: !this.state.is_crosspost
		})
	}

	handleChange(e) {
    this.setState({
      [$(e.target).attr('data-content')]: e.target.value
    })
  }

	handleArticleChange(e) {
		let t = this;
		var data = {url: encodeURIComponent(e.target.value)};
		this.setState({
			article_url: e.target.value,
			source: e.target.value
		})
		fetch(constants.BASE_API_URL + '/v1/admin/get-metadata/' + encodeURIComponent(e.target.value), {
			method: 'GET',
      credentials: 'include'
    }).then(res => res.json())
			.then(response => {
				t.setState({
					article_img: response.data.image,
					article_favicon: response.data.site.favicon,
					article_title: response.data.title,
					article_site: response.data.site.name
				})
			})
	}

  handleDateChange(date) {
    this.setState({
      created_at: date
    })
  }

  updateImportance(e) {
    this.setState({
      importance: $(e.target).data('importance')
    })
  }

	updateCopy(e) {
    this.setState({
      copy: $(e.target).data('copy')
    })
  }

  showReassignModal() {
		$('.darken').show(0, function() {
			$('.reassign-draft-modal').show();
		});
	}

  onReassign() {
    this.props.onSubmit();
    this.setState({
      redirect: true
    })
  }

  delete() {
    var t = this;
    fetch(constants.BASE_API_URL + '/v1/drafts/' + this.props.id + '/delete', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.props.onSubmit();
        t.setState({
          redirect: true
        })
      })
      .catch(error => console.error('Error:', error));
  }

	createTopNewsDraft() {
		var t = this;
    var data = {
      assigned_to: this.props.logged_in_user,
      feed: "5cc72613e07b170017c2112a",
      posted_by: this.props.logged_in_user,
			source: this.state.draft_source,
			notes: this.state.text ? this.state.text : (this.state.caption ? this.state.caption : null)
    }
    fetch(constants.BASE_API_URL + '/v1/drafts/create', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.props.onUpdateCreate();
				this.setState({
					has_created_top_news_draft: true
				})
      })
      .catch(error => console.error('Error:', error));
	}

  getTweetID(html) {
    if (html) {
      var regex = new RegExp('\/status\/(...................)?');
			if (regex.exec(html)) {
				return regex.exec(html)[1]
			} else {
				return null
			}
    } else {
      return null;
    }
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      let redirect_url = "/feeds/" + this.state.feed_id;
      return <Redirect to={redirect_url} />
    }
  }

	render() {
		return (
			<div className="draft-container">
        {this.renderRedirect()}
        <ReassignDraftModal admins={this.props.admins} id={this.props.id} onReassign={this.onReassign.bind(this)} />
				<div className="draft-left-container">
					{this.state.draft_source && this.state.draft_source.length === 19 &&
						<div className="draft-mobile-source">
							<div className="draft-section-label">
								SOURCE
							</div>
							<Tweet tweetId={this.state.draft_source} options={{ width: '400' }} />
						</div>
					}
					{this.state.draft_source && this.state.draft_source.length > 19 &&
						<div className="draft-mobile-source">
							<div className="draft-section-label">
								SOURCE
							</div>
							<a href={this.state.draft_source} target="_blank">
								<div className="source-link">
									<i class="fas fa-external-link"></i>
									{this.state.draft_source}
								</div>
							</a>
						</div>
					}
					<div className="draft-section-label">
            UPDATE INFO
          </div>
					<div className="update-info-container">
						<div className="update-info-left">
							<Link to={"/feeds/"+ this.state.feed_id}>
								<div className="feed-slug" style={{backgroundColor: this.state.color}}>
									<div className="fas fa-hashtag"></div>
									{this.state.name}
								</div>
							</Link>
							<span className="update-scout-label">
								/ posted by <span className="bold-text">{this.state.scout_name}</span>
							</span>
              <div className="draft-actions-container">
                <div className="draft-action-button" onClick={this.delete.bind(this)}>
                  delete
                </div>
                <div className="draft-action-button" onClick={this.showReassignModal.bind(this)}>
                  reassign
                </div>
              </div>
						</div>
						<div className="update-info-right">
							<div className="update-stopwatch-container">
								<TimeAgo date={this.state.posted_time} />
							</div>
						</div>
					</div>
					<div className="draft-section-label">
            SELECT TYPE
          </div>
					<div className="draft-type-selector-container">
						<div className="post-type-selector">
							<div className={this.state.post_type === 8 ? "post-type active-post-type" : "post-type"} data-post-type="8" onClick={(e) => this.chooseUpdateType(e)}>
								<span className="fas fa-link"></span>
								Article
							</div>
							<div className={this.state.post_type === 0 ? "post-type active-post-type" : "post-type"} data-post-type="0" onClick={(e) => this.chooseUpdateType(e)}>
								<span className="fas fa-align-left"></span>
								Text
							</div>
							<div className={this.state.post_type === 1 ? "post-type active-post-type" : "post-type"} data-post-type="1" onClick={(e) => this.chooseUpdateType(e)}>
								<span className="fas fa-image"></span>
								Image
							</div>
							<div className={this.state.post_type === 5 ? "post-type active-post-type" : "post-type"} data-post-type="5" onClick={(e) => this.chooseUpdateType(e)}>
								<span className="fas fa-video"></span>
								Video
							</div>
							<div className={this.state.post_type === 2 ? "post-type active-post-type" : "post-type"} data-post-type="2" onClick={(e) => this.chooseUpdateType(e)}>
								<span className="fas fa-link"></span>
								Link
							</div>
							<div className={this.state.post_type === 3 ? "post-type active-post-type" : "post-type"} data-post-type="3" onClick={(e) => this.chooseUpdateType(e)}>
								<span className="fas fa-quote-right"></span>
								Quote
							</div>
							<div className={this.state.post_type === 4 ? "post-type active-post-type" : "post-type"} data-post-type="4" onClick={(e) => this.chooseUpdateType(e)}>
								<span className="fab fa-twitter"></span>
								Tweet
							</div>
              <div className={this.state.post_type === 6 ? "post-type active-post-type" : "post-type"} data-post-type="6" onClick={(e) => this.chooseUpdateType(e)}>
								<span className="fas fa-font"></span>
								Title
							</div>
              <div className={this.state.post_type === 7 ? "post-type active-post-type" : "post-type"} data-post-type="7" onClick={(e) => this.chooseUpdateType(e)}>
								<span className="fas fa-bars"></span>
								Feed
							</div>
						</div>
					</div>
					{(this.state.post_type !== null) &&
						<div className="draft-section-label update-draft-label">
              UPDATE DETAILS
            </div>
					}
					{this.state.post_type === 0 &&
						<div className="admin-input-container">
							<textarea onChange={ this.handleChange.bind(this) } data-content="text" value={this.state.text} className="admin-input admin-post-input post-data" placeholder="write some text"></textarea>
						</div>
					}
					{this.state.post_type === 1 &&
						<div className="admin-input-container">
              <input style={{display: "none"}} type="text" className="admin-input admin-text-input post-data" value={this.state.color} data-content="color" />
							<input onChange={ this.handleChange.bind(this) } data-content="image_url" value={this.state.image_url} type="text" id="image_url" className="admin-input admin-url-input post-data" placeholder="image URL" />
							<textarea onChange={ this.handleChange.bind(this) } data-content="caption" value={this.state.caption} id="caption" className="admin-input admin-caption-input post-data" placeholder="caption"></textarea>
							<input onChange={ this.handleChange.bind(this) } data-content="button_text" value={this.state.button_text} type="text" id="button_text" className="admin-input admin-url-input post-data" placeholder="button (optional)" />
              {this.state.image_url && this.state.image_url.includes(".gif") &&
                <div className="update-gif-message">
                  recognized as gif
                </div>
              }
							<div className="radios-input-container image-radios-input">
                <div className="update-radios-container">
                  <div className="update-radio-container">
                    <div className="update-radio-button" data-copy="0" onClick={this.updateCopy.bind(this)} style={this.state.copy === 0 ? {backgroundColor: this.state.color} : null}></div>
                    <div className="update-radio-label">
                      DEEP LINK
                    </div>
                  </div>
                  <div className="update-radio-container">
                    <div className="update-radio-button" data-copy="1" onClick={this.updateCopy.bind(this)} style={this.state.copy === 1 ? {backgroundColor: this.state.color} : null}></div>
                    <div className="update-radio-label">
                      COPY TO S3
                    </div>
                  </div>
                </div>
              </div>
						</div>
					}
					{this.state.post_type === 5 &&
						<div className="admin-input-container">
              <input style={{display: "none"}} type="text" className="admin-input admin-text-input post-data" value={this.state.color} data-content="color" />
							<input onChange={ this.handleChange.bind(this) } type="text" className="admin-input admin-url-input post-data" value={this.state.video_id} data-content="video_id" placeholder="YouTube ID" />
							<textarea onChange={ this.handleChange.bind(this) } className="admin-input admin-caption-input post-data" value={this.state.caption} data-content="caption" placeholder="caption"></textarea>
						</div>
					}
					{this.state.post_type === 2 &&
						<div className="admin-input-container">
              <textarea onChange={ this.handleChange.bind(this) } id="link_caption" className="admin-input admin-caption-input post-data" value={this.state.text} data-content="text" placeholder="write some text"></textarea>
							<input onChange={ this.handleChange.bind(this) } type="text" className="admin-input admin-text-input post-data" value={this.state.url} data-content="url" placeholder="URL" />
							<input onChange={ this.handleChange.bind(this) } type="text" className="admin-input admin-text-input post-data" value={this.state.button_text} data-content="button_text" placeholder="button text" />
						</div>
					}
					{this.state.post_type === 3 &&
						<div className="admin-input-container">
							<textarea onChange={ this.handleChange.bind(this) } className="admin-input admin-caption-input post-data" value={this.state.quote} data-content="quote" placeholder="quote text"></textarea>
							<input onChange={ this.handleChange.bind(this) } type="text" className="admin-input admin-text-input post-data" value={this.state.attr} data-content="attr" placeholder="source name" />
              <input onChange={ this.handleChange.bind(this) } type="text" className="admin-input admin-text-input post-data" value={this.state.image_url} data-content="image_url" placeholder="image url (optional)" />
              <input onChange={ this.handleChange.bind(this) } type="text" className="admin-input admin-text-input post-data" value={this.state.tooltip_text} data-content="tooltip_text" placeholder="tooltip text (optional)" />
						</div>
					}
          {this.state.post_type === 6 &&
						<div className="admin-input-container">
              <input style={{display: "none"}} type="text" className="admin-input admin-text-input post-data" value={this.state.color} data-content="color" />
              <input onChange={ this.handleChange.bind(this) } type="text" className="admin-input admin-text-input post-data" value={this.state.question} data-content="question" placeholder="title" />
							<textarea onChange={ this.handleChange.bind(this) } className="admin-input admin-caption-input post-data" value={this.state.answer} data-content="answer" placeholder="text"></textarea>
						</div>
					}
					{this.state.post_type === 7 &&
						<div className="admin-input-container">
							<input onChange={ this.handleChange.bind(this) } type="text" className="admin-input admin-text-input post-data" value={this.state.feed} data-content="feed" placeholder="feed ID" />
              <textarea onChange={ this.handleChange.bind(this) } className="admin-input admin-caption-input post-data" value={this.state.text} data-content="text" placeholder="text"></textarea>
						</div>
					}
					{this.state.post_type === 8 &&
						<div className="admin-input-container">
							<input onChange={ this.handleArticleChange.bind(this) } type="text" className="admin-input admin-text-input post-data article-url-input" value={this.state.article_url} data-content="article_url" placeholder="article URL" />
							<input onChange={ this.handleChange.bind(this) } type="text" className="admin-input admin-text-input post-data" value={this.state.article_title} data-content="article_title" placeholder="article title" />
              <input onChange={ this.handleChange.bind(this) } type="text" className="admin-input admin-text-input post-data" value={this.state.article_site} data-content="article_site" placeholder="article site" />
							<input onChange={ this.handleChange.bind(this) } type="text" className="admin-input admin-text-input post-data" value={this.state.article_img} data-content="article_img" placeholder="article image URL" />
							<input onChange={ this.handleChange.bind(this) } type="text" className="admin-input admin-text-input post-data" value={this.state.article_favicon} data-content="article_favicon" placeholder="article favicon URL" />
							<textarea onChange={ this.handleChange.bind(this) } className="admin-input admin-caption-input post-data" value={this.state.caption} data-content="caption" placeholder="caption"></textarea>
						</div>
					}
					{this.state.post_type === 4 &&
						<div className="admin-input-container">
              <input style={{display: "none"}} type="text" className="admin-input admin-text-input post-data" value={this.state.color} data-content="color" />
							<input onChange={ this.handleChange.bind(this) } type="text" className="admin-input admin-text-input post-data" value={this.state.tweet_html} data-content="tweet_html" placeholder="tweet html" />
							<input onChange={ this.handleChange.bind(this) } type="text" className="admin-input admin-text-input post-data" value={this.state.caption} data-content="caption" placeholder="caption" />
						</div>
					}
					{this.state.post_type === "other" &&
						<div className="admin-input-container">
							<textarea onChange={ this.handleChange.bind(this) } className="admin-input admin-caption-input post-data" value={this.state.text} data-content="text" placeholder="something"></textarea>
						</div>
					}
          {(this.state.post_type !== null) &&
            <div>
              <div className="update-preview-section">
  							<div className="draft-section-label update-preview-label">UPDATE PREVIEW</div>
  							<div className="update-preview-container">
  								<div className="update-preview">
                    {this.state.post_type === 0 &&
                      <div>
                        {this.state.text}
                      </div>
                    }
                    {this.state.post_type === 1 &&
                      <div>
                        <img className="update-preview-image" src={this.state.image_url} alt={this.state.caption} />
                        {this.state.caption}
                      </div>
                    }
                    {this.state.post_type === 5 &&
                      <div>
                        <iframe src={"https://www.youtube.com/embed/" + this.state.video_id} title="Embedded YouTube video" height="190" className="update-preview-video" alt="Embedded YouTube video" frameborder="0" allow="encrypted-media" allowfullscreen></iframe>
                        {this.state.caption}
                      </div>
                    }
                    {this.state.post_type === 2 &&
                      <div>
                        <div className="preview-link-text">
                          {this.state.text}
                        </div>
                        <a href={this.state.url} target="_blank" rel="noopener noreferrer">
                					<div className="update-link-button">
                            {this.state.button_text}
                            <span className="fas fa-chevron-right"></span>
                					</div>
                				</a>
                      </div>
                    }
                    {this.state.post_type === 3 &&
                      <div>
                        <div className="update-quote-text">
                          "{this.state.quote}"
                        </div>
                        <div className="update-quote-details">
													{this.state.image_url &&
														<img className="quote-profile-image" src={this.state.image_url} title={this.state.tooltip_text} />
													}
													<div className="update-quote-source quote-source-preview">
														{!this.state.image_url &&
															<i class="far fa-comment"></i>
														}
														{this.state.attr}
													</div>
                        </div>
                      </div>
                    }
                    {this.state.post_type === 4 &&
                      <div>
                        <Tweet tweetId={this.getTweetID(this.state.tweet_html)} options={{ width: '1000' }} />
												{this.state.caption}
                      </div>
                    }
                    {this.state.post_type === 6 &&
                      <div>
                				<div className="update-question-text">
                					{this.state.question}
                				</div>
                				<div className="update-answer-text">
                					{this.state.answer}
                				</div>
                			</div>
                    }
										{this.state.post_type === 7 &&
											<FeedUpdate is_preview={true} id={this.state.feed} text={this.state.text} />
										}
										{this.state.post_type === 8 &&
											<div>
												<ArticleUpdate caption={this.state.caption} is_preview={true} article_img={this.state.article_img} article_favicon={this.state.article_favicon} article_title={this.state.article_title} article_site={this.state.article_site} article_url={this.state.article_url} />
											</div>
										}
                    <img className="update-preview-footer" src={UpdateFooter} alt="Update footer" />
  								</div>
  							</div>
  						</div>
              <div className="draft-section-label update-source-label">SOURCE</div>
              <div className="source-input-container">
                <input type="text" value={this.state.source} onChange={this.handleChange.bind(this)} data-content="source" className="feed-input update-source-input feed-text-input" name="source" placeholder="source" />
              </div>
							{this.state.is_crosspost &&
								<div className="crosspost-container">
									<div className="draft-section-label update-crosspost-label">CROSSPOST ID</div>
		              <div className="source-input-container">
		                <input type="text" value={this.state.crosspost} onChange={this.handleChange.bind(this)} data-content="crosspost" className="feed-input update-crosspost-input feed-text-input" name="crosspost" placeholder="feed" />
		              </div>
								</div>
							}
							<div>
								<div className="draft-section-label update-time-label">TIME POSTED <span onClick={this.setTimePostedToNow.bind(this)}>(NOW)</span></div>
								<div className="source-input-container">
									<DatePicker
										className="feed-input update-time-input feed-text-input"
										selected={this.state.created_at}
										onChange={this.handleDateChange.bind(this)}
										showTimeSelect
										timeFormat="h:mm aa"
										timeIntervals={10}
										dateFormat="MMMM d, yyyy h:mm aa"
										timeCaption="time"
									/>
								</div>
							</div>
              <div className="radios-input-container">
                <div className="update-radios-container">
                  <div className="update-radio-container">
                    <div className="update-radio-button" data-importance="0" onClick={this.updateImportance.bind(this)} style={this.state.importance === 0 ? {backgroundColor: this.state.color} : null}></div>
                    <div className="update-radio-label">
                      NORMAL
                    </div>
                  </div>
                  <div className="update-radio-container">
                    <div className="update-radio-button" data-importance="1" onClick={this.updateImportance.bind(this)} style={this.state.importance === 1 ? {backgroundColor: this.state.color} : null}></div>
                    <div className="update-radio-label">
                      IMPORTANT
                    </div>
                  </div>
                </div>
              </div>
							<div className="checkbox-input-container">
                <div className="update-checkbox-container">
                  <div className="update-checkbox-container">
                    <div className="update-checkbox-button" onClick={this.toggleNotif.bind(this)} style={this.state.no_notif ? {backgroundColor: this.state.color} : null}></div>
                    <div className="update-radio-label">
                      NO NOTIF?
                    </div>
                  </div>
                </div>
              </div>
							<div className="checkbox-input-container">
                <div className="update-checkbox-container">
                  <div className="update-checkbox-container">
                    <div className="update-checkbox-button" onClick={this.toggleForceNotif.bind(this)} style={this.state.force_notif ? {backgroundColor: this.state.color} : null}></div>
                    <div className="update-radio-label">
                      FORCE NOTIF?
                    </div>
                  </div>
                </div>
              </div>
							<div className="checkbox-input-container faq-checkbox-container">
                <div className="update-checkbox-container">
                  <div className="update-checkbox-container">
                    <div className="update-checkbox-button" onClick={this.toggleFAQ.bind(this)} style={this.state.is_faq ? {backgroundColor: this.state.color} : null}></div>
                    <div className="update-radio-label">
                      FAQ POST?
                    </div>
                  </div>
                </div>
              </div>
							<div className="checkbox-input-container faq-crosspost-container">
                <div className="update-checkbox-container">
                  <div className="update-checkbox-container">
                    <div className="update-checkbox-button" onClick={this.toggleCrosspost.bind(this)} style={this.state.is_crosspost ? {backgroundColor: this.state.color} : null}></div>
                    <div className="update-radio-label">
                      CROSSPOST?
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
					{(this.state.post_type !== null) &&
            <div className="update-submit-container">
              {!this.state.show_confirm && this.state.draft_status !== constants.DRAFT_STATUS.POSTED &&
                <div className="admin-post-submit" onClick={() => this.showConfirm()}>
    							<span className="fas fa-check"></span>
    							SUBMIT FOR REVIEW
    						</div>
              }
              {!this.state.show_confirm && this.state.draft_status === constants.DRAFT_STATUS.POSTED &&
                <div className="admin-post-submit" onClick={() => this.showConfirm()}>
    							<span className="fas fa-check"></span>
    							SAVE CHANGES
    						</div>
              }
              {this.state.show_confirm &&
                <div className="admin-post-submit submit-confirm" onClick={() => this.publish()}>
    							<span className="fas fa-check"></span>
                  CONFIRM
    						</div>
              }
							{this.state.importance === 1 && !this.state.has_created_top_news_draft &&
								<div className="create-top-news-draft-button" onClick={this.createTopNewsDraft.bind(this)}>
    							<span className="fas fa-plus"></span>
    							CREATE TOP-NEWS DRAFT
    						</div>
							}
            </div>
					}
				</div>
				<div className="draft-right-container">
          {this.state.notes &&
            <div className="draft-right-item">
  						<div className="draft-section-label">
                NOTES
              </div>
  						<div className="draft-notes-container">
  							{this.state.notes}
  						</div>
  					</div>
          }
          {this.state.draft_source && this.state.draft_source.length === 19 &&
            <div className="draft-right-item">
  						<div className="draft-section-label">
                SOURCE
              </div>
  						<Tweet tweetId={this.state.draft_source} options={{ width: '400' }} />
  					</div>
          }
					{this.state.draft_source && this.state.draft_source.length > 19 &&
						<div className="draft-right-item">
  						<div className="draft-section-label">
                SOURCE
              </div>
							<a href={this.state.draft_source} target="_blank">
								<div className="source-link">
									<i class="fas fa-external-link"></i>
									{this.state.draft_source}
								</div>
							</a>
  					</div>
					}
					<div className="draft-right-item">
						<div className="draft-section-label latest-updates-label">
              LATEST UPDATES
            </div>
						<div className="sidebar-updates-container">
							{this.state.updates}
						</div>
					</div>
				</div>
			</div>
		)
	}

}

class ReassignDraftModal extends Component {

	constructor(props) {
	  super(props);
	  this.state = {};
	}

  handleChange(e) {
    this.setState({
      [$(e.target).attr('data-content')]: e.target.value
    })
  }

	handleSubmit() {
    var t = this;
    let data = {
      user: this.state.assigned
    }
    fetch(constants.BASE_API_URL + '/v1/drafts/' + this.props.id + '/assign', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.props.onReassign();
        $('.modal-container').hide();
    		$('.darken').hide();
      })
      .catch(error => console.error('Error:', error));
	}

	render() {
		return (
			<div className="modal-container reassign-draft-modal">
				<div className="modal-top-container">
					<p className="modal-title">reassign draft</p>
				</div>
				<div className="submit-input-container">
          <select onChange={this.handleChange.bind(this)} defualtValue="assign" data-content="assigned">
            <option value="assign" disabled>assign</option>
            <option value="5c1d6ca4ca64a600047447c0">queue</option>
            {this.props.admins}
          </select>
					<div className="fas fa-sort-down"></div>
				</div>
				<div className="modal-submit-button" onClick={() => this.handleSubmit()}>
					<span className="fas fa-check"></span>
					SUBMIT
				</div>
			</div>
		)
	}
}

class SwitchDraftFeedModal extends Component {

	constructor(props) {
	  super(props);
		let feed_options = props.feeds.map(feed => (
			<option value={feed.value}>{feed.name}</option>
		))
		this.state = {
			feed_options: feed_options
		};
	}

  handleChange(e) {
    this.setState({
      [$(e.target).attr('data-content')]: e.target.value
    })
  }

	handleSubmit() {
    var t = this;
    let data = {
      feed: this.state.feed
    }
    fetch(constants.BASE_API_URL + '/v1/drafts/' + this.props.id + '/switch', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
				let feed = this.props.feeds.filter(feed => feed.value === this.state.feed)[0];
        t.props.onSwitch(feed.name, feed.color);
        $('.modal-container').hide();
    		$('.darken').hide();
      })
      .catch(error => console.error('Error:', error));
	}

	render() {
		let modal_class = "modal-container switch-draft-modal-" + this.props.id;
		return (
			<div className={modal_class}>
				<div className="modal-top-container">
					<p className="modal-title">switch feed</p>
				</div>
				<div className="submit-input-container">
          <select onChange={this.handleChange.bind(this)} defaultValue="feed" data-content="feed">
            <option value="feed" disabled>select</option>
            {this.state.feed_options}
          </select>
					<div className="fas fa-sort-down"></div>
				</div>
				<div className="modal-submit-button" onClick={() => this.handleSubmit()}>
					<span className="fas fa-check"></span>
					SUBMIT
				</div>
			</div>
		)
	}
}

class Users extends Component {

	constructor(props) {
		super(props)
		this.state = {
			show_admins: false,
			show_users: true
		}
		this.fetchLeaderboard();
	}

	fetchLeaderboard() {
		fetch(constants.BASE_API_URL + '/v1/curation/leaderboard', {
      credentials: 'include'
    }).then(res => res.json())
			.then(data => {
				let leaderboard = data.users.map((user) => {
					return (
						<div className="user-leaderboard-item">{user.login_id} <span className="leaderboard-score">{user.num_posts}</span></div>
					)
				})
				this.setState({
          leaderboard: leaderboard
        });
			})
	}

  showAddAdminModal() {
		$('.darken').show(0, function() {
			$('.add-admin-modal').show();
		});
	}

  showPromoteAdminModal() {
		$('.darken').show(0, function() {
			$('.promote-admin-modal').show();
		});
	}

	showAdmins() {
		this.setState({
			show_admins: true
		})
	}

	hideAdmins() {
		this.setState({
			show_admins: false
		})
	}

	showUsers() {
		this.setState({
			show_users: true
		})
	}

	hideUsers() {
		this.setState({
			show_users: false
		})
	}

	render() {
		return (
      <div className="users-container">
        <div className="users-top-container">
          <div className="manage-admin-button create-admin-button" onClick={this.showAddAdminModal}>
            <i className="fas fa-plus"></i>
            create
          </div>
          <div className="manage-admin-button promote-admin-button" onClick={this.showPromoteAdminModal}>
            <i className="fas fa-plus"></i>
            promote
          </div>
          <input className="users-search" type="text" placeholder="search..." />
        </div>
				{!this.state.show_admins &&
					<div className="show-section-button users-section-button" onClick={this.showAdmins.bind(this)}>
						show {this.props.admins && this.props.admins.length} admins
						<i className="fas fa-chevron-down"></i>
					</div>
				}
				{this.state.show_admins &&
					<div className="show-section-button users-section-button" onClick={this.hideAdmins.bind(this)}>
						hide {this.props.admins && this.props.admins.length} admins
						<i className="fas fa-chevron-up"></i>
					</div>
				}
        {this.state.show_admins &&
					<div className="users-list-container">
						{this.props.admins}
					</div>
        }
				{!this.state.show_users &&
					<div className="show-section-button users-section-button" onClick={this.showUsers.bind(this)}>
						show {this.props.total_users} users
						<i className="fas fa-chevron-down"></i>
					</div>
				}
				{this.state.show_users &&
					<div className="show-section-button users-section-button" onClick={this.hideUsers.bind(this)}>
						hide {this.props.total_users} users
						<i className="fas fa-chevron-up"></i>
					</div>
				}
        {this.state.show_users &&
					<div className="users-list-container">
	          {this.props.users}
					</div>
        }
        <div className="users-right-container">
					<div className="draft-right-item">
						<div className="draft-section-label">CURATORS</div>
						{this.state.leaderboard}
					</div>
				</div>
        <AddAdminModal onSubmit={this.props.onSubmit} />
        <PromoteAdminModal onSubmit={this.props.onSubmit} />
			</div>
		)
	}

}

class Data extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: true
		}
		this.fetchCount();
		this.fetchNotifs();
		this.fetchFollows();
		this.fetchFeeds();
		this.fetchLeaderboard();
	}

	fetchLeaderboard() {
		fetch(constants.BASE_API_URL + '/v1/curation/leaderboard', {
      credentials: 'include'
    }).then(res => res.json())
			.then(data => {
				let leaderboard = data.users.map((user) => {
					return (
						<div className="user-leaderboard-item">{user.login_id} <span className="leaderboard-score">{user.num_posts}</span></div>
					)
				})
				this.setState({
          leaderboard: leaderboard
        });
			})
	}

	fetchCount() {
		fetch(constants.BASE_API_URL + '/v1/data/active-count', {
      credentials: 'include'
    }).then(res => res.json())
			.then(data => {
				this.setState({
          day_active: data.day_active,
					two_day_active: data.two_day_active,
					week_active: data.week_active,
					average_age: data.average_age
        });
			})
	}

	fetchNotifs() {
		fetch(constants.BASE_API_URL + '/v1/data/num-notifs', {
      credentials: 'include'
    }).then(res => res.json())
			.then(data => {
				this.setState({
          num_notifs: data.num_notifs
        });
			})
	}

	fetchFollows() {
		fetch(constants.BASE_API_URL + '/v1/data/follows-count', {
      credentials: 'include'
    }).then(res => res.json())
			.then(data => {
				let follows = Object.keys(data.follows_map).sort(function(a,b){return data.follows_map[b] - data.follows_map[a]});
				var follows_array = []
				for (var i = 0; i < 15; i++) {
					if (follows[i] !== "tutorial") {
						follows_array.push((
							<div>
								#{follows[i]}: <span className="data-bold">+{data.follows_map[follows[i]]}</span> new followers
							</div>
						))
					}
				}
				this.setState({
          day_follows: data.day_follows,
					week_follows: data.week_follows,
					last_week_follows: data.last_week_follows,
					follows_array: follows_array
        });
			})
	}

	fetchFeeds() {
		fetch(constants.BASE_API_URL + '/v1/data/active-feeds', {
      credentials: 'include'
    }).then(res => res.json())
			.then(data => {
				let feeds = Object.keys(data.feeds_map).sort(function(a,b){return data.feeds_map[b] - data.feeds_map[a]});
				var feeds_array = []
				for (var i = 0; i < feeds.length; i++) {
					feeds_array.push((
						<div>
							<span className="data-bold">{Math.ceil((data.feeds_map[feeds[i]] / data.num_active) * 100) + "%"}</span>
							{" of active users are following " + feeds[i]}
						</div>
					))
				}
				let local = Object.keys(data.local_map).sort(function(a,b){return data.local_map[b] - data.local_map[a]});
				var local_array = []
				for (var l = 0; l < local.length; l++) {
					local_array.push((
						<div>
							<span className="data-bold">{(data.local_map[local[l]] * 100).toFixed(1) + "%"}</span>
							{" of " + local[l] + " users are active"}
						</div>
					))
				}
				this.setState({
          avg_follows: data.average_follows.toFixed(1),
					feeds_map: feeds_array,
					local_map: local_array,
					loading: false
        });
			})
	}

	render() {
		return (
      <div className="data-container">
				{this.state.loading &&
					<div className="manage-feeds-loading-container">
						<HashLoader
							className="loading-spinner"
							sizeUnit={"px"}
							size={150}
							color={'#E3E3E3'}
							loading={this.state.loading}
						/>
					</div>
				}
				{!this.state.loading &&
					<div>
						<div className="data-top-container">
		          <div className="manage-admin-button promote-admin-button">
		            <i className="fas fa-plus"></i>
		            test button
		          </div>
		          <input className="data-search" type="text" placeholder="search..." />
		        </div>
						<div className="data-list-container">
							Active users in last 24 hours: <span className="data-bold">{this.state.day_active}</span><br/>
							Active users in last 48 hours: <span className="data-bold">{this.state.two_day_active}</span><br/>
							Active users in last 7 days: <span className="data-bold">{this.state.week_active}</span><br/><br/>
							Notifications in last 24 hours: <span className="data-bold">{this.state.num_notifs}</span><br/>
							Average active user account age: <span className="data-bold">{(this.state.average_age/2629800000).toFixed(1)} months</span><br/>
							Average number of feeds followed: <span className="data-bold">{this.state.avg_follows}</span><br/><br/>
							New follows in last 24 hours: <span className="data-bold">{this.state.day_follows}</span><br/>
							New follows in last 7 days: <span className="data-bold">{this.state.week_follows}</span><br/>
							Week over week change: <span className="data-bold">{this.state.week_follows > this.state.last_week_follows ? "+" : "-"}{((this.state.week_follows/this.state.last_week_follows - 1) * 100).toFixed(1)}%</span>
						</div>
						<div className="data-list-container">
							{this.state.follows_array}
						</div>
						<div className="data-list-container">
							{this.state.feeds_map}
						</div>
						<div className="data-list-container">
							{this.state.local_map}
						</div>
					</div>
				}
        <div className="data-right-container">
					<div className="draft-right-item">
						<div className="draft-section-label">CURATORS</div>
						{this.state.leaderboard}
					</div>
				</div>
			</div>
		)
	}

}

class AddAdminModal extends Component {

	constructor(props) {
	  super(props);
	  this.state = {};
	}

  handleChange(e) {
    this.setState({
      [$(e.target).attr('data-content')]: e.target.value
    })
  }

	handleSubmit() {
    var t = this;
    var data = {
      username: this.state.username,
      password: this.state.password,
      role: this.state.role
    }
    fetch(constants.BASE_API_URL + '/v1/auth/publish-curator', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        $('.modal-container').hide();
    		$('.darken').hide(); // FIX THIS, NO JQUERY HERE
        t.props.onSubmit();
      })
      .catch(error => console.error('Error:', error));
	}

	render() {
		return (
			<div className="modal-container add-admin-modal">
				<div className="modal-top-container">
					<p className="modal-title">create admin</p>
				</div>
				<div className="submit-input-container">
					<input type="text" onChange={this.handleChange.bind(this)} data-content="username" name="username" placeholder="username" />
          <input type="text" onChange={this.handleChange.bind(this)} data-content="password" name="password" placeholder="password" />
					<select onChange={this.handleChange.bind(this)} defaultValue="role" data-content="role">
						<option value="role" disabled>role</option>
					  <option value="0">user</option>
					  <option value="1">admin</option>
					</select>
					<div className="fas fa-sort-down"></div>
				</div>
				<div className="modal-submit-button" onClick={() => this.handleSubmit()}>
					<span className="fas fa-check"></span>
					SUBMIT
				</div>
			</div>
		)
	}
}

class PromoteAdminModal extends Component {

	constructor(props) {
	  super(props);
	  this.state = {};
	}

  handleChange(e) {
    this.setState({
      [$(e.target).attr('data-content')]: e.target.value
    })
  }

  handleSubmit() {
    var t = this;
    var data = {
      username: this.state.username,
      role: this.state.role
    }
    fetch(constants.BASE_API_URL + '/v1/user/promote', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        $('.modal-container').hide();
    		$('.darken').hide(); // FIX THIS, NO JQUERY HERE
        t.props.onSubmit();
      })
      .catch(error => console.error('Error:', error));
	}

	render() {
		return (
			<div className="modal-container promote-admin-modal">
				<div className="modal-top-container">
					<p className="modal-title">promote admin</p>
				</div>
				<div className="submit-input-container">
					<input type="text" onChange={this.handleChange.bind(this)} data-content="username" name="username" placeholder="username" />
					<select onChange={this.handleChange.bind(this)} defaultValue="role" data-content="role">
						<option value="role" disabled>role</option>
					  <option value="0">user</option>
					  <option value="1">admin</option>
					</select>
					<div className="fas fa-sort-down"></div>
				</div>
				<div className="modal-submit-button" onClick={() => this.handleSubmit()}>
					<span className="fas fa-check"></span>
					SUBMIT
				</div>
			</div>
		)
	}
}

class User extends Component {

	constructor(props) {
		super(props)
		var auth_type, name, email;
		if (props.login_flow === constants.AUTH_FLOWS.CURATOR) {
			auth_type = "curator"
		} else if (props.login_flow === constants.AUTH_FLOWS.FACEBOOK) {
			auth_type = "facebook"
		} else if (props.login_flow === constants.AUTH_FLOWS.GOOGLE) {
			auth_type = "google"
		} else if (props.login_flow === constants.AUTH_FLOWS.PHONE) {
			auth_type = "phone"
		} else if (props.login_flow === constants.AUTH_FLOWS.APPLE) {
			auth_type = "apple"
		}
		if (props.login_flow === constants.AUTH_FLOWS.GOOGLE && props.profile) {
			name = props.profile.name
			email = props.profile.email
		} else if (props.login_flow === constants.AUTH_FLOWS.FACEBOOK && props.profile) {
			name = props.profile.displayName
			email = props.profile.emails[0].value
		}
		this.state = {
			confirm_delete: false,
			auth_type: auth_type,
			name: name,
			email: email
		}
	}

  showManageUserModal() {
    let t = this;
		$('.darken').show(0, function() {
			$('#' + t.props.id + ' .manage-user-modal').show();
		});
	}

	confirmDelete() {
		this.setState({
			confirm_delete: true
		})
	}

	delete() {
		let t = this;
		let data = {
			user_id: this.props.id
		}
		fetch(constants.BASE_API_URL + '/v1/users/delete', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
				alert("User " + this.props.id + " deleted :(")
        t.props.onSubmit();
      })
      .catch(error => console.error('Error:', error));
	}

  render() {
    return (
      <div className="user-container" id={this.props.id}>
				<div className="user-left-container">
          <span className="user-name">
						{this.state.name &&
							<span>
								{this.state.name}&nbsp;
							</span>
						}
						{this.state.email &&
							<a href={"mailto:" + this.state.email}>
								/ {this.state.email}
							</a>
						}
						{!this.state.name && !this.state.email &&
							<span>
								{this.props.username}
							</span>
						}
          </span>
          <div className="user-role">
            {this.state.auth_type}
          </div>
				</div>
				<div className="user-right-container">
					{!this.state.confirm_delete &&
						<div className="manage-user-button delete-user-button" onClick={this.confirmDelete.bind(this)}>
							delete
						</div>
					}
					{this.state.confirm_delete &&
						<div className="manage-user-button delete-user-button" onClick={this.delete.bind(this)}>
							are you sure?
						</div>
					}
					<div className="manage-user-button" onClick={this.showManageUserModal.bind(this)}>
						manage
					</div>
				</div>
				<div className="user-details-container">
					{this.props.role === constants.USER_ROLES.NORMAL && !this.props.created_at &&
						<div>
							created <span className="bold-text">pre-beta</span>
						</div>
					}
					{this.props.role === constants.USER_ROLES.NORMAL && this.props.created_at &&
						<div>
							created <span className="bold-text"><TimeAgo date={this.props.created_at} /></span>
						</div>
					}
					{this.props.role === constants.USER_ROLES.CURATOR &&
						<div>
							created <span className="bold-text"><TimeAgo date={this.props.created_at} /></span>
						</div>
					}
				</div>
        <ManageUserModal onSubmit={this.props.onSubmit} login_id={this.props.login_id} id={this.props.id} role={this.props.role} />
			</div>
    )
  }

}

class ManageUserModal extends Component {

	constructor(props) {
	  super(props);
	  this.state = {
      login_id: this.props.login_id,
      role: this.props.role
    };
	}

  handleChange(e) {
    this.setState({
      [$(e.target).attr('data-content')]: e.target.value
    })
  }

	handleSubmit() {
    var t = this;
    var data = {
      login_id: this.state.login_id,
      role: this.state.role
    }
    fetch(constants.BASE_API_URL + '/v1/user/promote', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        $('.modal-container').hide();
    		$('.darken').hide(); // FIX THIS, NO JQUERY HERE
        t.props.onSubmit();
      })
      .catch(error => console.error('Error:', error));
	}

	render() {
		return (
			<div className="modal-container manage-user-modal">
				<div className="modal-top-container">
					<p className="modal-title">update user</p>
				</div>
				<div className="submit-input-container">
          <input type="text" onChange={this.handleChange.bind(this)} data-content="login_id" value={this.state.login_id} name="login_id" placeholder="login_id" />
					<select onChange={this.handleChange.bind(this)} data-content="role" value={this.state.role}>
						<option value="role" disabled>role</option>
					  <option value="0">user</option>
					  <option value="1">admin</option>
					</select>
					<div className="fas fa-sort-down"></div>
				</div>
				<div className="modal-submit-button" onClick={() => this.handleSubmit()}>
					<span className="fas fa-check"></span>
					SUBMIT
				</div>
			</div>
		)
	}
}

class Feeds extends Component {

  constructor(props) {
		super(props);
		this.state = {
			show_archived: false,
			show_hidden: false,
			loading: true
		};
	}

  componentDidMount() {
		this.fetchFeeds();
	}

	showArchived() {
		this.setState({
			show_archived: true
		})
	}

	hideArchived() {
		this.setState({
			show_archived: false
		})
	}

	showHidden() {
		this.setState({
			show_hidden: true
		})
	}

	hideHidden() {
		this.setState({
			show_hidden: false
		})
	}

  fetchFeeds() {
    fetch(constants.BASE_API_URL + '/v1/feeds/all')
			.then(res => res.json())
			.then(data => {
				let feeds_copy = JSON.parse(JSON.stringify(data.feeds)).sort((a, b) => b.num_followers - a.num_followers);
				let feeds = data.feeds.map((feed) => {
          return (
            <ManageFeed key={feed._id} emoji={feed.emoji} coverage_type={feed.coverage_type} status={feed.status} is_header={false} num_updates={feed.num_updates} onUpdateCreate={this.props.onUpdateCreate} name={feed.name} logged_in_user={this.props.logged_in_user} user_role={this.props.user_role} updated_at={feed.updated_at} description={feed.description} color={feed.color} num_followers={feed.num_followers} id={feed._id} visible={feed.visible} is_streaming={feed.tweet_stream} refreshFeeds={this.fetchFeeds.bind(this)} />
          )
				})
				let leaderboard = feeds_copy.map((feed) => {
					if (feed.name !== "tutorial" && feed.visible && feed.status === constants.FEED_STATUS.ACTIVE || feed.status === constants.FEED_STATUS.BREAKING || feed.status === constants.FEED_STATUS.FEATURED) {
						return (
							<Link to={"/feeds/" + feed._id}>
								<div className="user-leaderboard-item">
									<div className="feed-slug" style={{backgroundColor: feed.color}}>
										<div className="fas fa-hashtag"></div>
										{feed.name}
									</div>
									<span className="feed-emoji">{feed.emoji}</span>
									<span className="leaderboard-score">{feed.num_followers}</span>
								</div>
							</Link>
						)
					}
				})
        this.setState({
          feeds: feeds,
          searchable_feeds: feeds,
					leaderboard: leaderboard,
					loading: false
        });
			})
  }

  filterFeeds(filter) {
    let filteredFeeds = this.state.searchable_feeds
    filteredFeeds = filteredFeeds.filter((feed) => {
      if (feed) {
        let feedSearchString = feed.props.name.toLowerCase()
  			feedSearchString = feedSearchString.replace("-", " ")
        return feedSearchString.indexOf(filter.target.value.replace("-", " ").toLowerCase()) !== -1
      }
    })
    this.setState({
      feeds: filteredFeeds
    })
  }

	render() {
		return (
      <div className="feeds-container">
        <div className="manage-feeds-top-container">
					{(this.props.user_role === constants.USER_ROLES.GOD || this.props.user_role === constants.USER_ROLES.CURATOR) &&
						<Link to="/feed/new">
	            <div className="manage-feeds-top-button add-feed-button">
	              <i className="fas fa-plus"></i>
	              create feed
	            </div>
	          </Link>
					}
          <input className="manage-search" type="text" placeholder="search..." onChange={this.filterFeeds.bind(this)} spellcheck="false" />
        </div>
				<div className="batch-separator"></div>
				{this.state.loading &&
					<div className="manage-feeds-loading-container">
						<HashLoader
							className="loading-spinner"
							sizeUnit={"px"}
							size={150}
							color={'#E3E3E3'}
							loading={this.state.loading}
						/>
					</div>
				}
        {this.state.feeds && this.state.feeds.filter(feed => feed.props.visible && feed.props.status === constants.FEED_STATUS.BREAKING).length > 0 &&
          <div className="manage-feeds-section">
            <div className="manage-feeds-label">
              breaking feeds
            </div>
    			  <div className="manage-feeds-container">
              {this.state.feeds.filter(feed => feed.props.visible && feed.props.status === constants.FEED_STATUS.BREAKING)}
    				</div>
          </div>
        }
        {this.state.feeds && this.state.feeds.filter(feed => feed.props.visible && feed.props.status === constants.FEED_STATUS.FEATURED).length > 0 &&
          <div className="manage-feeds-section">
            <div className="manage-feeds-label">
              featured feeds
            </div>
    			  <div className="manage-feeds-container">
              {this.state.feeds.filter(feed => feed.props.visible && feed.props.status === constants.FEED_STATUS.FEATURED)}
    				</div>
          </div>
        }
        {this.state.feeds && this.state.feeds.filter(feed => feed.props.visible && feed.props.coverage_type === constants.COVERAGE_TYPES.LIVE && feed.props.status === constants.FEED_STATUS.ACTIVE).length > 0 &&
          <div className="manage-feeds-section">
            <div className="manage-feeds-label">
              live feeds
            </div>
    			  <div className="manage-feeds-container">
              {this.state.feeds.filter(feed => feed.props.visible && feed.props.coverage_type === constants.COVERAGE_TYPES.LIVE && feed.props.status === constants.FEED_STATUS.ACTIVE)}
    				</div>
          </div>
        }
				{this.state.feeds && this.state.feeds.filter(feed => feed.props.visible && feed.props.coverage_type === constants.COVERAGE_TYPES.TWICE_DAILY && feed.props.status === constants.FEED_STATUS.ACTIVE).length > 0 &&
          <div className="manage-feeds-section">
            <div className="manage-feeds-label">
              twice-daily feeds
            </div>
    			  <div className="manage-feeds-container">
              {this.state.feeds.filter(feed => feed.props.visible && feed.props.coverage_type === constants.COVERAGE_TYPES.TWICE_DAILY && feed.props.status === constants.FEED_STATUS.ACTIVE)}
    				</div>
          </div>
        }
				{this.state.feeds && this.state.feeds.filter(feed => feed.props.visible && feed.props.coverage_type === constants.COVERAGE_TYPES.DAILY && feed.props.status === constants.FEED_STATUS.ACTIVE).length > 0 &&
          <div className="manage-feeds-section">
            <div className="manage-feeds-label">
              daily feeds
            </div>
    			  <div className="manage-feeds-container">
              {this.state.feeds.filter(feed => feed.props.visible && feed.props.coverage_type === constants.COVERAGE_TYPES.DAILY && feed.props.status === constants.FEED_STATUS.ACTIVE)}
    				</div>
          </div>
        }
				{this.state.feeds && this.state.feeds.filter(feed => feed.props.visible && feed.props.status === constants.FEED_STATUS.UNPUBLISHED).length > 0 &&
          <div className="manage-feeds-section">
            <div className="manage-feeds-label">
              unpublished feeds
            </div>
    			  <div className="manage-feeds-container unpublished-feeds-container">
              {this.state.feeds.filter(feed => feed.props.visible && feed.props.status === constants.FEED_STATUS.UNPUBLISHED)}
    				</div>
          </div>
        }
				{!this.state.loading && !this.state.show_archived &&
					<div className="show-section-button" onClick={this.showArchived.bind(this)}>
						show archived feeds
						<i className="fas fa-chevron-down"></i>
					</div>
				}
				{!this.state.loading && this.state.show_archived &&
					<div className="show-section-button" onClick={this.hideArchived.bind(this)}>
						hide archived feeds
						<i className="fas fa-chevron-up"></i>
					</div>
				}
        {!this.state.loading && this.state.show_archived && this.state.feeds && this.state.feeds.filter(feed => feed.props.visible && feed.props.status === constants.FEED_STATUS.ARCHIVED).length > 0 &&
          <div className="manage-feeds-section">
            <div className="manage-feeds-label">
              archived feeds
            </div>
    			  <div className="manage-feeds-container archived-feeds-container">
              {this.state.feeds.filter(feed => feed.props.visible && feed.props.status === constants.FEED_STATUS.ARCHIVED)}
    				</div>
          </div>
        }
				{!this.state.loading && !this.state.show_hidden &&
					<div className="show-section-button" onClick={this.showHidden.bind(this)}>
						show hidden feeds
						<i className="fas fa-chevron-down"></i>
					</div>
				}
				{!this.state.loading && this.state.show_hidden &&
					<div className="show-section-button" onClick={this.hideHidden.bind(this)}>
						hide hidden feeds
						<i className="fas fa-chevron-up"></i>
					</div>
				}
        {!this.state.loading && this.state.show_hidden && this.state.feeds && this.state.feeds.filter(feed => !feed.props.visible).length > 0 &&
          <div className="manage-feeds-section">
            <div className="manage-feeds-label">
              hidden feeds
            </div>
    			  <div className="manage-feeds-container archived-feeds-container">
              {this.state.feeds.filter(feed => !feed.props.visible)}
    				</div>
          </div>
        }
				<div className="feeds-right-container mobile-leaderboard-container">
					<div className="draft-right-item" style={{marginTop: 25}}>
						<div className="draft-section-label" style={{paddingBottom: 10}}>TOP FEEDS</div>
						{this.state.leaderboard}
					</div>
				</div>
			</div>
		)
	}

}

class Batch extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	confirmPublishBatch() {
		this.setState({
			confirm_publish_batch: true
		})
	}

	publishBatch() {
		fetch(constants.BASE_API_URL + '/v1/updates/publish-batch', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        this.setState({
          confirm_publish_batch: false
        })
				this.props.onPublish();
      })
      .catch(error => console.error('Error:', error));
	}

	render() {
		// USE MOMENT HERE LIKE BACKEND GETCOVERAGESTATUS
		let now = new Date();
		now.setMinutes(0);
		now.setMilliseconds(0);
		let current_hour = now.getHours();
		if (current_hour > 9 && current_hour < 16) {
			now.setHours(16);
		} else if (current_hour >= 0 && current_hour < 9) {
			now.setHours(9);
		} else {
			now.setDate(now.getDate() + 1)
			now.setHours(9);
		}
		return (
			<div className="batch-container">
				<div className="batch-top-container">
					{/*<div className="countdown-container">
						<Countdown date={now} renderer={this.renderer} daysInHours={true} />
					</div>*/}
					{!this.state.confirm_publish_batch &&
						<div className="manage-feeds-top-button publish-batch-button" onClick={this.confirmPublishBatch.bind(this)}>
							<i className="fas fa-clock"></i>
							publish all
						</div>
					}
					{this.state.confirm_publish_batch &&
						<div className="manage-feeds-top-button publish-batch-button confirm-warning" onClick={this.publishBatch.bind(this)}>
							<i className="fas fa-exclamation-triangle"></i>
							are you sure?
						</div>
					}
				</div>
				<div className="batch-separator"></div>
				<div className="batch-updates-container">
					{this.props.updates}
				</div>
			</div>
		)
	}

}

class Overnight extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	confirmPublishOvernight() {
		this.setState({
			confirm_publish_overnight: true
		})
	}

	publishOvernight() {
		fetch(constants.BASE_API_URL + '/v1/updates/publish-overnight', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        this.setState({
          confirm_publish_overnight: false
        })
				this.props.onPublish();
      })
      .catch(error => console.error('Error:', error));
	}

	render() {
		// USE MOMENT HERE LIKE BACKEND GETCOVERAGESTATUS
		// FIX THIS NOW IT'S TO NEXT BATCH TIME
		let now = new Date();
		now.setMinutes(0);
		now.setMilliseconds(0);
		let current_hour = now.getHours();
		if (current_hour > 9 && current_hour < 16) {
			now.setHours(16);
		} else if (current_hour >= 0 && current_hour < 9) {
			now.setHours(9);
		} else {
			now.setDate(now.getDate() + 1)
			now.setHours(9);
		}
		return (
			<div className="batch-container">
				<div className="batch-top-container">
					<div className="countdown-container">
						<Countdown date={now} renderer={this.renderer} daysInHours={true} />
					</div>
					{!this.state.confirm_publish_overnight &&
						<div className="manage-feeds-top-button publish-batch-button" onClick={this.confirmPublishOvernight.bind(this)}>
							<i class="fas fa-moon"></i>
							publish overnight
						</div>
					}
					{this.state.confirm_publish_overnight &&
						<div className="manage-feeds-top-button publish-batch-button confirm-warning" onClick={this.publishOvernight.bind(this)}>
							<i className="fas fa-exclamation-triangle"></i>
							are you sure?
						</div>
					}
				</div>
				<div className="batch-separator"></div>
				<div className="batch-updates-container">
					{this.props.updates}
				</div>
			</div>
		)
	}

}

class FeedPublishedBanner extends Component {

  render() {
    return (
      <div className="feed-published-banner">
        <i className="far fa-clock"></i>
        Coverage began on {new Date(this.props.published_at).toString()}
      </div>
    )
  }

}

class FeedArchivedBanner extends Component {

  render() {
    return (
      <div className="feed-archived-banner">
        <i className="fas fa-lock"></i>
        Feed archived on {new Date(this.props.archived_at).toString()}
      </div>
    )
  }

}

class UnpublishedUpdatesBanner extends Component {

  render() {
    return (
      <div className="unpublished-updates-banner">
        <i className="fas fa-arrow-up"></i>
        Updates above are unpublished
      </div>
    )
  }

}

class UpdateList extends Component {

	constructor(props) {
	  super(props);
		this.state = {
      id: props.id,
			updates: null,
			background_updates: null,
			show_background: false,
			loading: true,
			fetch_all: false,
			coverage_type: null,
			confirm_publish_batch: false
		};
		this.fetchUpdates(props.id, false);
		window.scrollTo(0, 0);
	}

	toggleShowBackground() {
		this.setState({
			show_background: !this.state.show_background
		})
	}

	componentWillReceiveProps(props) {
    if (props.id !== this.state.id) {
      this.setState({
				id: props.id,
				updates: null,
				background_updates: null,
				show_background: false
			});
      this.fetchUpdates(props.id, false);
			window.scrollTo(0, 0);
    }
	}

	fetchAllUpdates() {
		this.setState({
			loading: true
		})
		this.fetchUpdates(this.props.id, true);
	}

  fetchUpdates(id, fetch_all) {
		var fetch_url;
		if (fetch_all) {
			fetch_url = constants.BASE_API_URL + '/v1/feeds/' + id + '/updates/all'
		} else {
			fetch_url = constants.BASE_API_URL + '/v1/feeds/' + id + '/updates/all?page=1'
		}
		this.setState({
			fetch_all: fetch_all
		})
		fetch(fetch_url, {
			credentials: "include"
		}).then(res => res.json())
			.then(data => {
				if (data.updates) {
          var updates = [];
          var start_banner_added = false;
					var unpublished_banner_added = false;
					let feed_has_unpublished_updates = data.updates.filter(update => update.status === constants.UPDATE_STATUS.UNPUBLISHED).length > 0;
					if (data.feed.status === constants.FEED_STATUS.ARCHIVED) {
						let archived_banner = (
							<FeedArchivedBanner archived_at={data.feed.archived_at} />
						)
						updates.push(archived_banner)
					}
          for (var i = 0; i < data.updates.length; i++) {
            var update = data.updates[i];
            if (data.feed.status !== constants.FEED_STATUS.UNPUBLISHED && !start_banner_added && new Date(update.created_at) < new Date(data.feed.published_at)) {
              let published_banner = (
                <FeedPublishedBanner published_at={data.feed.published_at} />
              )
              updates.push(published_banner)
              start_banner_added = true
            }
						if (feed_has_unpublished_updates && update.status === constants.UPDATE_STATUS.PUBLISHED && !unpublished_banner_added) {
              let unpublished_banner = (
                <UnpublishedUpdatesBanner />
              )
              updates.push(unpublished_banner)
              unpublished_banner_added = true
            }
            let update_component = (
              <Update notes={update.notes} user_role={this.props.user_role} onApprove={() => this.fetchUpdates(id, false)} key={update._id} crosspost={update.crosspost} status={update.status} tooltips={update.tooltips} importance={update.importance} show_buttons={true} refreshReviewing={this.props.refreshReviewing} logged_in_user={this.props.logged_in_user} author={update.author} type={update.type} color={data.feed.color} feed_id={data.feed._id} content={update.content} num_reacts={update.num_reacts} id={update._id} draft_id={update.draft_id} source={update.source} timestamp={update.created_at} visible={update.visible} reacted={false} refreshUpdates={() => this.fetchUpdates(id, fetch_all)} />
            )
            updates.push(update_component);
          }
          if (data.feed.status !== constants.FEED_STATUS.UNPUBLISHED && !start_banner_added) {
            let published_banner = (
              <FeedPublishedBanner published_at={data.feed.published_at} />
            )
            updates.push(published_banner)
          }
          let manage_feed = (
            <ManageFeed key={data.feed._id} emoji={data.feed.emoji} coverage_type={data.feed.coverage_type} status={data.feed.status} is_header={true} name={data.feed.name} logged_in_user={this.props.logged_in_user} user_role={this.props.user_role} updated_at={data.feed.updated_at} description={data.feed.description} color={data.feed.color} num_updates={data.feed.num_updates} num_followers={data.feed.num_followers} id={data.feed._id} visible={data.feed.visible} is_streaming={data.feed.tweet_stream} />
          )
					this.setState({
						feed: data.feed,
						coverage_type: data.feed.coverage_type
					})
					if (fetch_all) {
						this.setState({
							updates: updates,
	            manage_feed: manage_feed
						});
					} else {
						fetch(constants.BASE_API_URL + '/v1/feeds/' + id + '/updates/background', {
							credentials: "include"
						}).then(res => res.json())
							.then(data => {
								var background_updates = [];
			          for (var i = 0; i < data.updates.length; i++) {
			            var update = data.updates[i];
			            let update_component = (
			              <Update user_role={this.props.user_role} onApprove={() => this.fetchUpdates(id, false)} key={update._id} crosspost={update.crosspost} status={update.status} tooltips={update.tooltips} importance={update.importance} show_buttons={true} refreshReviewing={this.props.refreshReviewing} logged_in_user={this.props.logged_in_user} type={update.type} color={data.feed.color} feed_id={data.feed._id} content={update.content} num_reacts={update.num_reacts} id={update._id} draft_id={update.draft_id} source={update.source} timestamp={update.created_at} visible={update.visible} reacted={false} refreshUpdates={() => this.fetchUpdates(id, fetch_all)} />
			            )
			            background_updates.push(update_component);
			          }
								this.setState({
									updates: updates,
			            manage_feed: manage_feed,
									loading: false,
									background_updates: background_updates,
									coverage_type: data.feed.coverage_type
								});
							})
					}
				}
				if (data.background) {
          var background_updates = [];
          for (var i = 0; i < data.background.length; i++) {
            var update = data.background[i];
            let update_component = (
              <Update user_role={this.props.user_role} onApprove={() => this.fetchUpdates(id, false)} key={update._id} crosspost={update.crosspost} status={update.status} tooltips={update.tooltips} importance={update.importance} show_buttons={true} refreshReviewing={this.props.refreshReviewing} logged_in_user={this.props.logged_in_user} type={update.type} color={data.feed.color} content={update.content} num_reacts={update.num_reacts} id={update._id} draft_id={update.draft_id} source={update.source} timestamp={update.created_at} visible={update.visible} reacted={false} refreshUpdates={() => this.fetchUpdates(id, fetch_all)} />
            )
            background_updates.push(update_component);
          }
					this.setState({
						background_updates: background_updates,
						loading: false
					});
				}
			})
	}

  createUpdate() {
    var t = this;
    var data = {
      assigned_to: this.props.logged_in_user,
      feed: this.state.feed._id,
      posted_by: this.props.logged_in_user
    }
    fetch(constants.BASE_API_URL + '/v1/drafts/create', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.props.onUpdateCreate();
        this.setState({
          draft_id: response.draft._id,
          redirect: true
        })
      })
      .catch(error => console.error('Error:', error));
	}

	confirmPublishBatch() {
    this.setState({
			confirm_publish_batch: true
		})
	}

	publishBatch() {
    var t = this;
    fetch(constants.BASE_API_URL + '/v1/feeds/' + this.state.id + '/publish-batch', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
				alert("Update batch published.");
        this.setState({
          confirm_publish_batch: false
        })
      })
      .catch(error => console.error('Error:', error));
	}

  renderRedirect = () => {
    if (this.state.redirect) {
      let redirect_url = "/draft/" + this.state.draft_id;
      return <Redirect to={redirect_url} />
    }
  }

	render() {
		return (
			<div className="feed-container">
        {this.renderRedirect()}
        <div className="manage-updates-top-container">
          <div className="add-update-button" onClick={this.createUpdate.bind(this)}>
            <i className="fas fa-plus"></i>
            new update
          </div>
					{!this.state.confirm_publish_batch && (this.state.coverage_type === constants.COVERAGE_TYPES.DAILY || this.state.coverage_type === constants.COVERAGE_TYPES.TWICE_DAILY) &&
						<div className="publish-feed-batch-button" onClick={this.confirmPublishBatch.bind(this)}>
	            <i className="fas fa-clock"></i>
	            publish batch
	          </div>
					}
					{this.state.confirm_publish_batch && (this.state.coverage_type === constants.COVERAGE_TYPES.DAILY || this.state.coverage_type === constants.COVERAGE_TYPES.TWICE_DAILY) &&
						<div className="publish-feed-batch-button notify-confirm-button" onClick={this.publishBatch.bind(this)}>
	            <i className="fas fa-clock"></i>
	            are you sure?
	          </div>
					}
          <input className="manage-search" type="text" placeholder="search..." />
        </div>
				{this.state.loading &&
					<div className="updates-loading-container">
						<HashLoader
							className="loading-spinner"
							sizeUnit={"px"}
							size={150}
							color={'#E3E3E3'}
							loading={this.state.loading}
						/>
					</div>
				}
				{!this.state.loading &&
					<div className="manage-updates-container">
	          {this.state.manage_feed}
	          <div className="feed-updates-separator"></div>
						{!this.state.show_background &&
							<div>
								<div className="toggle-background-button" onClick={this.toggleShowBackground.bind(this)}>
									<i class="fas fa-sync-alt"></i>
									show background
									<span className="queue-count">
										{this.state.background_updates.length}
									</span>
								</div>
								{!this.state.fetch_all &&
									<div className="toggle-background-button fetch-all-button" onClick={() => this.fetchAllUpdates()}>
										<i class="fas fa-arrow-alt-down"></i>
										fetch all
										<span className="queue-count">
											{this.state.feed.num_updates}
										</span>
									</div>
								}
								{this.state.updates}
							</div>
						}
						{this.state.show_background &&
							<div>
								<div className="toggle-background-button" onClick={this.toggleShowBackground.bind(this)}>
									<i class="fas fa-sync-alt"></i>
									show coverage
									<span className="queue-count">
										{this.state.updates.length}
									</span>
								</div>
								{!this.state.fetch_all &&
									<div className="toggle-background-button fetch-all-button" onClick={() => this.fetchAllUpdates()}>
										<i class="fas fa-arrow-alt-down"></i>
										fetch all
										<span className="queue-count">
											{this.state.feed.num_updates}
										</span>
									</div>
								}
								{this.state.background_updates}
							</div>
						}
					</div>
				}
				<div className="queue-right-container">
					<div className="draft-right-item">
						<div className="sidebar-updates-container queue-updates">
							{this.props.sidebar_updates}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

class ReviewingUpdates extends Component {

	constructor(props) {
	  super(props);
		this.state = {
			updates: props.updates,
			rejected_updates: props.rejected_updates
		};
		window.scrollTo(0, 0);
	}

	componentWillReceiveProps(props) {
    if (props.updates !== this.props.updates) {
      this.setState({
				updates: props.updates
			});
    }
		if (props.rejected_updates !== this.props.rejected_updates) {
      this.setState({
				rejected_updates: props.rejected_updates
			});
    }
	}

  renderRedirect = () => {
    if (this.state.redirect) {
      let redirect_url = "/draft/" + this.state.draft_id;
      return <Redirect to={redirect_url} />
    }
  }

	render() {
		return (
			<div className="feed-container">
        {this.renderRedirect()}
				<div className="reviewing-updates-container">
					<div className="pending-updates-container">
						<div className="pending-updates-header">
							<i class="fas fa-clock"></i>
							pending updates
						</div>
						{this.state.updates}
					</div>
					{this.state.rejected_updates &&
						<div className="rejected-updates-container">
							<div className="rejected-updates-header">
								<i class="fas fa-times"></i>
								rejected updates
							</div>
							{this.state.rejected_updates}
						</div>
					}
				</div>
			</div>
		)
	}
}

class TooltipList extends Component {

	constructor(props) {
	  super(props);
		this.state = {
      id: props.id,
			tooltips: null,
			loading: true
		};
		this.fetchTooltips(props.id);
		window.scrollTo(0, 0);
	}

	componentWillReceiveProps(props) {
    if (props.id !== this.state.id) {
      this.setState({
				id: props.id,
				tooltips: null
			});
      this.fetchTooltips(props.id);
			window.scrollTo(0, 0);
    }
	}

	showCreateTooltipModal(e) {
    e.preventDefault();
    let t = this;
		$('.darken').show(0, function() {
			$('.create-tooltip-modal').show();
		});
	}

  fetchTooltips(id) {
		fetch(constants.BASE_API_URL + '/v1/feeds/' + id + '/tooltips', {
			credentials: "include"
		}).then(res => res.json())
			.then(data => {
				if (data.tooltips) {
          var tooltips = [];
          for (var i = 0; i < data.tooltips.length; i++) {
            var tooltip = data.tooltips[i];
            let tooltip_component = (
              <Tooltip key={tooltip._id} id={tooltip._id} search_string={tooltip.search_string} tooltip_text={tooltip.tooltip_text} color={data.feed.color} refreshTooltips={() => this.fetchTooltips(id)} />
            )
            tooltips.push(tooltip_component);
          }
          let manage_feed = (
            <ManageFeed key={data.feed._id} emoji={data.feed.emoji} coverage_type={data.feed.coverage_type} status={data.feed.status} is_header={true} name={data.feed.name} updated_at={data.feed.updated_at} description={data.feed.description} color={data.feed.color} num_updates={data.feed.num_updates} num_followers={data.feed.num_followers} id={data.feed._id} visible={data.feed.visible} is_streaming={data.feed.tweet_stream} />
          )
					this.setState({
						tooltips: tooltips,
            feed: data.feed,
            manage_feed: manage_feed,
						loading: false
					});
				}
			})
	}

  createTooltip(search_string, tooltip_text) {
    var t = this;
    var data = {
      search_string: search_string,
      tooltip_text: tooltip_text
    }
    fetch(constants.BASE_API_URL + '/v1/feeds/' + this.state.id + "/tooltips/create", {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        this.fetchTooltips(this.state.id);
      })
      .catch(error => console.error('Error:', error));
	}

	render() {
		return (
			<div>
				<CreateTooltipModal onSubmit={this.createTooltip.bind(this)} />
				<div className="feed-container">
	        <div className="manage-updates-top-container">
	          <div className="add-update-button" onClick={this.showCreateTooltipModal.bind(this)}>
	            <i className="fas fa-plus"></i>
	            new tooltip
	          </div>
	          <input className="manage-search" type="text" placeholder="search..." />
	        </div>
					{this.state.loading &&
						<div className="updates-loading-container">
							<HashLoader
								className="loading-spinner"
								sizeUnit={"px"}
								size={150}
								color={'#E3E3E3'}
								loading={this.state.loading}
							/>
						</div>
					}
					{!this.state.loading &&
						<div className="manage-updates-container">
		          {this.state.manage_feed}
		          <div className="feed-updates-separator"></div>
							{this.state.tooltips}
						</div>
					}
				</div>
			</div>
		)
	}
}

class CreateTooltipModal extends Component {

	constructor(props) {
	  super(props);
	  this.state = {};
	}

  handleChange(e) {
    this.setState({
      [$(e.target).attr('data-content')]: e.target.value
    })
  }

  handleSubmit() {
    $('.darken').hide();
    $('.modal-container').hide();
    this.props.onSubmit(this.state.search_string, this.state.tooltip_text);
	}

	render() {
    let modal_class = "modal-container create-tooltip-modal";
		return (
			<div className={modal_class}>
				<div className="modal-top-container">
					<p className="modal-title">create tooltip</p>
				</div>
				<div className="submit-input-container">
					<input type="text" onChange={this.handleChange.bind(this)} data-content="search_string" name="search_string" placeholder="search string" />
					<textarea onChange={this.handleChange.bind(this)} data-content="tooltip_text" name="tooltip_text" placeholder="tooltip text" />
				</div>
				<div className="modal-submit-button notify-submit-button" onClick={() => this.handleSubmit()}>
					<span className="fas fa-check"></span>
					SUBMIT
				</div>
			</div>
		)
	}
}

class EditTooltipModal extends Component {

	constructor(props) {
	  super(props);
	  this.state = {
			search_string: props.search_string,
			tooltip_text: props.tooltip_text
		};
	}

  handleChange(e) {
    this.setState({
      [$(e.target).attr('data-content')]: e.target.value
    })
  }

  handleSubmit() {
    $('.darken').hide();
    $('.modal-container').hide();
    this.props.onSubmit(this.state.search_string, this.state.tooltip_text);
	}

	render() {
    let modal_class = "modal-container edit-tooltip-modal edit-tooltip-modal-" + this.props.id;
		return (
			<div className={modal_class}>
				<div className="modal-top-container">
					<p className="modal-title">edit tooltip</p>
				</div>
				<div className="submit-input-container">
					<input type="text" onChange={this.handleChange.bind(this)} value={this.state.search_string} data-content="search_string" name="search_string" placeholder="search string" />
					<textarea onChange={this.handleChange.bind(this)} value={this.state.tooltip_text} data-content="tooltip_text" name="tooltip_text" placeholder="tooltip text" />
				</div>
				<div className="modal-submit-button notify-submit-button" onClick={() => this.handleSubmit()}>
					<span className="fas fa-check"></span>
					SUBMIT
				</div>
			</div>
		)
	}
}

class Tooltip extends Component {

	constructor(props) {
		super(props);
		this.state = {
			delete_confirm: false
		};
	}

	delete() {
		var t = this;
    fetch(constants.BASE_API_URL + '/v1/tooltips/' + this.props.id + "/delete", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.props.refreshTooltips();
      })
      .catch(error => console.error('Error:', error));
	}

	deleteConfirm() {
		this.setState({
			delete_confirm: true
		})
	}

	edit(search_string, tooltip_text) {
		var t = this;
    var data = {
      search_string: search_string,
      tooltip_text: tooltip_text
    }
    fetch(constants.BASE_API_URL + '/v1/tooltips/' + this.props.id + "/edit", {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.props.refreshTooltips();
      })
      .catch(error => console.error('Error:', error));
	}

	showEditTooltipModal(e) {
    e.preventDefault();
    let t = this;
		$('.darken').show(0, function() {
			$('.edit-tooltip-modal-' + t.props.id).show();
		});
	}

	render() {
		return (
			<div>
				<EditTooltipModal onSubmit={this.edit.bind(this)} id={this.props.id} search_string={this.props.search_string} tooltip_text={this.props.tooltip_text} />
				<div className="update">
					<div className="update-container tooltip-container">
						<div className="update-top-container">
							<div className="manage-update-button update-edit-button" onClick={this.showEditTooltipModal.bind(this)}>
								edit
							</div>
							{!this.state.delete_confirm &&
                <div className="manage-update-button update-delete-button" onClick={this.deleteConfirm.bind(this)} >
                  delete
                </div>
              }
              {this.state.delete_confirm &&
                <div className="manage-update-button update-delete-button notify-confirm-button" onClick={this.delete.bind(this)} >
                  <i className="fas fa-exclamation-triangle"></i>
                  are you sure?
                </div>
              }
						</div>
						<div className="update-question-container">
							<div className="update-question-text" style={{backgroundColor: this.props.color, color: "white"}}>
								{this.props.search_string}
							</div>
							<div className="update-answer-text">
								{this.props.tooltip_text}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

}

	class Update extends Component {

	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
      visible: props.visible,
			num_reacts: props.num_reacts,
      notify_confirm: false,
			notify_some_confirm: false,
			delete_confirm: false,
			approve_confirm: false,
			reject_confirm: false,
			timestamp: Date.now(),
			reacts_0: 0,
			reacts_1: 0,
			reacts_2: 0,
			reacts_3: 0,
			reacts_4: 0
		}
		// if (process.env.REACT_APP_BASE_API_URL !== "https://sandbox.api.winno.app") {
			this.renderTooltips();
		// }
	}

	renderTooltips() {
		if (this.props.tooltips) {
			let tooltips = this.props.tooltips;
			tooltips.sort((a, b) => (b.start - a.start))
			for (var i = 0; i < tooltips.length; i++) {
				let tooltip_text = tooltips[i].tooltip_text.replace("\"", "").replace("\'", "")
				let open_tag = '<span data-tip="' + tooltip_text + '" data-for="' + this.props.id + this.state.timestamp + '" style="cursor: default; border-bottom: 2px solid ' + this.props.color + '">';
				let close_tag = "</span>";
				if (this.props.type === constants.UPDATE_TYPES["TextUpdate"] && (this.props.content.text.split("data-tip").length - 1) !== tooltips.length) {
					this.props.content.text = this.props.content.text.substr(0, tooltips[i].start) + open_tag + this.props.content.text.substr(tooltips[i].start)
					this.props.content.text = this.props.content.text.substr(0, tooltips[i].end + open_tag.length) + close_tag + this.props.content.text.substr(tooltips[i].end + open_tag.length)
				} else if (this.props.type === constants.UPDATE_TYPES["ImageUpdate"] && (this.props.content.caption.split("data-tip").length - 1) !== tooltips.length) {
					this.props.content.caption = this.props.content.caption.substr(0, tooltips[i].start) + open_tag + this.props.content.caption.substr(tooltips[i].start)
					this.props.content.caption = this.props.content.caption.substr(0, tooltips[i].end + open_tag.length) + close_tag + this.props.content.caption.substr(tooltips[i].end + open_tag.length)
				} else if (this.props.type === constants.UPDATE_TYPES["LinkUpdate"] && (this.props.content.text.split("data-tip").length - 1) !== tooltips.length) {
					this.props.content.text = this.props.content.text.substr(0, tooltips[i].start) + open_tag + this.props.content.text.substr(tooltips[i].start)
					this.props.content.text = this.props.content.text.substr(0, tooltips[i].end + open_tag.length) + close_tag + this.props.content.text.substr(tooltips[i].end + open_tag.length)
				} else if (this.props.type === constants.UPDATE_TYPES["QuoteUpdate"] && (this.props.content.quote.split("data-tip").length - 1) !== tooltips.length) {
					this.props.content.quote = this.props.content.quote.substr(0, tooltips[i].start) + open_tag + this.props.content.quote.substr(tooltips[i].start)
					this.props.content.quote = this.props.content.quote.substr(0, tooltips[i].end + open_tag.length) + close_tag + this.props.content.quote.substr(tooltips[i].end + open_tag.length)
				} else if (this.props.type === constants.UPDATE_TYPES["TweetUpdate"] && (this.props.content.caption.split("data-tip").length - 1) !== tooltips.length) {
					this.props.content.caption = this.props.content.caption.substr(0, tooltips[i].start) + open_tag + this.props.content.caption.substr(tooltips[i].start)
					this.props.content.caption = this.props.content.caption.substr(0, tooltips[i].end + open_tag.length) + close_tag + this.props.content.caption.substr(tooltips[i].end + open_tag.length)
				} else if (this.props.type === constants.UPDATE_TYPES["VideoUpdate"] && (this.props.content.caption.split("data-tip").length - 1) !== tooltips.length) {
					this.props.content.caption = this.props.content.caption.substr(0, tooltips[i].start) + open_tag + this.props.content.caption.substr(tooltips[i].start)
					this.props.content.caption = this.props.content.caption.substr(0, tooltips[i].end + open_tag.length) + close_tag + this.props.content.caption.substr(tooltips[i].end + open_tag.length)
				} else if (this.props.type === constants.UPDATE_TYPES["QuestionUpdate"] && (this.props.content.answer.split("data-tip").length - 1) !== tooltips.length) {
					this.props.content.answer = this.props.content.answer.substr(0, tooltips[i].start) + open_tag + this.props.content.answer.substr(tooltips[i].start)
					this.props.content.answer = this.props.content.answer.substr(0, tooltips[i].end + open_tag.length) + close_tag + this.props.content.answer.substr(tooltips[i].end + open_tag.length)
				} else if (this.props.type === constants.UPDATE_TYPES["FeedUpdate"] && (this.props.content.text.split("data-tip").length - 1) !== tooltips.length) {
					this.props.content.text = this.props.content.text.substr(0, tooltips[i].start) + open_tag + this.props.content.text.substr(tooltips[i].start)
					this.props.content.text = this.props.content.text.substr(0, tooltips[i].end + open_tag.length) + close_tag + this.props.content.text.substr(tooltips[i].end + open_tag.length)
				} else if (this.props.type === constants.UPDATE_TYPES["ArticleUpdate"] && (this.props.content.caption.split("data-tip").length - 1) !== tooltips.length) {
					this.props.content.caption = this.props.content.caption.substr(0, tooltips[i].start) + open_tag + this.props.content.caption.substr(tooltips[i].start)
					this.props.content.caption = this.props.content.caption.substr(0, tooltips[i].end + open_tag.length) + close_tag + this.props.content.caption.substr(tooltips[i].end + open_tag.length)
				}
			}
		}
	}

	expand(e) {
		e.stopPropagation();
		let el = $('#update-' + this.props.id);
		let t = this;
		if (!this.state.expanded) {
			el.animate({ height: $(el).height() + 47 }, 70, function() {
				t.setState({
					expanded: !t.state.expanded,
					counts_loading: true
				})
				t.fetchReactCounts();
			});
		} else {
			el.animate({ height: $(el).height() - 47 }, 70, function() {
				t.setState({
					expanded: !t.state.expanded
				})
			});
		}
	}

	fetchReactCounts() {
		fetch(constants.BASE_API_URL + '/v1/updates/' + this.props.id + '/reacts')
			.then(res => res.json())
			.then(data => {
				this.setState({
					reacts_0: data.reacts["0"],
					reacts_1: data.reacts["1"],
					reacts_2: data.reacts["2"],
					reacts_3: data.reacts["3"],
					reacts_4: data.reacts["4"],
					counts_loading: false
				});
			});
	}

  hide(e) {
    e.stopPropagation();
    let t = this;
    fetch(constants.BASE_API_URL + '/v1/updates/' + this.props.id + '/hide', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.setState({
          visible: false
        })
        t.props.refreshUpdates();
      })
      .catch(error => console.error('Error:', error));
  }

  unhide(e) {
    e.stopPropagation();
    let t = this;
    fetch(constants.BASE_API_URL + '/v1/updates/' + this.props.id + '/unhide', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.setState({
          visible: true
        })
        t.props.refreshUpdates();
      })
      .catch(error => console.error('Error:', error));
  }

  delete(e) {
    e.stopPropagation();
    let t = this;
    fetch(constants.BASE_API_URL + '/v1/updates/' + this.props.id + '/delete', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
				if (response.error) {
					alert(response.error);
					t.setState({
						delete_confirm: false
					})
				} else {
					if (t.props.refreshUpdates) {
						t.props.refreshUpdates();
					}
					t.props.refreshReviewing();
				}
      })
      .catch(error => console.error('Error:', error));
  }

  notifyConfirm(e) {
    this.setState({
      notify_confirm: true
    })
  }

	deleteConfirm(e) {
    this.setState({
      delete_confirm: true
    })
  }

	notifySomeConfirm(e) {
    this.setState({
      notify_some_confirm: true
    })
  }

  notify(e, some_only) {
    e.stopPropagation();
    let t = this;
		var data = {
			some_only: some_only
		}
    fetch(constants.BASE_API_URL + '/v1/updates/' + this.props.id + '/notify', {
      method: 'POST',
      credentials: 'include',
			body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(repsonse => {
        t.setState({
          notify_confirm: false,
					notify_some_confirm: false
        })
      })
      .catch(error => console.error('Error:', error));
  }

	showApproveConfirm() {
		this.setState({
      approve_confirm: true
    })
	}

	approveUpdate(notes) {
		let t = this;
		var data = {
			notes: notes
		}
		fetch(constants.BASE_API_URL + '/v1/updates/' + this.props.id + '/approve', {
      method: 'POST',
      credentials: 'include',
			body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(repsonse => {
        t.setState({
          approve_confirm: false
        })
				t.props.onApprove();
      })
      .catch(error => console.error('Error:', error));
	}

	rejectUpdate(notes) {
		let t = this;
		var data = {
			notes: notes
		}
		fetch(constants.BASE_API_URL + '/v1/updates/' + this.props.id + '/reject', {
      method: 'POST',
      credentials: 'include',
			body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(repsonse => {
        t.setState({
          reject_confirm: false
        })
				t.props.onApprove();
      })
      .catch(error => console.error('Error:', error));
	}

	showRejectConfirm() {
		this.setState({
      reject_confirm: true
    })
	}

	showRejectModal(e) {
    e.preventDefault();
    let t = this;
		$('.darken').show(0, function() {
			$("." + t.props.id + "-reject").show();
		});
	}

	showApproveModal(e) {
    e.preventDefault();
    let t = this;
		$('.darken').show(0, function() {
			$("." + t.props.id + "-approve").show();
		});
	}

	render() {
    let editLink = "/update/" + this.props.draft_id;
    let baseClass = this.state.visible ? "update" : "update hidden-update";
    let updateClass = this.state.expanded ? (baseClass +  " update-expanded") : baseClass
		return (
			<div className={updateClass}>
				<UpdateFeedbackModal id={this.props.id + "-reject"} onSubmit={this.rejectUpdate.bind(this)} />
				<UpdateFeedbackModal id={this.props.id + "-approve"} onSubmit={this.approveUpdate.bind(this)} />
				<ReactTooltip id={this.props.id + this.state.timestamp} type="dark" className="custom-tooltip" />
				<div className={this.props.type === 4 ? "update-container update-twitter-container" : "update-container"} id={"update-" + this.props.id}>
          {this.props.show_buttons &&
            <div className="update-top-container">
              {!this.props.source && this.props.type !== 6 && this.props.type !== 7 &&
                <Link to={editLink}>
                  <div className="manage-update-button update-edit-button needs-source-button">
                    NEEDS LINK
                  </div>
                </Link>
              }
							{this.props.user_role !== constants.USER_ROLES.GOD && this.props.status === constants.UPDATE_STATUS.IN_REVIEW &&
								<div className="manage-update-button update-edit-button update-in-review-button">
									IN REVIEW
								</div>
							}
							{this.props.status === constants.UPDATE_STATUS.REJECTED &&
								<div className="manage-update-button update-edit-button update-reject-button">
									REJECTED
								</div>
							}
							{this.props.user_role === constants.USER_ROLES.GOD && this.props.status === constants.UPDATE_STATUS.IN_REVIEW && !this.state.approve_confirm &&
								<div onClick={this.showApproveConfirm.bind(this)}>
                  <div className="manage-update-button update-edit-button update-approve-button">
                    APPROVE
                  </div>
                </div>
							}
							{this.props.user_role === constants.USER_ROLES.GOD && this.props.status === constants.UPDATE_STATUS.IN_REVIEW && this.state.approve_confirm &&
								<div onClick={this.showApproveModal.bind(this)}>
                  <div className="manage-update-button update-edit-button needs-source-button">
                    ARE YOU SURE?
                  </div>
                </div>
							}
							{this.props.user_role === constants.USER_ROLES.GOD && this.props.status === constants.UPDATE_STATUS.IN_REVIEW && !this.state.reject_confirm &&
								<div onClick={this.showRejectConfirm.bind(this)}>
                  <div className="manage-update-button update-edit-button update-reject-button">
                    REJECT
                  </div>
                </div>
							}
							{this.props.user_role === constants.USER_ROLES.GOD && this.props.status === constants.UPDATE_STATUS.IN_REVIEW && this.state.reject_confirm &&
								<div onClick={this.showRejectModal.bind(this)}>
                  <div className="manage-update-button update-edit-button needs-source-button">
                    ARE YOU SURE?
                  </div>
                </div>
							}
							{this.props.status === constants.UPDATE_STATUS.UNPUBLISHED &&
								<Link to="/batch">
									<div className="manage-update-button update-edit-button unpublished-label">
										UNPUBLISHED
									</div>
								</Link>
              }
							{((this.props.logged_in_user === constants.DANIEL_ID && constants.DANIEL_FEEDS.includes(this.props.feed_id)) || (this.props.logged_in_user === constants.DANIEL_ID && this.props.status === constants.UPDATE_STATUS.IN_REVIEW) || (this.props.status === constants.UPDATE_STATUS.IN_REVIEW && this.props.author === this.props.logged_in_user) || this.props.user_role === constants.USER_ROLES.GOD) &&
	              <Link to={editLink}>
	                <div className="manage-update-button update-edit-button">
	                  edit
	                </div>
	              </Link>
							}
              {!this.state.visible && this.props.status === constants.UPDATE_STATUS.PUBLISHED && this.props.user_role === constants.USER_ROLES.GOD &&
                <div className="manage-update-button update-hide-button" onClick={this.unhide.bind(this)} >
                  unhide
                </div>
              }
              {this.state.visible && this.props.status === constants.UPDATE_STATUS.PUBLISHED && this.props.user_role === constants.USER_ROLES.GOD &&
                <div className="manage-update-button update-hide-button" onClick={this.hide.bind(this)} >
                  hide
                </div>
              }
							{!this.state.delete_confirm && ((this.props.author === this.props.logged_in_user && constants.DANIEL_FEEDS.includes(this.props.feed_id)) || (this.props.status === constants.UPDATE_STATUS.IN_REVIEW && this.props.author === this.props.logged_in_user) || this.props.user_role === constants.USER_ROLES.GOD) &&
	              <div className="manage-update-button update-delete-button" onClick={this.deleteConfirm.bind(this)} >
	                delete
	              </div>
							}
							{this.state.delete_confirm && ((this.props.author === this.props.logged_in_user && constants.DANIEL_FEEDS.includes(this.props.feed_id)) || (this.props.status === constants.UPDATE_STATUS.IN_REVIEW && this.props.author === this.props.logged_in_user) || this.props.user_role === constants.USER_ROLES.GOD) &&
                <div className="manage-update-button update-notify-button notify-confirm-button" onClick={this.delete.bind(this)} >
                  <i className="fas fa-exclamation-triangle"></i>
                  are you sure?
                </div>
              }
              {!this.state.notify_confirm && this.props.status === constants.UPDATE_STATUS.PUBLISHED && this.props.user_role === constants.USER_ROLES.GOD &&
                <div className="manage-update-button update-notify-button" onClick={this.notifyConfirm.bind(this)} >
                  notify
                </div>
              }
              {this.state.notify_confirm && this.props.status === constants.UPDATE_STATUS.PUBLISHED && this.props.user_role === constants.USER_ROLES.GOD &&
                <div className="manage-update-button update-notify-button notify-confirm-button" onClick={(e) => this.notify(e, false)} >
                  <i className="fas fa-exclamation-triangle"></i>
                  are you sure?
                </div>
              }
							{this.props.importance === constants.UPDATE_IMPORTANCE.KEY && !this.state.notify_some_confirm && this.props.status === constants.UPDATE_STATUS.PUBLISHED && this.props.user_role === constants.USER_ROLES.GOD &&
                <div className="manage-update-button update-notify-button" onClick={this.notifySomeConfirm.bind(this)} >
                  notify some
                </div>
              }
              {this.props.importance === constants.UPDATE_IMPORTANCE.KEY && this.state.notify_some_confirm && this.props.status === constants.UPDATE_STATUS.PUBLISHED && this.props.user_role === constants.USER_ROLES.GOD &&
                <div className="manage-update-button update-notify-button notify-confirm-button" onClick={(e) => this.notify(e, true)} >
                  <i className="fas fa-exclamation-triangle"></i>
                  are you sure?
                </div>
              }
            </div>
          }
          {this.props.importance === constants.UPDATE_IMPORTANCE.KEY &&
            <div className="importance-label">
              <span className="fas fa-exclamation-triangle"></span>
              important
            </div>
          }
					{this.props.type === 0 &&
						<TextUpdate text={this.props.content.text} />
					}
					{this.props.type === 1 &&
						<ImageUpdate caption={this.props.content.caption} image_url={this.props.content.image_url} />
					}
					{this.props.type === 5 &&
						<VideoUpdate caption={this.props.content.caption} video_id={this.props.content.video_id} />
					}
					{this.props.type === 2 &&
						<LinkUpdate button_text={this.props.content.button_text} url={this.props.content.url} text={this.props.content.text} />
					}
					{this.props.type === 4 &&
						<TweetUpdate is_small={this.props.small_tweet} tweet_html={this.props.content.tweet_html} caption={this.props.content.caption} />
					}
					{this.props.type === 3 &&
						<QuoteUpdate text={this.props.content.quote} attr={this.props.content.attr} image_url={this.props.content.image_url} tooltip_text={this.props.content.tooltip_text} />
					}
					{this.props.type === 6 &&
						<QuestionUpdate question={this.props.content.question} answer={this.props.content.answer} color={this.props.color} />
					}
					{this.props.type === 7 &&
						<FeedUpdate id={this.props.content.feed_id} text={this.props.content.text} name={this.props.content.feed.name} color={this.props.content.feed.color} num_followers={this.props.content.feed.num_followers} updated_at={this.props.content.feed.updated_at} image_url={this.props.content.feed.image_url} />
					}
					{this.props.type === 8 &&
						<ArticleUpdate caption={this.props.content.caption} article_img={this.props.content.article_img} article_favicon={this.props.content.article_favicon} article_title={this.props.content.article_title} article_site={this.props.content.article_site} article_url={this.props.content.article_url} />
					}
					{this.props.notes &&
						<div className="update-notes-container">
							{this.props.notes}
						</div>
					}
					<UpdateBottom reacted={false} crosspost={this.props.crosspost} button_text={this.props.content.button_text} show_buttons={this.props.show_buttons} num_reacts={this.state.num_reacts} expand={this.expand.bind(this)} expanded={this.state.expanded} source={this.props.source} id={this.props.id} timestamp={this.props.timestamp} />
					{this.state.expanded &&
						<div className="update-expansion-container">
							<div className="update-expansion-left-container">
								<div className="update-react-button green-react" data-react="0" data-reacted={this.state.reacted === 0}>
									<span className="fas fa-thumbs-up"></span>
									{!this.state.counts_loading &&
										<div>
											{this.state.reacts_0}
										</div>
									}
									{this.state.counts_loading &&
										<ClipLoader
											sizeUnit={"px"}
											size={8}
											color={"#FFFFFF"}
											loading={this.state.counts_loading}
										/>
									}
								</div>
								<div className="update-react-button red-react" data-react="1" data-reacted={this.state.reacted === 1}>
									<span className="fas fa-angry"></span>
									{!this.state.counts_loading &&
										<div>
											{this.state.reacts_1}
										</div>
									}
									{this.state.counts_loading &&
										<ClipLoader
											sizeUnit={"px"}
											size={8}
											color={"#FFFFFF"}
											loading={this.state.counts_loading}
										/>
									}
								</div>
								<div className="update-react-button yellow-react" data-react="2" data-reacted={this.state.reacted === 2}>
									<span className="fas fa-laugh-squint"></span>
									{!this.state.counts_loading &&
										<div>
											{this.state.reacts_2}
										</div>
									}
									{this.state.counts_loading &&
										<ClipLoader
											sizeUnit={"px"}
											size={8}
											color={"#FFFFFF"}
											loading={this.state.counts_loading}
										/>
									}
								</div>
								<div className="update-react-button purple-react" data-react="3" data-reacted={this.state.reacted === 3}>
									<span className="fas fa-surprise"></span>
									{!this.state.counts_loading &&
										<div>
											{this.state.reacts_3}
										</div>
									}
									{this.state.counts_loading &&
										<ClipLoader
											sizeUnit={"px"}
											size={8}
											color={"#FFFFFF"}
											loading={this.state.counts_loading}
										/>
									}
								</div>
								<div className="update-react-button blue-react" data-react="4" data-reacted={this.state.reacted === 4}>
									<span className="fas fa-sad-tear"></span>
									{!this.state.counts_loading &&
										<div>
											{this.state.reacts_4}
										</div>
									}
									{this.state.counts_loading &&
										<ClipLoader
											sizeUnit={"px"}
											size={8}
											color={"#FFFFFF"}
											loading={this.state.counts_loading}
										/>
									}
								</div>
							</div>
							<a href={this.props.source} target="_blank" rel="noopener noreferrer">
								<div className="update-expansion-center-container">
									<div className="update-source-button">
										<i className="fas fa-link"></i>
										{this.props.source}
									</div>
								</div>
							</a>
							<div className="update-expansion-right-container">
								<div className="update-share-button">
									<i className="fab fa-twitter"></i>
									share
								</div>
							</div>
						</div>
					}
				</div>
			</div>
		)
	}
}

class UpdateFeedbackModal extends Component {

	constructor(props) {
	  super(props);
	  this.state = {};
	}

  handleChange(e) {
    this.setState({
      [$(e.target).attr('data-content')]: e.target.value
    })
  }

  handleSubmit() {
    $('.darken').hide();
    $('.modal-container').hide();
    this.props.onSubmit(this.state.notes);
	}

	render() {
    let modal_class = "modal-container break-confirmation-modal " + this.props.id;
		return (
			<div className={modal_class}>
				<div className="modal-top-container">
					<p className="modal-title">leave feedback</p>
				</div>
				<div className="submit-input-container">
					<textarea className="modal-notes-input" type="text" onChange={this.handleChange.bind(this)} data-content="notes" name="notes" placeholder="notes (optional)" />
				</div>
				<div className="modal-submit-button notify-submit-button" onClick={() => this.handleSubmit()}>
					<span className="fas fa-check"></span>
					SUBMIT
				</div>
			</div>
		)
	}
}

class UpdateBottom extends Component {

	constructor(props) {
	  super(props);
	  this.state = {
			upvotes_count: Math.floor(Math.random() * Math.floor(1000))
		};
	}

	render() {
		return (
			<div className="update-bottom-container">
				<div className="update-bottom-left-container">
					{/*
					<div className="update-reacts">
						<span className="far fa-fire"></span>
						<span className="update-reacts-count">{this.props.num_reacts}</span>
					</div>
					*/}
					<span className="far fa-clock"></span>
					<div className="update-time">
						<TimeAgo date={this.props.timestamp} />
					</div>
					{this.props.show_buttons &&
						<div className="update-id">
							{this.props.id}
						</div>
					}
				</div>
				<div className="update-bottom-right-container">
					{this.props.source &&
						<a href={this.props.source} target="_blank">
							<div className="update-expand">
								{this.props.button_text &&
									<span>{this.props.button_text}</span>
								}
								{!this.props.button_text &&
									<span>more</span>
								}
								<i className="fas fa-external-link"></i>
							</div>
						</a>
					}
					{!this.props.source &&
						<div className="update-expand">
							no link
							<i className="fas fa-unlink"></i>
						</div>
					}
				</div>
				{this.props.crosspost &&
					<Link to={"/feeds/" + this.props.crosspost._id}>
						<div className="update-bottom-crosspost-container" style={{backgroundColor: this.props.crosspost.color}}>
							also followed in #{this.props.crosspost.name}
							<i className="fas fa-chevron-right"></i>
						</div>
					</Link>
				}
			</div>
		)
	}
}

class ImageUpdate extends Component {

	constructor(props) {
		super(props);
		this.state = {}
	}

	openLightbox(e) {
		e.stopPropagation();
		this.setState({lightboxIsOpen: true});
	}

	closeLightbox() {
		this.setState({lightboxIsOpen: false});
	}

	render() {
		return (
			<div className="update-image-container">
				<img className="update-image" onClick={(e) => this.openLightbox(e)} src={this.props.image_url} alt={this.props.caption} />
				<div className="update-media-caption">
					{this.props.caption && renderHTML(this.props.caption)}
				</div>
				<Lightbox
        	images={[{ src: this.props.image_url }]}
					backdropClosesModal={true}
        	isOpen={this.state.lightboxIsOpen}
        	onClickPrev={this.gotoPrevious}
        	onClickNext={this.gotoNext}
        	onClose={this.closeLightbox.bind(this)}
      	/>
			</div>
		)
	}
}

class VideoUpdate extends Component {

	render() {
    let video_url = "https://www.youtube.com/embed/" + this.props.video_id;
		return (
			<div className="update-video-container">
				<iframe src={video_url} title={this.props.caption} className="update-video" frameborder="0" allow="encrypted-media" allowfullscreen></iframe>
				<div className="update-media-caption">
					{this.props.caption && renderHTML(this.props.caption)}
				</div>
			</div>
		)
	}

}

class LinkUpdate extends Component {

	render() {
		return (
			<div className="update-link-container">
				<div className="update-link-text">
					{this.props.text && renderHTML(this.props.text)}
				</div>
				<a href={this.props.url} target="_blank" rel="noopener noreferrer">
					<div className="update-link-button">
						{this.props.button_text}
						<i className="fas fa-chevron-right"></i>
					</div>
				</a>
			</div>
		)
	}

}

class TextUpdate extends Component {

	render() {
		return (
			<div className="update-text-container">
				{this.props.text && renderHTML(this.props.text)}
			</div>
		)
	}
}

class TweetUpdate extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		var regex = new RegExp('\/status\/(...................)?');
		if (regex.exec(this.props.tweet_html)) {
			this.setState({
				tweet_id: regex.exec(this.props.tweet_html)[1]
			})
		}
	}

	render() {
		return (
			<div className="update-twitter-container">
				<Tweet tweetId={this.state.tweet_id} options={this.props.is_small ? { width: '350' } : { width: '1000' }} />
				<div className="update-media-caption">
					{this.props.caption && renderHTML(this.props.caption)}
				</div>
			</div>
		)
	}

}

class QuoteUpdate extends Component {

	render() {
		return (
			<div className="update-quote-container">
				<div className="update-quote-text">
					"{this.props.text && renderHTML(this.props.text)}"
				</div>
				<div className="update-quote-details">
					{this.props.image_url &&
						<img className="quote-profile-image" src={this.props.image_url} title={this.props.tooltip_text} />
					}
					<div className="update-quote-source">
						{!this.props.image_url &&
							<i class="far fa-comment"></i>
						}
						{this.props.attr}
					</div>
				</div>
			</div>
		)
	}

}

class QuestionUpdate extends Component {

	render() {
		return (
			<div className="update-question-container">
				<div className="update-question-text">
					{this.props.question}
				</div>
				<div className="update-answer-text">
					{this.props.answer && renderHTML(this.props.answer)}
				</div>
			</div>
		)
	}

}

class FeedUpdate extends Component {

	constructor(props) {
		super(props);
		if (props.is_preview) {
			this.state = {
				id: props.id
			}
			this.fetchFeed(props.id);
		} else {
			this.state = {
				id: props.id,
				name: props.name,
				color: props.color,
				num_followers: props.num_followers,
				updated_at: props.updated_at,
				image_url: props.image_url
			};
		}
	}

	componentWillReceiveProps(props) {
		if (props.is_preview && props.id && props.id !== this.state.id) {
			this.setState({
				id: props.id,
				name: null,
				color: null,
				num_followers: null,
				updated_at: null,
				image_url: null
			});
			this.fetchFeed(props.id);
		}
	}

	fetchFeed(id) {
		fetch(constants.BASE_API_URL + '/v1/feeds/' + id)
			.then(res => res.json())
      .then(res => {
				this.setState( {
					name: res.feed.name,
					color: res.feed.color,
					num_followers: res.feed.num_followers,
					updated_at: res.feed.updated_at,
					image_url: res.feed.image_url
				})
      })
      .catch(error => console.error('Error:', error));
	}

	render() {
		return (
			<div className="update-feed-container">
				<div className="update-text">
					{this.props.text && renderHTML(this.props.text)}
				</div>
				<Link to={"/feeds/" + this.props.id}>
					<div className="feed-update-container" style={{backgroundColor: this.state.color}}>
						<img src={this.state.image_url} className="feed-update-image" />
						<div className="feed-slug" style={{backgroundColor: "rgb(255,255,255,0.2)"}}>
							<div className="fas fa-hashtag"></div>
							{this.state.name}
						</div>
						<div className="feed-update-details">
							<i class="fas fa-user"></i>
							{this.state.num_followers}
							<i class="fas fa-sync-alt"></i>
							<TimeAgo date={this.state.updated_at} />
						</div>
						<div className="feed-update-button">
							<i class="fas fa-chevron-right"></i>
						</div>
					</div>
				</Link>
			</div>
		)
	}

}

class ArticleUpdate extends Component {

	constructor(props) {
		super(props);
		this.state = {
			article_img: props.article_img,
			article_favicon: props.article_favicon,
			article_title: props.article_title,
			article_site: props.article_site,
			article_url: props.article_url,
			article_description: props.article_description
		};
	}

	render() {
		return (
			<div>
				<a href={this.props.article_url ? this.props.article_url : "https://google.com"} target="_blank">
					<div className={this.props.is_preview ? "update-article-container-preview" : "update-article-container"}>
						{/*<img src={this.props.article_img} className="article-update-image" />*/}
						<div className="article-update-title">{this.props.article_title}</div>
						<div className="article-update-site-container">
							<img src={this.props.article_favicon} className="article-update-favicon" />
							<div className="article-update-site">
								{this.props.article_site} / {this.props.article_url}
							</div>
						</div>
					</div>
				</a>
				<div className="article-caption-text">
					{this.props.caption && renderHTML(this.props.caption)}
				</div>
			</div>
		)
	}

}

class ManageFeed extends Component {

  constructor(props) {
	  super(props);
		this.state = {
      visible: this.props.visible,
      is_streaming: this.props.is_streaming,
      status: this.props.status,
      confirm_publish: false,
			notify_confirm: false
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      visible: props.visible,
      is_streaming: props.is_streaming,
      status: props.status
    });
  }

  feature(e) {
    e.preventDefault();
    let t = this;
    fetch(constants.BASE_API_URL + '/v1/feeds/' + this.props.id + '/feature', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.setState({
          status: constants.FEED_STATUS.FEATURED
        })
        t.props.refreshFeeds();
      })
      .catch(error => console.error('Error:', error));
  }

  unfeature(e) {
    e.preventDefault();
    let t = this;
    fetch(constants.BASE_API_URL + '/v1/feeds/' + this.props.id + '/unfeature', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.setState({
          status: constants.FEED_STATUS.ACTIVE
        })
        t.props.refreshFeeds();
      })
      .catch(error => console.error('Error:', error));
  }

  break(notification_text) {
    let data = {};
    if (notification_text) {
      data.notification_text = notification_text;
    }
    let t = this;
    fetch(constants.BASE_API_URL + '/v1/feeds/' + this.props.id + '/break', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.setState({
          status: constants.FEED_STATUS.BREAKING
        })
        t.props.refreshFeeds();
      })
      .catch(error => console.error('Error:', error));
  }

  showBreakConfirmationModal(e) {
    e.preventDefault();
    let t = this;
		$('.darken').show(0, function() {
			$('.break-confirmation-modal-' + t.props.id).show();
		});
	}

  unbreak(e) {
    e.preventDefault();
    let t = this;
    fetch(constants.BASE_API_URL + '/v1/feeds/' + this.props.id + '/unbreak', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.setState({
          status: constants.FEED_STATUS.ACTIVE
        })
        t.props.refreshFeeds();
      })
      .catch(error => console.error('Error:', error));
  }

  archive(e) {
    e.preventDefault();
    let t = this;
    fetch(constants.BASE_API_URL + '/v1/feeds/' + this.props.id + '/archive', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.setState({
          status: constants.FEED_STATUS.ARCHIVED
        })
        t.props.refreshFeeds();
      })
      .catch(error => console.error('Error:', error));
  }

  unarchive(e) {
    e.preventDefault();
    let t = this;
    fetch(constants.BASE_API_URL + '/v1/feeds/' + this.props.id + '/unarchive', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.setState({
          status: constants.FEED_STATUS.ACTIVE
        })
        t.props.refreshFeeds();
      })
      .catch(error => console.error('Error:', error));
  }

  hide(e) {
    e.preventDefault();
    let t = this;
    fetch(constants.BASE_API_URL + '/v1/feeds/' + this.props.id + '/hide', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.setState({
          visible: false
        })
        t.props.refreshFeeds();
      })
      .catch(error => console.error('Error:', error));
  }

  confirmPublish(e) {
    e.preventDefault();
    this.setState({
      confirm_publish: true
    })
  }

  publish(e) {
    e.preventDefault();
    let t = this;
    fetch(constants.BASE_API_URL + '/v1/feeds/' + this.props.id + '/publish', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.setState({
          status: constants.FEED_STATUS.ACTIVE
        })
        t.props.refreshFeeds();
      })
      .catch(error => console.error('Error:', error));
  }

  unhide(e) {
    e.preventDefault();
    let t = this;
    fetch(constants.BASE_API_URL + '/v1/feeds/' + this.props.id + '/unhide', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.setState({
          visible: true
        })
        t.props.refreshFeeds();
      })
      .catch(error => console.error('Error:', error));
  }

  stream(e) {
    e.preventDefault();
    let t = this;
    fetch(constants.BASE_API_URL + '/v1/feeds/' + this.props.id + '/stream', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.setState({
          is_streaming: true
        })
        t.props.refreshFeeds();
      })
      .catch(error => console.error('Error:', error));
  }

  unstream(e) {
    e.preventDefault();
    let t = this;
    fetch(constants.BASE_API_URL + '/v1/feeds/' + this.props.id + '/unstream', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.setState({
          is_streaming: false
        })
        t.props.refreshFeeds();
      })
      .catch(error => console.error('Error:', error));
  }

	notifyConfirm(e) {
		e.preventDefault();
    this.setState({
      notify_confirm: true
    })
  }

  notify(e) {
		e.preventDefault();
    let t = this;
    fetch(constants.BASE_API_URL + '/v1/feeds/' + this.props.id + '/notify', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
				if (response.error) {
					alert(response.error);
				}
				t.setState({
					notify_confirm: false
				})
      })
      .catch(error => console.error('Error:', error));
  }

  createUpdate(e) {
    e.preventDefault();
    var t = this;
    var data = {
      assigned_to: this.props.logged_in_user,
      feed: this.props.id,
      posted_by: this.props.logged_in_user
    }
    fetch(constants.BASE_API_URL + '/v1/drafts/create', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(response => {
        t.props.onUpdateCreate();
        this.setState({
          draft_id: response.draft._id,
          redirect: true
        })
      })
      .catch(error => console.error('Error:', error));
	}

  renderRedirect = () => {
    if (this.state.redirect) {
      let redirect_url = "/draft/" + this.state.draft_id;
      return <Redirect to={redirect_url} />
    }
  }

  render() {
    var manage_url = "/feeds/" + this.props.id + "/edit";
		var tooltips_url = "/feeds/" + this.props.id + "/tooltips";
    var updates_url = "/feeds/" + this.props.id;
		return (
      <div>
        <BreakConfirmationModal id={this.props.id} name={this.props.name} onSubmit={this.break.bind(this)} />
        <Link to={updates_url}>
          {this.renderRedirect()}
    			<div className="managed-feed">
    				<div className="managed-feed-left-container">
    					<div className="feed-slug" style={{backgroundColor: this.props.color}}>
    						<div className="fas fa-hashtag"></div>
    						{this.props.name}
    					</div>
              <div className="feed-details-label">
								<span className="manage-feed-emoji">{this.props.emoji}</span>
                <span className="bold-text">{this.props.num_followers}</span> followers / <span className="bold-text">{this.props.num_updates}
								</span><span> updates / </span>
								{/*
									{this.props.status !== constants.FEED_STATUS.ACTIVE && this.props.coverage_type === constants.COVERAGE_TYPES.LIVE &&
										<span><span className="bold-text">live</span> /</span>
									}
									{this.props.status !== constants.FEED_STATUS.ACTIVE && this.props.coverage_type === constants.COVERAGE_TYPES.DAILY &&
										<span><span className="bold-text">daily</span> /</span>
									}
									{this.props.status !== constants.FEED_STATUS.ACTIVE && this.props.coverage_type === constants.COVERAGE_TYPES.TWICE_DAILY &&
										<span><span className="bold-text">twice-daily</span> /</span>
									}
								*/}
								<span> last updated </span><span className="bold-text"><TimeAgo date={this.props.updated_at} /></span>
              </div>
    				</div>
            <div className="manage-feed-description">
              {this.props.description}
            </div>
						{this.props.user_role === constants.USER_ROLES.GOD &&
	            <div className="managed-feed-buttons-container">
	              <Link to={manage_url}>
	      					<div className="manage-feed-button">
	      						edit
	      					</div>
	              </Link>
								{!this.props.is_header &&
									<div className="manage-feed-button new-update-button" onClick={this.createUpdate.bind(this)}>
										+
									</div>
	              }
								<Link to={tooltips_url}>
	      					<div className="manage-feed-button">
	      						tooltips
	      					</div>
	              </Link>
								{this.state.status !== constants.FEED_STATUS.UNPUBLISHED && this.state.status === constants.FEED_STATUS.FEATURED &&
	                <div className="manage-feed-button" onClick={(e) => this.unfeature(e)}>
	                  un-feature
	                </div>
	              }
	              {this.state.status !== constants.FEED_STATUS.UNPUBLISHED && this.state.status !== constants.FEED_STATUS.FEATURED &&
	                <div className="manage-feed-button" onClick={(e) => this.feature(e)}>
	                  feature
	                </div>
	              }
								{this.state.status !== constants.FEED_STATUS.UNPUBLISHED && this.state.status === constants.FEED_STATUS.ARCHIVED &&
	                <div className="manage-feed-button" onClick={(e) => this.unarchive(e)}>
	                  un-archive
	                </div>
	              }
	              {this.state.status !== constants.FEED_STATUS.UNPUBLISHED && this.state.status !== constants.FEED_STATUS.ARCHIVED &&
	                <div className="manage-feed-button" onClick={(e) => this.archive(e)}>
	                  archive
	                </div>
	              }
								{!this.state.notify_confirm &&
	                <div className="manage-feed-button update-notify-button" onClick={(e) => this.notifyConfirm(e)} >
	                  notify
	                </div>
	              }
	              {this.state.notify_confirm &&
	                <div className="manage-feed-button update-notify-button notify-confirm-button" onClick={(e) => this.notify(e)} >
	                  <i className="fas fa-exclamation-triangle"></i>
	                  are you sure?
	                </div>
	              }
								{!this.state.visible &&
	                <div className="manage-feed-button" onClick={(e) => this.unhide(e)}>
	                  un-hide
	                </div>
	              }
	              {this.state.visible &&
	                <div className="manage-feed-button" onClick={(e) => this.hide(e)}>
	                  hide
	                </div>
	              }
								{this.state.status === constants.FEED_STATUS.UNPUBLISHED && !this.state.confirm_publish &&
	                <div className="manage-feed-button" onClick={(e) => this.confirmPublish(e)}>
	                  publish
	                </div>
	              }
	              {this.state.status === constants.FEED_STATUS.UNPUBLISHED && this.state.confirm_publish &&
	                <div className="manage-feed-button notify-warning-button" onClick={(e) => this.publish(e)}>
	                  <span className="fas fa-exclamation-triangle"></span>
	                  are you sure?
	                </div>
	              }
								{this.state.is_streaming &&
	                <div className="manage-feed-button" onClick={(e) => this.unstream(e)}>
	                  stream off
	                </div>
	              }
	              {!this.state.is_streaming &&
	                <div className="manage-feed-button feed-streaming-button" onClick={(e) => this.stream(e)}>
	                  stream on
	                </div>
	              }
								{this.state.status !== constants.FEED_STATUS.UNPUBLISHED && this.state.status === constants.FEED_STATUS.BREAKING &&
	                <div className="manage-feed-button feed-live-button" onClick={(e) => this.unbreak(e)}>
	                  end live
	                </div>
	              }
	              {this.state.status !== constants.FEED_STATUS.UNPUBLISHED && this.state.status !== constants.FEED_STATUS.BREAKING &&
	                <div className="manage-feed-button" onClick={(e) => this.showBreakConfirmationModal(e)}>
	                  make live
	                </div>
	              }
	    				</div>
						}
						{this.props.user_role === constants.USER_ROLES.CURATOR &&
							<div className="managed-feed-buttons-container">
								{!this.props.is_header &&
									<div className="manage-feed-button new-update-button-big" onClick={this.createUpdate.bind(this)}>
										+ new
									</div>
								}
								{!this.props.is_header &&
									<Link to={updates_url}>
										<div className="manage-feed-button">
											updates
										</div>
									</Link>
								}
								<Link to={tooltips_url}>
									<div className="manage-feed-button">
										tooltips
									</div>
								</Link>
							</div>
						}
    			</div>
        </Link>
      </div>
		)
	}

}

class BreakConfirmationModal extends Component {

	constructor(props) {
	  super(props);
	  this.state = {};
	}

  handleChange(e) {
    this.setState({
      [$(e.target).attr('data-content')]: e.target.value
    })
  }

  handleSubmit() {
    $('.darken').hide();
    $('.modal-container').hide();
    this.props.onSubmit(this.state.notification_text);
	}

	render() {
    let modal_class = "modal-container break-confirmation-modal break-confirmation-modal-" + this.props.id;
		return (
			<div className={modal_class}>
				<div className="modal-top-container">
					<p className="modal-title">mark #{this.props.name} as live</p>
				</div>
				<div className="submit-input-container">
					<input type="text" onChange={this.handleChange.bind(this)} data-content="notification_text" name="notification_text" placeholder="notification text (optional)" />
				</div>
        {!this.state.notification_text &&
  				<div className="modal-submit-button notify-submit-button" onClick={() => this.handleSubmit()}>
  					<span className="fas fa-check"></span>
  					SUBMIT
  				</div>
        }
        {this.state.notification_text &&
          <div className="modal-submit-button notify-submit-button notify-warning-button" onClick={() => this.handleSubmit()}>
  					<span className="fas fa-exclamation-triangle"></span>
  					SUBMIT AND NOTIFY
  				</div>
        }
			</div>
		)
	}
}

class Login extends Component {

	constructor(props) {
		super(props);
		this.state = {
			show_phone_input: false,
			show_code_input: false,
			show_username_input: false
		};
	}

	login() {
		var t = this;
		let data = {
			username: this.state.username,
			password: this.state.password
		}
		fetch(constants.BASE_API_URL + '/v1/auth/curator', {
			method: 'POST',
			credentials: 'include',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json'
			}
		}).then(res => res.json())
			.then(response => {
				if (response.user_role === constants.USER_ROLES.CURATOR || response.user.role === constants.USER_ROLES.GOD) {
					t.props.onLogin(response.user._id, response.user.role, response.user.login_id);
				} else {
					alert("You don't have the permissions.")
				}
			})
			.catch(error => console.error('Error:', error));
	}

	handleChange(e) {
    this.setState({
      [$(e.target).attr('data-content')]: e.target.value
    })
  }

  checkForEnter(e) {
    var code = (e.keyCode ? e.keyCode : e.which);
    if(code === 13) {
      this.login();
    }
  }

	showPhoneInput() {
		this.setState({
			show_phone_input: true
		})
	}

	showUsernameInput() {
		this.setState({
			show_username_input: true
		})
	}

	submitPhone() {
		if (this.state.phone.length === 10) {
			this.setState({
				phone: "+1" + this.state.phone
			})
		}
		let data = { number: this.state.phone }
		fetch(constants.BASE_API_URL + '/v1/auth/phone/send-code', {
			method: 'POST',
			credentials: 'include',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json'
			}
		}).then(res => res.json())
			.then(response => {
				if (!response.error) {
					this.setState({
						show_code_input: true,
						show_phone_input: false
					})
				}
			})
	}

	submitCode() {
		let t = this;
		let data = {
			number: this.state.phone,
			code: this.state.code
		}
		fetch(constants.BASE_API_URL + '/v1/auth/phone', {
			method: 'POST',
			credentials: 'include',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json'
			}
		}).then(res => res.json())
			.then(response => {
				console.log(response);
				if (response.user.role === constants.USER_ROLES.CURATOR || response.user.role === constants.USER_ROLES.GOD) {
					t.props.onLogin(response.user._id, response.user.role, response.user.login_id);
				} else {
					alert("You don't have the permissions.")
				}
			})
			.catch(error => console.error('Error:', error));
	}

	responseFacebook(response) {
		if (response.accessToken) {
			var t = this;
			let data = {
				access_token: response.accessToken
			}
			fetch(constants.BASE_API_URL + '/v1/auth/facebook', {
				method: 'POST',
				credentials: 'include',
				body: JSON.stringify(data),
				headers: {
					'Content-Type': 'application/json'
				}
			}).then(res => res.json())
				.then(response => {
					if (response.user_role === constants.USER_ROLES.CURATOR || response.user.role === constants.USER_ROLES.GOD) {
						t.props.onLogin(response.user._id, response.user.role, response.user.login_id);
					} else {
						alert("You don't have the permissions.")
					}
				})
				.catch(error => console.error('Error:', error));
		} else {
			alert("Login failed...");
			alert(response);
		}
	}

	responseGoogle(response) {
		if (response.tokenId) {
			var t = this;
			let data = {
				id_token: response.tokenId
			}
			fetch(constants.BASE_API_URL + '/v1/auth/google/curator', {
				method: 'POST',
				credentials: 'include',
				body: JSON.stringify(data),
				headers: {
					'Content-Type': 'application/json'
				}
			}).then(res => res.json())
				.then(response => {
					if (response.user_role === constants.USER_ROLES.CURATOR || response.user.role === constants.USER_ROLES.GOD) {
						t.props.onLogin(response.user._id, response.user.role, response.user.login_id);
					} else {
						alert("You don't have the permissions.")
					}
				})
				.catch(error => console.error('Error:', error));
		} else {
			alert("Login failed...");
			alert(response);
		}
	}

	render() {
		return (
      <div className="login-background">
  			<div className="login-container">
  				<div className="login-top-container">
  					<img src={logo} className="login-logo" alt="Happening logo" />
  					<p className="login-title">curator<span className="beta-text">beta</span></p>
  				</div>
					{!this.state.show_phone_input && !this.state.show_code_input &&
	  				<div className="login-input-container">
							{this.state.show_username_input &&
								<div className="username-input-container">
									<input onChange={ this.handleChange.bind(this) } data-content="username" type="text" name="username" placeholder="username" />
		  						<input onKeyUp={this.checkForEnter.bind(this)} onChange={ this.handleChange.bind(this) } data-content="password" type="password" name="password" placeholder="password..." />
									<div className="login-submit-button" onClick={this.login.bind(this)}>
				  					<span className="fas fa-check"></span>
				  					submit
				  				</div>
								</div>
							}
							{!this.state.show_username_input &&
								<div className="login-options">
									<button className="username-login-button" onClick={this.showUsernameInput.bind(this)}>
										continue with username
									</button>
									<FacebookLogin
								    appId="143054886575090"
								    autoLoad={false}
								    fields="name,email,picture"
										cssClass="facebook-login-button"
										textButton="continue with facebook"
								    callback={this.responseFacebook.bind(this)}
									/>
									<GoogleLogin
								    clientId="313716684430-cdr1n0m69tockvpcjomlm3muls7t11mg.apps.googleusercontent.com"
										render={renderProps => (
								      <button className="google-login-button" onClick={renderProps.onClick}>continue with google</button>
								    )}
										className="google-login-button"
								    onSuccess={this.responseGoogle.bind(this)}
								    cookiePolicy={'single_host_origin'}
								  />
									<button className="sms-login-button" onClick={this.showPhoneInput.bind(this)}>
										continue with phone
									</button>
								</div>
							}
	  				</div>
					}
					{this.state.show_phone_input &&
						<div className="login-input-container">
							<input onChange={ this.handleChange.bind(this) } data-content="phone" type="text" name="phone" placeholder="phone" />
							<div className="login-submit-button" onClick={this.submitPhone.bind(this)}>
		  					<span className="fas fa-check"></span>
		  					submit
		  				</div>
						</div>
					}
					{this.state.show_code_input &&
						<div className="login-input-container">
							<input onChange={ this.handleChange.bind(this) } data-content="code" type="text" name="code" placeholder="enter code" />
							<div className="login-submit-button" onClick={this.submitCode.bind(this)}>
		  					<span className="fas fa-check"></span>
		  					submit
		  				</div>
						</div>
					}
  			</div>
      </div>
		)
	}

}

function shadeColor(color, percent) {
  // eslint-disable-next-line
  var num = parseInt(color.slice(1),16), amt = Math.round(2.55 * percent), R = (num >> 16) + amt, G = (num >> 8 & 0x00FF) + amt, B = (num & 0x0000FF) + amt;
  return "#" + (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (G<255?G<1?0:G:255)*0x100 + (B<255?B<1?0:B:255)).toString(16).slice(1);
}

export default App;
